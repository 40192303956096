import {TradesState} from "./feature";
import {TradesUIState, TradeUIState} from "./TradesUIState";
import {OrderDirection, OrderStatus, Trade} from "../../domain/trades/models";
import colors from "../../ui/theme/foundations/colors";
import {toFormatted} from "../../ui/utils";

export function featureStateToTradesUIState(state: TradesState): TradesUIState {
    const trades = state.trades.map((trade) => tradeToUIState(trade))
    return {
        active: state.active,
        items: trades,
        loading: state.tradesLoading,
        loadingError: state.tradesLoadingError,
        loadMoreAvailable: !state.isLastPage
            && !state.tradesLoading
            && state.tradesLoadingError === null
    }
}

export function tradeToUIState(trade: Trade): TradeUIState {
    return {
        id: trade.id,
        baseLogoUrl: trade.poolInfo.baseToken.logoUrl,
        baseSymbol: trade.poolInfo.baseToken.symbol,
        quoteLogoUrl: trade.poolInfo.quoteToken.logoUrl,
        quoteSymbol: trade.poolInfo.quoteToken.symbol,
        type: getTradeType(trade),
        typeColor: trade.direction === OrderDirection.BUY ? colors.tg.green : colors.tg.red,
        statusPrimary: getStatusPrimary(trade),
        statusPrimaryColor: getPrimaryStatusColor(trade) ?? colors.tg.textColor,
        statusSecondary: getStatusSecondary(trade),
        statusSecondaryColor: getSecondaryStatusColor(trade.status),
    }
}

function getTradeType(trade: Trade): string {
    return trade.smartTrade ? getSmartTradeType(trade) : getSimpleTradeType(trade)
}

function getSimpleTradeType(trade: Trade): string {
    const type = trade.condition ? "Conditional" : "Market"
    const direction = trade.direction.valueOf()
    return `${type} ${direction}`
}

function getSmartTradeType(trade: Trade): string {
    return trade.direction === OrderDirection.BUY ? "Smart Long" : "Smart Short"
}

function getStatusPrimary(trade: Trade): string {
    return trade.smartTrade
        ? getTradeProfitString(trade)
        : getAmountString(trade)
}

function getStatusSecondary(trade: Trade): string {
    switch (trade.status) {
        case OrderStatus.SUCCESS:
            if (trade.smartTrade) {
                return "FINISHED"
            } else {
                return getTotalString(trade)
            }
        case OrderStatus.PENDING:
            return trade.smartTrade && trade.entryPrice
                ? "WAITING TARGETS"
                : "PENDING"
    }
    return trade.status.toUpperCase()
}

function isFinalStatus(status: string): boolean {
    return [
        OrderStatus.CLOSED.valueOf(),
        OrderStatus.CANCELLED.valueOf(),
        OrderStatus.FAILED.valueOf(),
        OrderStatus.SUCCESS.valueOf(),
        OrderStatus.STOP_LOSS.valueOf(),
    ].includes(status)
}

function getTradeProfitString(trade: Trade): string {
    if ([OrderStatus.FAILED.valueOf(), OrderStatus.CANCELLED.valueOf()].includes(trade.status)) {
        return ''
    }

    let str = ''

    if (isFinalStatus(trade.status)) {
        if (trade.total && trade.close?.total) {
            const profit = trade.close.total - trade.total
            const sign = profit > 0 ? "+" : ""
            str = `${sign}${toFormatted(profit, 8)} ${trade.poolInfo.quoteToken.symbol}`
        }
    } else {
        if (trade.entryPrice) {
            const priceChange = trade.poolInfo.limits.price - trade.entryPrice
            const profitPercentage = priceChange / trade.entryPrice * 100
            const sign = priceChange > 0 ? "+" : ""
            str = `${sign}${toFormatted(profitPercentage, 2)}%`
        }
    }

    return str
}

function getAmountString(trade: Trade): string {
    const sign = trade.direction === OrderDirection.BUY ? "+" : "-"
    const value = toFormatted(trade.size, 8)
    return `${sign}${value} ${trade.poolInfo.baseToken.symbol}`
}

function getTotalString(trade: Trade): string {
    const sign = trade.direction === OrderDirection.BUY ? "-" : "+"
    const value = toFormatted(trade.total, 8)
    return `${sign}${value} ${trade.poolInfo.quoteToken.symbol}`
}

function getPrimaryStatusColor(trade: Trade): string | undefined {
    if (trade.smartTrade) {
        const entryPrice = trade.entryPrice ?? trade.poolInfo.limits.price
        const profit = trade.poolInfo.limits.price - entryPrice
        if (profit > 0) return colors.tg.green
        if (profit < 0) return colors.tg.red
    }
}

function getSecondaryStatusColor(status: string): string {
    switch (status) {
        case OrderStatus.SUCCESS:
            return colors.tg.textColorSecondary
        case OrderStatus.PENDING:
            return colors.tg.yellow
        case OrderStatus.FAILED:
            return colors.tg.red
    }
    return colors.tg.textColorSecondary
}