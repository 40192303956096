import {
  createContext, ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AlchemySmartAccountClient,
  createModularAccountAlchemyClient,
} from "@alchemy/aa-alchemy";
import {
  WalletClientSigner,
  type SmartAccountSigner,
  LocalAccountSigner, SmartContractAccount,
} from "@alchemy/aa-core";
import {
  AccountLoupeActions,
  SessionKeyPlugin,
  SessionKeyPluginActions,
  sessionKeyPluginActions,
} from "@alchemy/aa-accounts";
import { ConnectedWallet, usePrivy, useWallets } from "@privy-io/react-auth";
import { createWalletClient, custom, keccak256 } from "viem";
import { generatePrivateKey } from "viem/accounts";

import {PluginStatus, SmartAccount, UserOperation} from "./smartAccount";
import {THexAddress} from "../../blockchain/erc20";
import {sendEvent} from "../../../utils/analytics";
import {getChainConfig} from "../../blockchain/config";

const AlchemySmartAccountContext = createContext<SmartAccount | null>(null);

export const useSmartAccount = () => {
  const context = useContext(AlchemySmartAccountContext);

  if (!context) {
    throw new Error("AlchemySmartAccountContext is not initialized");
  }

  return context;
};

type SmartAccountClient = AlchemySmartAccountClient &
    SessionKeyPluginActions<SmartContractAccount> &
    AccountLoupeActions<SmartContractAccount>;

const isPluginInstalled = async (
    accountClient: SmartAccountClient
): Promise<boolean> => {
  const chainId = accountClient?.chain?.id;
  if (!chainId) {
    return false;
  }

  return accountClient
      .getInstalledPlugins({})
      .then((plugins: readonly THexAddress[]) => {
        return plugins.includes(SessionKeyPlugin.meta.addresses[chainId]);
      })
      .catch(() => {
        return false;
      });
};

export const AlchemyPrivySmartAccountProvider = (props: {
    children: ReactNode,
}) => {
  const [pluginStatus, setPluginStatus] = useState<PluginStatus>(
      PluginStatus.INITIAL
  );

  const [resultingUser, setResultingUser] = useState<SmartAccount["privyUser"]>(
      {
        wallet: undefined,
      }
  );

  const { ready, authenticated, user, getAccessToken, login, logout } =
      usePrivy();

  const { wallets } = useWallets();
  const [client, setClient] = useState<SmartAccountClient | null>(null);

  const privyWallet = useMemo(() => {
    return wallets.find((wallet) => wallet.walletClientType === "privy");
  }, [wallets]);

  useEffect(() => {
    if (!authenticated && ready) {
      sendEvent("no-auth-view");
    }
    if (privyWallet) {
      sendEvent("auth-view", {
        event_category: privyWallet.address,
      });
    }
  }, [ready, authenticated, privyWallet]);

  const initSessionKeyPlugin = useCallback(
      async (accountClient: SmartAccountClient) => {
        setPluginStatus(PluginStatus.LOADING);
        const isInstalled = await isPluginInstalled(accountClient);

        if (isInstalled) {
          setPluginStatus(PluginStatus.INSTALLED);
        } else {
          try {
            const { hash } = await accountClient.installSessionKeyPlugin({
              args: [[], [], []],
            });

            const txHash = await accountClient.waitForUserOperationTransaction({
              hash,
            });

            setPluginStatus(PluginStatus.INSTALLED);
          } catch (e) {
            setPluginStatus(PluginStatus.FAILED);
          }
        }
      },
      []
  );

  const uninstallSessionKeyPlugin = useCallback(async () => {
    setPluginStatus(PluginStatus.LOADING);
    if (client?.chain?.id) {
      // @ts-expect-error temporary error
      await client.uninstallPlugin({
        pluginAddress: SessionKeyPlugin.meta.addresses[client.chain.id],
      });
      setPluginStatus(PluginStatus.INITIAL);
    }
  }, [client]);

  const installSessionKeyPlugin = useCallback(async () => {
    if (client?.chain?.id) {
      await initSessionKeyPlugin(client);
    }
  }, [initSessionKeyPlugin, client]);

  useEffect(() => {
    if (!client) {
      return;
    }
    setPluginStatus(PluginStatus.LOADING);
    isPluginInstalled(client).then((isInstalled) => {
      setPluginStatus(
          isInstalled ? PluginStatus.INSTALLED : PluginStatus.NOT_INSTALLED
      );
    });
  }, [client]);

  const switchChain = useCallback(
      (chainId: number) => {
        const createWallet = async (wallet: ConnectedWallet) => {
          // Switch the embedded wallet to your desired network
          await wallet.switchChain(chainId);
          const _config = getChainConfig(chainId);

          // Get a viem client from the embedded wallet
          const eip1193provider = await wallet.getEthereumProvider();
          const privyClient = createWalletClient({
            account: wallet.address as THexAddress,
            chain: _config.chain,
            transport: custom(eip1193provider),
          });

          // Create a smart account signer from the embedded wallet's viem client
          const privySigner: SmartAccountSigner = new WalletClientSigner(
              privyClient,
              "privy" // signerType
          );

          const smartAccountClient = (
              await createModularAccountAlchemyClient({
                apiKey: _config.alchemy.Api,
                chain: _config.chain,
                signer: privySigner,
                gasManagerConfig: {
                  policyId: _config.alchemy.Gas,
                },
              })
          ).extend(sessionKeyPluginActions);

          setClient(smartAccountClient);

          if (user && smartAccountClient?.account) {
            setResultingUser({
              id: user.id,
              wallet: {
                address: smartAccountClient.account.address,
                chainId,
              },
            });
          }
        };

        if (privyWallet) {
          createWallet(privyWallet);
        }
      },
      [privyWallet, user]
  );

  const loading = !ready || (authenticated && !client);

  const logout_ = useCallback(async () => {
    await logout();
    setClient(null);
  }, [logout]);

  const sendUserOperations = useCallback(
      async (operations: UserOperation[]) => {
        if (client) {
          const op = await client.sendUserOperation({
            uo: operations.map((item) => ({
              ...item,
              value: item.value ? BigInt(item.value) : 0n,
            })),
            account: client.account!,
          });

          return client.waitForUserOperationTransaction({
            hash: op.hash,
          });
        }
        throw new Error("Account is not created");
      },
      [client]
  );

  const createSessionKey = useCallback(
      async (permissions: THexAddress[]) => {
        if (!client?.account) {
          throw new Error("SmartAccountClient is not ready");
        }

        const pk = generatePrivateKey();
        const sessionKey = LocalAccountSigner.privateKeyToAccountSigner(pk);

        const key = await sessionKey.getAddress();

        const opResult = await client.addSessionKey({
          key,
          permissions,
          tag: keccak256(new TextEncoder().encode("session-key-tag")),
          account: client.account,
        });

        await client.waitForUserOperationTransaction({
          hash: opResult.hash,
        });

        return pk;
      },
      [client]
  );

  const value: SmartAccount = {
    privyUser: resultingUser,
    authenticated,
    loading,
    getAccessToken,
    login,
    logout: logout_,
    sendUserOperations,
    createSessionKey,
    switchChain,
    pluginStatus,
    installSessionKeyPlugin,
    uninstallSessionKeyPlugin,
  };

  return (
      <AlchemySmartAccountContext.Provider value={value}>
        {props.children}
      </AlchemySmartAccountContext.Provider>
  );
};

