import { buyBoost, checkTinderSwipe, claimOrStartMiningNet } from "@storage/net/decommas/tinder/tinderAPI";
import { Boost, TinderSwipe } from "./models";
import { directionToNet } from "./mappers";
import { getBoosts } from "@domain/tinder/repo";
import { BOOST_LEVEL } from "@utils/consts";

export function getSwipeResult({ chainId, poolAddress: tokenAddress, direction }: TinderSwipe) {
    return checkTinderSwipe(chainId, tokenAddress, directionToNet(direction));
}

export function claimOrStartMining(): Promise<any> {
    return claimOrStartMiningNet()
}

export async function getLevelUpBoost(): Promise<Boost> {
    const boosts = await getBoosts();
    return boosts.find((boost) => boost.slug === BOOST_LEVEL)!!;
}

export function buyLevelUpBoost() {
    return buyBoost(BOOST_LEVEL)
}