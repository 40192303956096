import { Quest } from "@domain/quest/models";
import { CheckIcon } from "@ui/icons/Icon";
import colors from "@ui/theme/foundations/colors";
import { Button, Flex, Text, VStack } from "@chakra-ui/react";
import QuestIcon from "@presentation/quest/components/QuestIcon";

export default function QuestListItem(props: {
    quest: Quest;
    started: boolean;
    onStartClick: () => void;
    onClaimClick: () => void;
}) {
    const { quest, started, onStartClick, onClaimClick } = props

    const button = quest.finished ? (
        <CheckIcon size={24} color={colors.tg.green} />
    ) : started ? (
        <Button variant="quest_green" onClick={onClaimClick}>
            Claim
        </Button>
    ) : (
        <Button variant="quest_blue" onClick={onStartClick}>
            Start
        </Button>
    );

    return (
        <Flex gap="12px" align="center" width="100%">
            <QuestIcon platform={quest.platform} />
            <VStack marginRight="auto" alignItems="flex-start" gap="2px">
                <Text fontSize="14px">{quest.name}</Text>
                <Text
                    textColor={colors.tg.textColorSecondary}
                    fontSize="12px"
                >{`+${quest.reward} SP`}</Text>
            </VStack>
            {button}
        </Flex>
    );
};