import { FriendsState } from "@presentation/friends/feature";
import { FriendsUIState, FriendUIState } from "@presentation/friends/FriendsUIState";
import { Referral } from "@domain/tg_user/models";

const appLink = process.env.REACT_APP_WEB_APP_DIRECT_LINK || "";

export function featureStateToUIState(state: FriendsState): FriendsUIState {
    const refLink = `${appLink}?startapp=degen_${state.refCode}`;

    return {
        refDataLoading: state.refDataLoading,
        referralCount: state.referralsCount,
        refBalance: state.refBalance,
        link: refLink,
        referralsLoading: state.referralsLoading,
        referrals: state.referrals.map(referralToUIState),
    };
}

function referralToUIState(referral: Referral): FriendUIState {
    const fullName = `${referral.userData.firstName ?? ""} ${referral.userData.lastName ?? ""}`;
    const imageUrl = referral.userData.photo
        ? `data:image/jpeg;base64,${referral.userData.photo}`
        : "/success_right.png";
    return {
        fullName: fullName,
        userName: referral.userData.userName ?? "",
        imageUrl: imageUrl,
        friendsCount: referral.referrals,
    };
}
