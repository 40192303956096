import { QuestsState } from "@presentation/quest/feature";
import { QuestCategoryUIState, QuestsUIState } from "@presentation/quest/QuestsUIState";
import { QuestsCategory } from "@domain/quest/models";

export const featureStateToUIState = (state: QuestsState): QuestsUIState => {
    const { quests, ...rest } = state;
    const allQuests = quests.map((category) => category.quests).reduce((res, value) => res.concat(value), [])
    const questsDisplayed = state.selectedCategoryId
        ? quests.find((category) => category.id === state.selectedCategoryId)?.quests ?? []
        : allQuests
    const categoryAll: QuestsCategory = {
        id: "",
        name: "All",
        quests: allQuests
    }
    const categories: QuestCategoryUIState[] = [categoryAll, ...quests]
        .map((category) => categoryToUIState(category, state.selectedCategoryId))

    return {
        categories: categories,
        questsDisplayed: questsDisplayed.filter(({ active }) => active).sort((a, b) => b.reward - a.reward),
        quests,
        ...rest,
    };
};

function categoryToUIState(category: QuestsCategory, selectedId: string): QuestCategoryUIState {
    return {
        id: category.id,
        name: category.name,
        selected: category.id === selectedId,
        hasUnfinished: category.quests.filter((quest) => !quest.finished).length > 0,
    }
}