import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {featureStateToUIState} from "../../stateMapper";
import {Button, Flex} from "@chakra-ui/react";
import spacing from "../../../../ui/theme/foundations/spacing";
import {Screen} from "../../../../router/Screen";
import {useNavigate} from "react-router-dom";
import SkeletonListItems from "../../../../ui/components/feedback/SkeletonListItems";
import TradeListItems from "../../../trades/components/TradeListItems";
import TradesEmptyState from "../../../trades/components/TradesEmptyState";
import {TRADE_SELECTED} from "../../../trades/trade/feature";

export default function TradesShortList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const state = useAppSelector(state => featureStateToUIState(state.dashboard)).trades

    const shouldShowSkeleton = state.items.length === 0 && state.loading
    const content = state.items.length > 0
        ? <TradeListItems
            items={state.items}
            onItemClick={(id) => {
                dispatch(TRADE_SELECTED(id))
                navigate(Screen.TRADE_DETAILS)
            }}
        />
        : <TradesEmptyState />
    const listItems = shouldShowSkeleton
        ? <SkeletonListItems count={5}/>
        : content

    return (
        <Flex
            direction='column'
            gap={spacing.content_spacing_v}
        >
            <Flex direction='column'>
                {listItems}
            </Flex>

            {state.items.length === 5 &&
                <Button
                    variant='main'
                    onClick={ () => navigate(Screen.TRADES) }
                >
                    See all
                </Button>
            }
        </Flex>
    )
}