import { Box } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Screen } from "../../router/Screen";
import { isContentRootScreen, settingsAvailable } from "../../router/Router";
import { isDesktopPlatform, showBackButton, showSettingsButton, WebApp } from "@ui/telegram/utils";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { CLEAN_UP } from "../auth/feature";

export function Root() {
    const state = useAppSelector((state) => state.account);
    useTelegramUI();
    useNavigationFlow(state.tgAuthenticated);

    return (
        <Box w="full" h="100dvh" position={"fixed"} overflow={"hidden"}>
            <Outlet />
        </Box>
    );
}

function useNavigationFlow(authenticated: boolean) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        navigate(Screen.AUTH, { replace: true });
    }, []);

    useEffect(() => {
        if (isDesktopPlatform()) {
            navigate(Screen.NO_DESKTOP, { replace: true });
        } else if (!authenticated) {
            navigate(Screen.AUTH);
            dispatch(CLEAN_UP());
        }
    }, [authenticated]);
}

function useTelegramUI() {
    WebApp.setHeaderColor("secondary_bg_color");
    WebApp.expand();
    WebApp.enableClosingConfirmation();
    WebApp.disableVerticalSwipes();

    //useSettingsButton()
    useBackButton();
}

function useBackButton() {
    const location = useLocation();
    const show = !isContentRootScreen(location.pathname);
    showBackButton(show);
    useOnBackClick();
}

function useSettingsButton() {
    const location = useLocation();
    const show = settingsAvailable(location.pathname);
    showSettingsButton(show);
    useOnSettingsClick();
}

function useOnSettingsClick() {
    const navigate = useNavigate();
    const callback = useCallback(() => {
        navigate(Screen.SETTINGS);
    }, []);
    useEffect(() => {
        WebApp.SettingsButton.onClick(callback);
        return () => {
            WebApp.SettingsButton.offClick(callback);
        };
    });
}

function useOnBackClick() {
    const navigate = useNavigate();
    const callback = useCallback(() => {
        navigate(-1);
    }, []);
    useEffect(() => {
        WebApp.BackButton.onClick(callback);
        return () => {
            WebApp.BackButton.offClick(callback);
        };
    });
}
