import { TinderState } from "./feature";
import { TinderUIState } from "./TinderUIState";
import { EMPTY_TINDER_STATS } from "@utils/consts";

export function featureStateToUIState(state: TinderState): TinderUIState {
    //until better math
    const totalPoints = state.stats.balance;
    const totalPointsLoading = state.statsLoading && state.stats === EMPTY_TINDER_STATS;
    const pointsPerCorrect = 3 + (state.stats.level - 1)

    return {
        ...state,

        totalPoints,
        totalPointsLoading,
        pointsPerCorrect
    };
}
