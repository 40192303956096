import {Page} from "../../ui/components/containers/Page";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {featureStateToInvitesUIState} from "./stateMapper";
import {useEffect} from "react";
import {INVITES_LOAD_REQUIRED} from "./feature";
import InviteListItems from "./components/InviteListItems";
import {Flex, Text, useToast} from "@chakra-ui/react";
import InvitesEmptyState from "./components/InvitesEmptyState";
import SkeletonListItems from "../../ui/components/feedback/SkeletonListItems";
import spacing from "../../ui/theme/foundations/spacing";
import fontSizes from "../../ui/theme/foundations/fontSizes";
import colors from "../../ui/theme/foundations/colors";
import {BaseCard} from "../../ui/components/containers/BaseCard";

const SUBTITLE = "Get 1 level and 1 invite code for each invited person who has registered and made a trade"

export default function InviteScreen() {
    const state = useAppSelector(state => featureStateToInvitesUIState(state.invites))
    const dispatch = useAppDispatch()
    const toast = useToast()


    useEffect(() => {
        dispatch(INVITES_LOAD_REQUIRED())
    }, [])

    const noItems = state.items.length === 0
    const shouldShowSkeleton = noItems && state.loading
    const content = state.items.length > 0
        ? <InviteListItems
            items={state.items}
            onItemClick={(code) => {
                navigator.clipboard.writeText(code)
                toast({
                    title: 'Copied!',
                    status: 'success',
                    duration: 600,
                    isClosable: false,
                })
            }}
        />
        : <InvitesEmptyState />
    const listItems = shouldShowSkeleton
        ? <SkeletonListItems count={5} />
        : content

    return (
        <Page>
            <Flex direction='column' gap={spacing.content_spacing_v}>
                <Text fontSize={fontSizes.page_title} as='b'>
                    Invites
                </Text>
                <Text
                    fontSize={fontSizes.small}
                    textColor={colors.tg.textColorSecondary}
                >
                    {SUBTITLE}
                </Text>

                <BaseCard>
                    <Flex direction='column'>
                        {listItems}
                    </Flex>
                </BaseCard>

            </Flex>
        </Page>
    )
}