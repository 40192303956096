import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {RouterProvider} from "react-router-dom";
import {ChakraProvider } from "@chakra-ui/react";
import theme from "./ui/theme/theme";
import {store} from "./app/store";
import {Provider} from "react-redux";
import router from "./router/Router";
import {AAProvider} from "./domain/auth/alchemy/AAProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <AAProvider>
              <ChakraProvider theme={theme}>
                  <RouterProvider router={router} />
              </ChakraProvider>
          </AAProvider>
      </Provider>
  </React.StrictMode>
);
