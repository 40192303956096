import { TokenUIState } from "../TokensUIState";
import TokenListItem from "./TokenListItem";
import { Flex } from "@chakra-ui/react";
import Divider from "../../dashboard/components/details/Divider";
import spacing from "../../../ui/theme/foundations/spacing";

export default function TokenListItems(props: {
    items: TokenUIState[];
    onItemClick: (id: string) => void;
}) {
    return props.items.map((item, index) => {
        const listItem = (
            <TokenListItem key={item.symbol} item={item} onClick={(id) => props.onItemClick(id)} />
        );

        return (
            <Flex direction="column" w="full">
                {listItem}
                {index < props.items.length - 1 && (
                    <Divider paddingStart={spacing.divider_token_padding_start} />
                )}
            </Flex>
        );
    });
}
