import {BaseCard} from "../../../../ui/components/containers/BaseCard";
import SectionPlaceholder from "../../../../ui/components/section/SectionPlaceholder";
import SectionHeader from "../../../../ui/components/section/SectionHeader";
import SectionLabel from "../../../../ui/components/section/SectionLabel";
import Price from "./Price";
import PairSelector from "./PairSelector";
import {HStack, Skeleton} from "@chakra-ui/react";
import spacing from "../../../../ui/theme/foundations/spacing";
import Chart from "./Chart";
import {PairUIState} from "../../TerminalUIState";
import fontSizes from "../../../../ui/theme/foundations/fontSizes";
import colors from "../../../../ui/theme/foundations/colors";

export default function TradingPair(
    props: {state: PairUIState}
) {
    return (
        <SectionPlaceholder
            header={
                <SectionHeader
                    left={
                        <SectionLabel text='Trading pair'/>
                    }
                />
            }
            content={
                <HStack
                    spacing={spacing.content_spacing_h_medium}
                >
                    <BaseCard
                        variant='section'
                    >
                        <PairSelector
                            state={props.state}
                        />
                    </BaseCard>

                    {props.state.chartVisible &&
                        <Chart />
                    }
                </HStack>
            }
            footer={
                props.state.priceLoading
                    ?  <Skeleton
                        borderRadius='6px'
                        w='180px'
                        h='18px'
                        startColor={colors.tg.skeletonStart}
                        endColor={colors.tg.skeletonEnd}
                    />
                    : <Price state={props.state} />
            }
        />
    )
}