import {TokensState} from "./feature";
import {UserToken} from "../../domain/tokens/models";
import {TokensUIState, TokenUIState} from "./TokensUIState";

export function featureStateToTokensUIState(state: TokensState): TokensUIState {
    const tokens = state.tokens.map((token) => tokenToUIState(token))
    return {
        items: tokens,
        loading: state.tokensLoading,
        loadingError: state.tokensLoadingError
    }
}

export function tokenToUIState(token: UserToken): TokenUIState {
    const amount = token.amount.toN().movePointLeft(token.decimals)
    const price = token.actualPrice.toN()
    return {
        icon: token.logoUrl,
        symbol: token.symbol,
        amount: amount,
        amountUsd: price * amount,
        priceUsd: price,
        changePercentage: 0
    }
}