import {Flex, HStack, Skeleton} from "@chakra-ui/react";
import sizes from "../../theme/foundations/sizes";
import fontSizes from "../../theme/foundations/fontSizes";
import colors from "../../theme/foundations/colors";

export default function SkeletonListItem() {

    return (
        <HStack
            w='full'
            px={"16px"}
            py={"12px"}
        >
            <Skeleton
                borderRadius='full'
                h={sizes.asset_list_item_logo}
                w={sizes.asset_list_item_logo}
                startColor={colors.tg.skeletonStart}
                endColor={colors.tg.skeletonEnd}
            />

            <Flex
                w='full'
                direction='column'
                gap='10px'
            >
                <HStack
                    w='full'
                    justifyContent='space-between'
                >
                    <Skeleton
                        borderRadius='6px'
                        w='80px'
                        h={fontSizes.medium}
                        startColor={colors.tg.skeletonStart}
                        endColor={colors.tg.skeletonEnd}
                    />
                    <Skeleton
                        borderRadius='6px'
                        w='60px'
                        h={fontSizes.medium}
                        startColor={colors.tg.skeletonStart}
                        endColor={colors.tg.skeletonEnd}
                    />

                </HStack>

                <HStack
                    w='full'
                    justifyContent='space-between'
                >
                    <Skeleton
                        borderRadius='6px'
                        w='60px'
                        h={fontSizes.small}
                        startColor={colors.tg.skeletonStart}
                        endColor={colors.tg.skeletonEnd}
                    />
                    <Skeleton
                        borderRadius='6px'
                        w='80px'
                        h={fontSizes.small}
                        startColor={colors.tg.skeletonStart}
                        endColor={colors.tg.skeletonEnd}
                    />

                </HStack>

            </Flex>

        </HStack>
    )
}
