import { TgUserInfo } from "@domain/tg_user/models";
import { Boost, TinderStats } from "@domain/tinder/models";
import { EMPTY_BOOST, EMPTY_TINDER_STATS } from "@utils/consts";
import { AppThunk } from "@app/store";
import { createSlice, PayloadAction as Effect } from "@reduxjs/toolkit";
import { getTgUserInfo } from "@domain/tg_user/repo";
import { getTinderStats } from "@domain/tinder/repo";
import { buyLevelUpBoost, claimOrStartMining, getLevelUpBoost } from "@domain/tinder/usecases";

export interface FarmingState {
    user: TgUserInfo;
    userLoading: boolean;
    userLoadingError?: string;

    gameStats: TinderStats;
    gameStatsLoading: boolean;
    gameStatsLoadingError?: string;

    lastLocalUpdate: number;
    aiPointsLocal: number;

    levelUpBoost: Boost;
    levelUpBoostLoading: boolean;
    levelUpBoostLoadingError?: string;

    levelUpInProgress: boolean;
    levelUpSuccess: boolean;
    levelUpError?: string;
}

const initialState: FarmingState = {
    user: getLocalTgUserInfo(),
    userLoading: false,
    userLoadingError: undefined,

    gameStats: EMPTY_TINDER_STATS,
    gameStatsLoading: false,
    gameStatsLoadingError: undefined,

    lastLocalUpdate: Date.now(),
    aiPointsLocal: 0,

    levelUpBoost: EMPTY_BOOST,
    levelUpBoostLoading: false,

    levelUpInProgress: false,
    levelUpSuccess: false,
}

//slice

const slice = createSlice({
    name: "farming",
    initialState,
    reducers: {
        userLoadingStarted,
        userInfoLoaded,
        userLoadingError,
        gameStatsLoadingStarted,
        gameStatsLoaded,
        gameStatsLoadingError,
        aiPointsUpdatedLocal,
        levelUpBoostLoadingStarted,
        levelUpBoostLoaded,
        levelUpBoostLoadingError,
        levelUpInProgress,
        levelUpError,
        levelUpSuccess,
        levelCleanedUp,
    }
})

//actions

const loadData = (): AppThunk => async (dispatch) => {
    dispatch(loadUser())
    dispatch(loadGameData())
}

const loadUser = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.userLoadingStarted())

    try {
        const info = await getTgUserInfo()
        dispatch(slice.actions.userInfoLoaded({
            info: info
        }))
    } catch (e) {
        dispatch(slice.actions.userLoadingError({
            error: (e as object).toString(),
        }))
    }
}

const loadGameData = (resetLocal: boolean = false): AppThunk => async (dispatch) => {
    dispatch(slice.actions.gameStatsLoadingStarted())

    try {
        const stats = await getTinderStats()
        dispatch(slice.actions.gameStatsLoaded({
            stats: stats,
            resetLocal: resetLocal,
        }))
    } catch (e) {
        dispatch(slice.actions.gameStatsLoadingError({
            error: (e as object).toString(),
        }))
    }
}

const loadLevelUpBoost = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.levelUpBoostLoadingStarted())

    try {
        const boost = await getLevelUpBoost()
        dispatch(slice.actions.levelUpBoostLoaded({
            boost: boost
        }))
    } catch (e) {
        dispatch(slice.actions.levelUpBoostLoadingError({
            error: (e as object).toString(),
        }))
    }
}

const upgradeLevel = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.levelUpInProgress())

    try {
        await buyLevelUpBoost()
        dispatch(slice.actions.levelUpSuccess())
        dispatch(loadData())
        dispatch(loadLevelUpBoost())
    } catch (e) {
        dispatch(slice.actions.levelUpError({
            error: (e as object).toString(),
        }))
    }
}

const levelCleanUp = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.levelCleanedUp())
}

const miningAction = (): AppThunk => async(dispatch) => {
    try {
        await claimOrStartMining()
        dispatch(loadGameData(true))
    } catch (e) {
        console.log("Error mining action")
    }
}

const updateMiningPointsLocal = (): AppThunk => async(dispatch, getState) => {
    const state = getState().farming
    if (state.gameStats.lastAction) {
        const now = Date.now()
        const sinceLastUpdate = now - state.lastLocalUpdate
        const maxMsForAi = state.gameStats.maxHoursForAi*3600*1000
        const pointsPerMs = state.gameStats.maxAiPoints / maxMsForAi
        const pointsToAdd = pointsPerMs * sinceLastUpdate
        dispatch(slice.actions.aiPointsUpdatedLocal({
            timestamp: now,
            pointsToAdd: pointsToAdd
        }))
    }
}

function getLocalTgUserInfo(): TgUserInfo {
    const data = window.Telegram.WebApp.initDataUnsafe
    return {
        userData: {
            id: "",
            firstName: data.user?.first_name,
            lastName: data.user?.last_name,
            userName: data.user?.username,
        }
    }
}

//effects

function userLoadingStarted(state: FarmingState) {
    state.userLoading = true
    state.userLoadingError = undefined
}

function userInfoLoaded(state: FarmingState, effect: Effect<{ info: TgUserInfo }>) {
    state.userLoading = false
    state.user = effect.payload.info
}

function userLoadingError(state: FarmingState, effect: Effect<{ error: string }>) {
    state.userLoading = false
    state.userLoadingError = effect.payload.error
}

function gameStatsLoadingStarted(state: FarmingState) {
    state.gameStatsLoading = true
    state.gameStatsLoadingError = undefined
}

function gameStatsLoaded(state: FarmingState, effect: Effect<{ stats: TinderStats, resetLocal: boolean }>) {
    state.gameStatsLoading = false
    state.gameStats = effect.payload.stats
    if (effect.payload.resetLocal) {
        state.aiPointsLocal = effect.payload.stats.aiPoints
    } else {
        state.aiPointsLocal = Math.max(effect.payload.stats.aiPoints, state.aiPointsLocal)
    }
}

function gameStatsLoadingError(state: FarmingState, effect: Effect<{ error: string }>) {
    state.gameStatsLoading = false
    state.gameStatsLoadingError = effect.payload.error
}

function aiPointsUpdatedLocal(state: FarmingState, effect: Effect<{ timestamp: number, pointsToAdd: number }>) {
    const result = state.aiPointsLocal + effect.payload.pointsToAdd
    state.aiPointsLocal = Math.min(result, state.gameStats.maxAiPoints)
    state.lastLocalUpdate = effect.payload.timestamp
}

function levelUpBoostLoadingStarted(state: FarmingState) {
    state.levelUpBoostLoading = true
    state.levelUpBoostLoadingError = undefined
    state.levelUpError = undefined
}

function levelUpBoostLoaded(state: FarmingState, effect: Effect<{ boost: Boost }>) {
    state.levelUpBoostLoading = false
    state.levelUpBoost = effect.payload.boost
}

function levelUpBoostLoadingError(state: FarmingState, effect: Effect<{ error: string }>) {
    state.levelUpBoostLoading = false
    state.levelUpBoostLoadingError = effect.payload.error
}

function levelUpInProgress(state: FarmingState) {
    state.levelUpInProgress = true
    state.levelUpError = undefined
}

function levelUpSuccess(state: FarmingState) {
    state.levelUpInProgress = false
    state.levelUpSuccess = true
}

function levelUpError(state: FarmingState, effect: Effect<{ error: string }>) {
    state.levelUpInProgress = false
    state.levelUpError = effect.payload.error
}

function levelCleanedUp(state: FarmingState) {
    state.levelUpInProgress = false
    state.levelUpSuccess = false
    state.levelUpError = undefined
}

export const farmingReducer = slice.reducer,
    LOAD_DATA = loadData,
    MINING_ACTION = miningAction,
    MINING_UPDATE = updateMiningPointsLocal,
    LOAD_GAME_DATA = loadGameData,
    UPGRADE_LEVEL = upgradeLevel,
    LOAD_LEVEL_BOOST = loadLevelUpBoost,
    LEVEL_CLEAN_UP = levelCleanUp