import {InvitesState} from "./feature";
import {InvitesUIState, InviteUIState} from "./InvitesUIState";
import {InviteNet} from "../../storage/net/decommas/user/models";

export function featureStateToInvitesUIState(state: InvitesState): InvitesUIState {
    const items = state.invites.map((invite) => inviteToUIState(invite))
    return {
        items: items,
        loading: state.invitesLoading,
        loadingError: state.invitesLoadingError,
    }
}

export function inviteToUIState(invite: InviteNet): InviteUIState {
    return {
        code: invite.code,
        available: invite.free,
    }
}