import {defineStyleConfig} from "@chakra-ui/react";
import colors from "../foundations/colors";
import radius from "../foundations/radius";
import spacing from "../foundations/spacing";

const BaseCard = defineStyleConfig({
    baseStyle: {
        width: 'full',
        display: 'flex',
        flexDirection: 'column',
        bgColor: colors.tg.sectionBgColor,
        borderRadius: radius.card,
    },
    variants: {
        content: {
            paddingX: spacing.card_padding_h,
            paddingY: spacing.card_padding_v,
            gap: spacing.content_spacing_v
        },
        section: {
            padding: '0px'
        },
    },
    defaultProps: {
        variant: 'content',
    }
})

export default BaseCard