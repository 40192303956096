import {Flex} from "@chakra-ui/react";
import SkeletonSectionItem from "./SkeletonSectionItem";
import spacing from "../../theme/foundations/spacing";

export default function SkeletonSectionItems(props: {
    count: number
}) {

    return (
        <Flex
            direction='column'
            w='full'
            gap={spacing.content_spacing_v}
        >
            {
                Array.from({ length: props.count }).map(() => {
                    return <SkeletonSectionItem />
                })
            }
        </Flex>
    )
}