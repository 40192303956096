import ModalContainer from "../../../ui/components/feedback/ModalContainer";
import {EmptyIcon, TradeIcon} from "../../../ui/icons/Icon";
import {useNavigate} from "react-router-dom";
import {Screen} from "../../../router/Screen";
import {Button} from "@chakra-ui/react";

export default function TradesEmptyState() {
    const navigate = useNavigate()

    return (
        <ModalContainer
            icon={ <EmptyIcon size='85px' /> }
            title={ "Nothing here yet" }
            subtitle={ "Create trade" }
            action={
                <Button
                    variant='wallet_action'
                    leftIcon={ <TradeIcon size='action_button_icon' /> }
                    onClick={ () => navigate(Screen.POOLS, { replace: true }) }
                >
                    Trade
                </Button>
            }
        />
    )
}