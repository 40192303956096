export const space = {
    page_padding_h: '16px',
    page_padding_v: '16px',
    content_spacing_v: '14px',
    content_spacing_h_small: '4px',
    content_spacing_h_medium: '10px',
    card_padding_h: '16px',
    card_padding_v: '12px',
    button_wallet_action_padding_x: '10px',
    button_wallet_action_padding_y: '10px',
    button_network_select_padding_x: '0px',
    button_network_select_padding_y: '0px',
    button_refresh_padding: '6px',
    button_segmented_control_padding_y: '6px',
    segmented_control_container_padding: '4px',
    menu_item_padding: '8px',
    button_main_padding_y: '14px',
    list_item_padding_y: '8px',
    list_item_padding_x: '0px',
    list_item_logo_padding_x: '8px',
    list_item_text_spacing_y: '2px',
    list_item_text_spacing_x: '2px',
    divider_token_padding_start: '44px',
    section_header_padding_x: '16px',
    section_around_padding_y: '8px',
    section_label_spacing_x: '4px'
}

export default space