import {InviteUIState} from "../InvitesUIState";
import {Flex} from "@chakra-ui/react";
import Divider from "../../dashboard/components/details/Divider";
import InviteListItem from "./InviteListItem";

export default function InviteListItems(props: {
    items: InviteUIState[],
    onItemClick: (code: string) => void,
}) {
    return props.items.map((item, index) => {
        const listItem = <InviteListItem
            item={item}
            onClick={(code) => props.onItemClick(code)}
        />
        return (
            <Flex
                direction='column'
                w='full'
            >
                {listItem}
                {index < props.items.length - 1 &&
                    <Divider />}
            </Flex>
        )
    })
}