import {ReactNode} from "react";
import {HStack} from "@chakra-ui/react";

export default function SectionHeader(
    props: { left: ReactNode, right?: ReactNode }
) {
    const {left, right} = props

    return (
        <HStack
            w='full'
            justifyContent='space-between'
        >
            {left}
            {right}
        </HStack>
    )

}