import {HStack, Image, Text} from "@chakra-ui/react";
import sizes from "../../../../ui/theme/foundations/sizes";
import {useAppSelector} from "../../../../app/hooks";
import {CHAINS} from "../../../../utils/consts";



export default function NetworkSelector() {
    const state = useAppSelector(state => state.account)
    const currentChain = CHAINS.find(value => value.id.valueOf() === state.wallet.chainId) ?? CHAINS[0]

    return (
        <HStack
            gap='8px'
            paddingY='12px'
        >
            <ChainLogo url={currentChain.logoUrl} />
            <Text>{currentChain.title}</Text>
        </HStack>
    )
}

function ChainLogo(props: {url: string}) {
    return (
        <Image
            src={props.url}
            h={sizes.network_selector_menu_logo}
            w={sizes.network_selector_menu_logo}
            borderRadius='full'
        />
    )
}