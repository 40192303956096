import { State } from "./feature";
import { TerminalUIState } from "./TerminalUIState";
import { OrderDirection, OrderType } from "../../domain/trades/models";
import { toFormatted } from "../../ui/utils";
import validateState from "./validator";
import { EMPTY_POOL, NO_VAL } from "../../utils/consts";

export function featureStateToUIState(state: State): TerminalUIState {
    const poolFirstLoading = state.poolLoading && state.pool === EMPTY_POOL;
    const balancesFirstLoading = state.balancesLoading && state.balances.size === 0;
    const basePrice = state.pool.limits.price;
    const condition = !isEmpty(state.conditionPrice) ? state.conditionPrice : "";
    const amount = !isEmpty(state.amount) ? state.amount : "";
    const total = !isEmpty(state.total) ? state.total : "";
    const base = state.pool.baseToken;
    const quote = state.pool.quoteToken;
    const baseBalanceGwei = state.balances[base?.address ?? ""] ?? 0;
    const quoteBalanceGwei = state.balances[quote?.address ?? ""] ?? 0;
    const baseBalance = baseBalanceGwei.movePointLeft(base?.decimals ?? 16);
    const quoteBalance = quoteBalanceGwei.movePointLeft(quote?.decimals ?? 16);
    const baseBalanceFormatted = toFormatted(baseBalance, 8);
    const quoteBalanceFormatted = toFormatted(quoteBalance, 8);
    const validationErrors = validateState(state);
    const conditionErrors = validationErrors.get("condition") ?? [];
    const amountErrors = validationErrors.get("amount") ?? [];
    const totalErrors = validationErrors.get("total") ?? [];
    const tpErrors = validationErrors.get("takeProfit") ?? [];
    const slErrors = validationErrors.get("stopLoss") ?? [];
    const noErrors =
        [...conditionErrors, ...amountErrors, ...totalErrors, ...tpErrors, ...slErrors].length ===
        0;

    return {
        pair: {
            baseLogoUrl: base?.logoUrl,
            baseSymbol: base?.symbol ?? "",
            quoteLogoUrl: quote?.logoUrl,
            quoteSymbol: quote?.symbol ?? "",
            chartVisible: state.chartAvailable,
            price: basePrice.toFixed(basePrice.countDecimals()),
            priceLoading: state.poolLoading,
        },
        tradeType: {
            orderType: state.orderType,
            orderDirection: state.orderDirection,
            orderDirectionVisible: state.orderType !== OrderType.SMART_TRADE,
        },
        condition: {
            value: condition,
            quoteLogoUrl: quote.logoUrl,
            baseSymbol: base.symbol,
            quoteSymbol: quote.symbol,
            visible: state.orderType === OrderType.CONDITIONAL,
            error: conditionErrors.at(0),
        },
        amount: {
            title: state.orderDirection === OrderDirection.BUY ? "You get" : "You sell",
            value: amount,
            baseLogoUrl: base.logoUrl,
            baseSymbol: base.symbol,
            balance: baseBalanceFormatted,
            minLotStep: state.pool.limits.baseLotStep,
            error: amountErrors.at(0),
        },
        total: {
            title: state.orderDirection === OrderDirection.BUY ? "You pay" : "You get",
            value: total,
            quoteLogoUrl: quote.logoUrl,
            quoteSymbol: quote.symbol,
            balance: quoteBalanceFormatted,
            minLotStep: state.pool.limits.quoteLotStep,
            error: totalErrors.at(0),
        },
        smartTrade: {
            visible: state.orderType === OrderType.SMART_TRADE,
            takeProfit: {
                enabled: state.takeProfitEnabled,
                title: "TAKE PROFIT",
                value: state.takeProfitPercent,
                error: tpErrors.at(0),
            },
            stopLoss: {
                enabled: state.stopLossEnabled,
                title: "STOP LOSS",
                value: state.stopLossPercent,
                error: slErrors.at(0),
            },
        },
        button: {
            text: getButtonText(state),
            enabled: noErrors && !stConditionsRequired(state),
            loading: state.createTradeInProgress,
        },
        status: {
            shouldShowSkeleton: poolFirstLoading || balancesFirstLoading,
            error: state.createTradeError,
            tradeCreated: state.createTradeSuccess,
        },
    };
}

function stConditionsRequired(state: State): boolean {
    return (
        state.orderType === OrderType.SMART_TRADE &&
        !state.stopLossEnabled &&
        !state.takeProfitEnabled
    );
}

function getButtonText(state: State): string {
    return stConditionsRequired(state) ? "TP or SL required" : "Create";
}

function isEmpty(value: string): boolean {
    return value === NO_VAL || Number.isNaN(Number(value));
}
