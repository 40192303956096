import { createSlice, PayloadAction as Effect } from "@reduxjs/toolkit";
import { AppThunk } from "@app/store";
import { createTgUser, createUser, isDecommasAuthorized } from "@domain/auth/usecases";
import { Wallet } from "@domain/wallet/models";
import { getCurrentWallet } from "@domain/wallet/repo";
import { NO_WALLET } from "@utils/consts";
import { saveAuthToken } from "@storage/net/utils";

export interface AccountState {
    tgAuthenticated: boolean;
    privyAuthenticated: boolean;
    decommasAuthorized: boolean;
    wallet: Wallet;
    initialCheckInProgress: boolean;
    decommasAuthInProgress: boolean;
    decommasAuthError?: string;
}

const initialState: AccountState = {
    tgAuthenticated: false,
    privyAuthenticated: false,
    decommasAuthorized: false,
    wallet: NO_WALLET,
    initialCheckInProgress: true,
    decommasAuthInProgress: false,
    decommasAuthError: undefined,
};

const updateTgAuth =
    (init: any): AppThunk =>
    async (dispatch) => {
        try {
            const user = await createTgUser(init);
            saveAuthToken(user.data.token);
            dispatch(slice.actions.tgAuthUpdated({ authenticated: true }));
        } catch (e) {
            console.log(e);
        }
    };

const updateAuth =
    (privyAuthenticated: boolean): AppThunk =>
    async (dispatch) => {
        const authorized = await isDecommasAuthorized();
        dispatch(slice.actions.decommasAuthUpdated({ authorized: authorized }));
        dispatch(slice.actions.privyAuthUpdated({ authenticated: privyAuthenticated }));
    };

const updateWallet =
    (wallet: Wallet): AppThunk =>
    async (dispatch, getState) => {
        const state = getState().account;
        dispatch(slice.actions.walletUpdated({ wallet: wallet }));
        if (state.privyAuthenticated && !state.decommasAuthorized && wallet.address) {
            dispatch(createStierUser());
        }
    };

const logoutUser = (): AppThunk => async (dispatch) => {
    dispatch(
        slice.actions.privyAuthUpdated({
            authenticated: false,
        }),
    );
    dispatch(
        slice.actions.decommasAuthUpdated({
            authorized: false,
        }),
    );
};

const createStierUser = (): AppThunk => async (dispatch) => {
    const wallet = getCurrentWallet();
    dispatch(slice.actions.decommasAuthInProgress());
    try {
        await createUser(wallet.chainId, wallet.address);
        dispatch(
            slice.actions.decommasAuthUpdated({
                authorized: true,
            }),
        );
    } catch (e) {
        dispatch(
            slice.actions.decommasAuthError({
                error: e?.toString(),
            }),
        );
    }
};

const cleanUp = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.reset());
};

export const slice = createSlice({
    name: "account",
    initialState,
    reducers: {
        tgAuthUpdated,
        privyAuthUpdated,
        decommasAuthUpdated,
        decommasAuthInProgress,
        decommasAuthError,
        walletUpdated,
        reset,
    },
});

function tgAuthUpdated(state: AccountState, effect: Effect<{ authenticated: boolean }>) {
    state.tgAuthenticated = effect.payload.authenticated;
    state.initialCheckInProgress = false;
}

function privyAuthUpdated(state: AccountState, effect: Effect<{ authenticated: boolean }>) {
    state.privyAuthenticated = effect.payload.authenticated;
    state.initialCheckInProgress = false;
}

function decommasAuthUpdated(state: AccountState, effect: Effect<{ authorized: boolean }>) {
    state.decommasAuthorized = effect.payload.authorized;
    state.decommasAuthInProgress = false;
}

function decommasAuthInProgress(state: AccountState) {
    state.decommasAuthInProgress = true;
    state.decommasAuthError = undefined;
}

function decommasAuthError(state: AccountState, effect: Effect<{ error?: string }>) {
    state.decommasAuthInProgress = false;
    state.decommasAuthError = effect.payload.error;
}

function walletUpdated(state: AccountState, effect: Effect<{ wallet: Wallet }>) {
    state.wallet = effect.payload.wallet;
}

function reset(state: AccountState) {
    Object.assign(state, initialState);
}

export const accountReducer = slice.reducer,
    TG_AUTH_UPDATED = updateTgAuth,
    AUTH_UPDATED = updateAuth,
    WALLET_UPDATED = updateWallet,
    USER_LOGGED_OUT = logoutUser,
    CLEAN_UP = cleanUp;
