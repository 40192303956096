import {useAppSelector} from "../../../../app/hooks";
import {featureStateToUIState} from "../../stateMapper";
import spacing from "../../../../ui/theme/foundations/spacing";
import {Button, Flex} from "@chakra-ui/react";
import {Screen} from "../../../../router/Screen";
import {useNavigate} from "react-router-dom";
import TokenListItems from "../../../tokens/components/TokenListItems";
import SkeletonListItems from "../../../../ui/components/feedback/SkeletonListItems";
import TokensEmptyState from "../../../tokens/components/TokensEmptyState";

export default function TokensShortList() {
    const navigate = useNavigate()
    const state = useAppSelector(state => featureStateToUIState(state.dashboard)).tokens

    const shouldShowSkeleton = state.items.length === 0 && state.loading
    const content = state.items.length > 0
        ? <TokenListItems
            items={state.items}
            onItemClick={(symbol) => {
                window.open(`https://www.coingecko.com/en/coins/${symbol.toLowerCase()}`, '_blank')
            }}
        />
        : <TokensEmptyState />
    const listItems = shouldShowSkeleton
        ? <SkeletonListItems count={5}/>
        : content

    return (
        <Flex
            direction='column'
            gap={spacing.content_spacing_v}
        >
            <Flex direction='column'>
                {listItems}
            </Flex>

            {state.items.length === 5 &&
                <Button
                    variant='main'
                    onClick={ () => navigate(Screen.TOKENS) }
                >
                    See all
                </Button>
            }
        </Flex>
    )
}

