import { AppThunk } from "@app/store";
import { createSlice, PayloadAction as Effect } from "@reduxjs/toolkit";
import {
    SwipeDirection,
    TinderPoolDetails,
    TinderPoolShort,
    TinderStats,
    TinderSwipeResult,
} from "@domain/tinder/models";
import { getTinderPoolDetails, getTinderPoolsShort, getTinderStats } from "@domain/tinder/repo";
import { getSwipeResult } from "@domain/tinder/usecases";
import { EMPTY_TINDER_STATS } from "@utils/consts";
import { randomIntFromInterval } from "@utils/number";

export interface TinderState {
    tokens: TinderPoolShort[];
    tokensLoading: boolean;
    tokensLoadingError?: string;

    currentTokenDetails: TinderPoolDetails | null;
    currentTokenDetailsLoading: boolean;

    swipeResult: TinderSwipeResult | null;
    swipeResultLoading: boolean;
    swipeResultError?: string;

    stats: TinderStats;
    statsLoading: boolean;
    statsLoadingError?: string;
}

const initialState: TinderState = {
    tokens: [],

    currentTokenDetails: null,
    currentTokenDetailsLoading: false,

    tokensLoading: false,
    tokensLoadingError: undefined,

    swipeResult: null,
    swipeResultLoading: false,

    stats: EMPTY_TINDER_STATS,
    statsLoading: false,
    statsLoadingError: undefined,
};

const slice = createSlice({
    name: "tinder",
    initialState,
    reducers: {
        tokensLoadingStarted,
        tokensLoaded,
        tokensLoadingError,
        swipeResultError,
        swipeResultLoaded,
        swipeResultApplied,
        advance,
        detailsLoadingStarted,
        detailsLoaded,
        statsLoadingStarted,
        statsLoaded,
        statsLoadingError,
    },
});

const loadStats = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.statsLoadingStarted());

    try {
        const stats = await getTinderStats();
        console.log(`balance ${stats.balance}`)
        dispatch(
            slice.actions.statsLoaded({
                stats: stats,
            }),
        );
    } catch (e) {
        dispatch(
            slice.actions.statsLoadingError({
                error: (e as object).toString(),
            }),
        );
    }
};

const loadTokens = (): AppThunk => async (dispatch, getState) => {
    if (getState().tinder.tokensLoading) {
        // in case several token batches are trying to get loaded
        return;
    }

    dispatch(slice.actions.tokensLoadingStarted());
    try {
        const tokens = await getTinderPoolsShort();
        dispatch(
            slice.actions.tokensLoaded({
                tokens: tokens,
            }),
        );
        dispatch(gotoNextToken());
    } catch (e) {
        dispatch(
            slice.actions.tokensLoadingError({
                error: (e as object).toString(),
            }),
        );
    }
};

const gotoNextToken = (): AppThunk => async (dispatch, getState) => {
    const nextIndex = randomIntFromInterval(0, getState().tinder.tokens.length - 1);

    dispatch(slice.actions.advance());
    dispatch(loadTokenDetails(getState().tinder.tokens[nextIndex]));
};

const applySwipeResult = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.swipeResultApplied())
    dispatch(gotoNextToken())
}

const loadTokenDetails =
    (pool: TinderPoolShort): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(slice.actions.detailsLoadingStarted());
            const details = await getTinderPoolDetails(pool);
            dispatch(slice.actions.detailsLoaded({ details }));
        } catch {
            dispatch(gotoNextToken());
        }
    };

const createSwipeResult = (
    direction: SwipeDirection,
    pool: TinderPoolDetails,
): TinderSwipeResult => {
    const priceChange = pool.price - pool.oldData.price;
    return {
        result:
            (direction === "right" && priceChange >= 0) ||
            (direction === "left" && priceChange <= 0),
        poolAddress: pool.address,
        direction,
    };
};

const checkSwipe =
    (direction: SwipeDirection): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { currentTokenDetails: details } = getState().tinder;

            if (details) {
                getSwipeResult({
                    direction,
                    poolAddress: details.address,
                    chainId: details.chainId,
                });

                dispatch(
                    slice.actions.swipeResultLoaded({
                        result: createSwipeResult(direction, details),
                    }),
                );
            } else {
                dispatch(slice.actions.swipeResultError({ error: "No token to swipe" }));
            }
        } catch (err) {}
    };

function detailsLoadingStarted(state: TinderState) {
    state.currentTokenDetailsLoading = true;
    state.currentTokenDetails = null;
}

function detailsLoaded(state: TinderState, effect: Effect<{ details: TinderPoolDetails }>) {
    const { details } = effect.payload;
    state.currentTokenDetails = details;
    state.currentTokenDetailsLoading = false;
}

function tokensLoadingStarted(state: TinderState) {
    state.tokensLoading = true;
    state.tokensLoadingError = undefined;
}

function tokensLoaded(state: TinderState, effect: Effect<{ tokens: TinderPoolShort[] }>) {
    state.tokensLoading = false;
    state.tokens = effect.payload.tokens;
}

function tokensLoadingError(state: TinderState, effect: Effect<{ error: string }>) {
    state.tokensLoading = false;
    state.tokensLoadingError = effect.payload.error;
}

function swipeResultApplied(state: TinderState) {
    state.stats.totalSwipes++;
    if (!state.swipeResult!!.result) {
        state.stats.energy--
    }
    const pointsPerCorrect = 3 + (state.stats.level - 1)
    const points = state.swipeResult!!.result ? pointsPerCorrect : 1
    state.stats.balance = state.stats.balance + points
    state.stats.swipeBalance = state.stats.swipeBalance + points
}

function swipeResultLoaded(state: TinderState, effect: Effect<{ result: TinderSwipeResult }>) {
    state.swipeResultLoading = false;
    state.swipeResult = effect.payload.result;

}

function swipeResultError(state: TinderState, effect: Effect<{ error: string }>) {
    state.swipeResultLoading = false;
    state.swipeResultError = effect.payload.error;
}

function statsLoadingStarted(state: TinderState) {
    state.statsLoading = true;
    state.statsLoadingError = undefined;
}

function statsLoaded(state: TinderState, effect: Effect<{ stats: TinderStats }>) {
    state.stats = effect.payload.stats;
    state.statsLoading = false;
}

function statsLoadingError(state: TinderState, effect: Effect<{ error: string }>) {
    state.statsLoading = false;
    state.statsLoadingError = effect.payload.error;
}

function advance(state: TinderState) {
    state.swipeResult = null;
    state.swipeResultError = undefined;
}

export const tinderReducer = slice.reducer,
    LOAD_STATS = loadStats,
    DATA_LOAD_REQUIRED = loadTokens,
    CHECK_SWIPE = checkSwipe,
    CLAIM = applySwipeResult,
    GOTO_NEXT_TOKEN = gotoNextToken;
