import {WithdrawState} from "./feature";
import {WithdrawUIState} from "./WithdrawUIState";
import validateState from "./validator";

export function featureStateToUIState(state: WithdrawState): WithdrawUIState {
    const validationErrors = validateState(state)
    const addressErrors = validationErrors.get('address') ?? []
    const noErrors = [...addressErrors].length === 0

    return {
        symbol: state.token.symbol,
        amount: state.token.amount,
        address: {
            value: state.address,
            error: addressErrors.at(0)
        },
        button: {
            text: "Confirm",
            enabled: noErrors && !state.withdrawInProgress && state.address.length > 0,
            loading: state.withdrawInProgress,
        },
        status: {
            error: state.withdrawError,
            hash: state.withdrawHash,
        }
    }
}