import SectionHeader from "../../../../ui/components/section/SectionHeader";
import SectionLabel from "../../../../ui/components/section/SectionLabel";
import {BaseCard} from "../../../../ui/components/containers/BaseCard";
import SectionPlaceholder from "../../../../ui/components/section/SectionPlaceholder";
import OrderTypeSelector from "./OrderTypeSelector";
import OrderDirectionSelector from "./OrderDirectionSelector";
import {TradeTypeUIState} from "../../TerminalUIState";

export default function TradeType(
    props: {state: TradeTypeUIState}
) {
    return (
        <SectionPlaceholder
            header={
                <SectionHeader
                    left={
                        <SectionLabel text='Trade type'/>
                    }
                />
            }
            content={
                <BaseCard>
                    <OrderTypeSelector
                        orderType={props.state.orderType}
                    />
                    { props.state.orderDirectionVisible &&
                        <OrderDirectionSelector
                            direction={props.state.orderDirection}
                        />
                    }
                </BaseCard>
            }
        />
    )
}