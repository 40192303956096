import {ReactNode} from "react";
import {Text, VStack} from "@chakra-ui/react";
import spacing from "../../theme/foundations/spacing";
import fontSizes from "../../theme/foundations/fontSizes";
import colors from "../../theme/foundations/colors";

export default function ModalContainer(props: {
    icon: ReactNode,
    title: string,
    subtitle?: string,
    action?: ReactNode,
}) {

    return (
        <VStack py={spacing.card_padding_v} gap={spacing.card_padding_v}>
            {props.icon}
            <Text
                as='b'
                fontSize={fontSizes.large}
                textAlign="center"
            >
                {props.title}
            </Text>
            {props.subtitle &&
                <Text
                    fontSize={fontSizes.medium}
                    textColor={colors.tg.textColorSecondary}
                    textAlign="center"
                >
                    {props.subtitle}
                </Text>}
            {props.action &&
                props.action}
        </VStack>
    )
}