import {PoolsState} from "./feature";
import {PoolsUIState, PoolUIState} from "./PoolsUIState";
import {Pool} from "../../../domain/pools/models";

export function featureStateToPoolsUIState(state: PoolsState): PoolsUIState {
    const pools = state.pools.map((pool) => poolToUIState(pool))
    return {
        query: state.query,
        items: pools,
        loading: state.poolsLoading,
        loadingError: state.poolsLoadingError,
    }
}

function poolToUIState(pool: Pool): PoolUIState {
    const precision = pool.baseToken.price > 1 ? 2 : 8
    return {
        address: pool.address,
        prettyAddress: getStrippedAddress(pool.address),
        baseLogoUrl: pool.baseToken.logoUrl,
        baseSymbol: pool.baseToken.symbol,
        quoteLogoUrl: pool.quoteToken.logoUrl,
        quoteSymbol: pool.quoteToken.symbol,
        dex: pool.dex.capitalize(),
        price: `$${pool.baseToken.price.toFixed(precision)}`
    }
}

function getStrippedAddress(address: string): string {
    const start = address.slice(0, 4)
    const end = address.slice(-6)
    return `${start}...${end}`
}