import { FarmingState } from "@presentation/farming/feature";
import { FarmingUIState } from "@presentation/farming/FarmingUIState";

export function featureStateToUIState(state: FarmingState): FarmingUIState {
    const userData = state.user.userData
    const photo = userData.photo
        ? `data:image/jpeg;base64,${userData.photo}`
        : undefined
    const initials =
        `
        ${userData.firstName?.charAt(0) ?? ""}
        ${userData.lastName?.charAt(0) ?? ""}
        `
    const miningButtonEnabled =
        state.gameStats.lastAction === null || state.gameStats.aiPoints > 1

    const miningPointsToShow = state.aiPointsLocal
    const miningPrimaryText = state.gameStats.lastAction === null
        ? "Start mining"
        : `Claim SP ${miningPointsToShow.toFixed(4)}`

    const timePassedMs = Date.now() - (state.gameStats.lastAction ?? 0)
    const timeLeftMs = (state.gameStats.maxHoursForAi*3600*1000) - timePassedMs
    const timeLeftDate = new Date(timeLeftMs)
    const miningActive = state.gameStats.lastAction !== null && timeLeftMs > 0

    const miningSecondaryText = miningActive
        ? timeLeftDate.toISOString().substring(11, 19)
        : ""

    const levelUpAvailable = state.gameStats.balance >= state.levelUpBoost.price
    const correctSwipePrev = 3 + (state.gameStats.level - 1)
    const correctSwipeNext = correctSwipePrev + 1
    const miningTimePrev = Math.min(state.gameStats.level, 24)
    const miningTimeNext = Math.min(state.gameStats.level + 1, 24)

    return {
        userInfo: {
            photo: photo,
            initials: initials,
            name: userData.firstName ?? userData.userName ?? "anon",
            loading: state.userLoading,
            level: state.gameStats.level,
        },
        level: {
            level: state.gameStats.level,
            upgradeCost: state.levelUpBoost.price,
            upgradeAvailable: levelUpAvailable,
            upgradeInProgress: state.levelUpInProgress,
            loading: state.levelUpBoostLoading,
            upgradeError: state.levelUpError,
            upgradeSuccess: state.levelUpSuccess,
            perks: [
                {
                    name: "Correct swipe",
                    prev: `${correctSwipePrev} SP`,
                    next: `${correctSwipeNext} SP`,
                },
                {
                    name: "Mining time",
                    prev: `${miningTimePrev}h`,
                    next: `${miningTimeNext}h`,
                },
            ]
        },
        points: {
            balance: state.gameStats.balance,
            loading: state.gameStatsLoading,
        },
        game: {
            level: state.gameStats.level,
            energy: state.gameStats.energy,
            energyLimit: 30,
            loading: state.gameStatsLoading,
        },
        mining: {
            enabled: miningButtonEnabled,
            active: miningActive,
            loading: false,
            textPrimary: miningPrimaryText,
            textSecondary: miningSecondaryText,
        }
    }
}