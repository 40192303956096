import { createBrowserRouter } from "react-router-dom";
import { Root } from "@presentation/root/Root";
import { AuthScreen } from "@presentation/auth/AuthScreen";
import { DashboardScreen } from "@presentation/dashboard/DashboardScreen";
import TerminalScreen from "../presentation/terminal/TerminalScreen";
import PoolsScreen from "../presentation/terminal/pools/PoolsScreen";
import { Settings } from "@presentation/settings/Settings";
import { Screen } from "./Screen";
import TokensScreen from "../presentation/tokens/TokensScreen";
import TradesScreen from "../presentation/trades/TradesScreen";
import DepositScreen from "../presentation/deposit/DepositScreen";
import TradeDetailsScreen from "../presentation/trades/trade/TradeDetailsScreen";
import InviteScreen from "../presentation/invites/InvitesScreen";
import WithdrawSelectScreen from "../presentation/withdraw/WithdrawSelectScreen";
import WithdrawFormScreen from "../presentation/withdraw/WithdrawFormScreen";
import { Main } from "@presentation/main/Main";
import { TinderScreen } from "@presentation/tinder/TinderScreen";
import { BoostScreen } from "@presentation/boost/BoostScreen";
import { FriendsScreen } from "@presentation/friends/FriendsScreen";
import { NoDesktopScreen } from "@presentation/nodesktop/NoDesktopScreen";
import { QuestScreen } from "@presentation/quest/QuestScreen";
import { FarmingScreen } from "@presentation/farming/FarmingScreen";

export const CONTENT_ROOT_SCREENS = [
    Screen.ROOT.valueOf(),
    Screen.NO_DESKTOP.valueOf(),
    Screen.DASHBOARD.valueOf(),
    Screen.FARMING.valueOf(),
    Screen.BOOST.valueOf(),
    Screen.QUEST.valueOf(),
    Screen.FRIENDS.valueOf(),
    Screen.AUTH.valueOf(),
];

export const UNAUTHORIZED_SCREENS = [Screen.AUTH.valueOf()];

export const NO_SETTINGS_SCREENS = [
    Screen.SETTINGS.valueOf(),
    Screen.INVITES.valueOf(),
    Screen.TERMINAL.valueOf(),
];

export function isContentRootScreen(route: string): boolean {
    return CONTENT_ROOT_SCREENS.includes(route);
}

function isUnauthorizedScreen(route: string): boolean {
    return UNAUTHORIZED_SCREENS.includes(route);
}

function isNoSettingsScreen(route: string): boolean {
    return NO_SETTINGS_SCREENS.includes(route);
}

export function settingsAvailable(route: string): boolean {
    return !isUnauthorizedScreen(route) && !isNoSettingsScreen(route);
}

const router = createBrowserRouter([
    {
        path: Screen.ROOT,
        element: <Root />,
        children: [
            {
                path: Screen.AUTH,
                element: <AuthScreen />,
            },
            {
                path: Screen.NO_DESKTOP,
                element: <NoDesktopScreen />,
            },
            {
                path: Screen.MAIN,
                element: <Main />,
                children: [
                    {
                        path: Screen.DASHBOARD,
                        element: <DashboardScreen />,
                    },
                    {
                        path: Screen.FARMING,
                        element: <FarmingScreen />,
                    },
                    {
                        path: Screen.BOOST,
                        element: <BoostScreen />,
                    },
                    {
                        path: Screen.QUEST,
                        element: <QuestScreen />,
                    },
                    {
                        path: Screen.FRIENDS,
                        element: <FriendsScreen />,
                    },
                ],
            },
            {
                path: Screen.TINDER,
                element: <TinderScreen />,
            },
            {
                path: Screen.TERMINAL,
                element: <TerminalScreen />,
            },
            {
                path: Screen.POOLS,
                element: <PoolsScreen />,
            },
            {
                path: Screen.SETTINGS,
                element: <Settings />,
            },
            {
                path: Screen.TOKENS,
                element: <TokensScreen />,
            },
            {
                path: Screen.TRADES,
                element: <TradesScreen />,
            },
            {
                path: Screen.TRADE_DETAILS,
                element: <TradeDetailsScreen />,
            },
            {
                path: Screen.DEPOSIT,
                element: <DepositScreen />,
            },
            {
                path: Screen.INVITES,
                element: <InviteScreen />,
            },
            {
                path: Screen.WITHDRAW_LIST,
                element: <WithdrawSelectScreen />,
            },
            {
                path: Screen.WITHDRAW_FORM,
                element: <WithdrawFormScreen />,
            },
        ],
    },
]);

export default router;
