import {Page} from "../../ui/components/containers/Page";
import {useCallback, useEffect} from "react";
import TradingPair from "./components/pair/TradingPair";
import TradeType from "./components/trade_type/TradeType";
import ConditionPrice from "./components/inputs/ConditionPrice";
import TokenAmount from "./components/inputs/TokenAmount";
import TotalPrice from "./components/inputs/TotalPrice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {featureStateToUIState} from "./stateMapper";
import {MainButtonUIState, StatusUIState, TerminalUIState} from "./TerminalUIState";
import colors from "../../ui/theme/foundations/colors";
import { setMainButtonLoading, WebApp } from "../../ui/telegram/utils";
import {useNavigate} from "react-router-dom";
import {TRADE_CONFIRMED, CLEAN_UP, PRICE_UPDATE_REQUESTED} from "./feature";
import TakeProfit from "./components/smart_trade/TakeProfit";
import StopLoss from "./components/smart_trade/StopLoss";
import {useSmartAccount} from "../../domain/auth/alchemy/AlchemyProvider";
import SkeletonSectionItems from "../../ui/components/feedback/SkeletonSectionItems";


export default function TerminalScreen() {
    const state = useAppSelector(state => featureStateToUIState(state.terminal))
    const dispatch = useAppDispatch()
    useEffect(() => {
        return () => { dispatch(CLEAN_UP()) }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(PRICE_UPDATE_REQUESTED())
        }, 5000)

        return () => clearInterval(interval)
    }, [])

    useMainButton(state.button)
    useStatusPopUp(state.status)

    return render(state)
}

function render(state: TerminalUIState) {
    return state.status.shouldShowSkeleton
        ? skeleton()
        : form(state)
}

function form(state: TerminalUIState) {
    return (
        <Page>
            <TradingPair
                state={state.pair}
            />
            <TradeType
                state={state.tradeType}
            />
            {state.condition.visible &&
                <ConditionPrice
                    state={state.condition}
                />
            }
            <TokenAmount
                state={state.amount}
            />
            <TotalPrice
                state={state.total}
            />
            {state.smartTrade.visible &&
                <>
                    <TakeProfit
                        state={state.smartTrade.takeProfit}
                    />
                    <StopLoss
                        state={state.smartTrade.stopLoss}
                    />
                </>
            }
        </Page>
    )
}

function skeleton() {
    return (
        <Page>
            <SkeletonSectionItems count={5} />
        </Page>
    )
}

function useStatusPopUp(state: StatusUIState) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (state.error) {
            WebApp.showAlert(state.error)
        }
    }, [state.error])
    useEffect(() => {
        if (state.tradeCreated) {
            WebApp.showAlert(
                "Trade successfully initiated!",
                () => {
                    dispatch(CLEAN_UP())
                    navigate(-1)
                }
            )
        }
    }, [state.tradeCreated])
}

function useMainButton(state: MainButtonUIState) {
    const dispatch = useAppDispatch()
    const mainButton = WebApp.MainButton
    const { privyUser, sendUserOperations, createSessionKey, installSessionKeyPlugin } = useSmartAccount()

    setMainButtonLoading(state.loading)
    mainButton.setParams({
        is_active: state.enabled,
        color: state.enabled ? colors.tg.accentColor : colors.tg.gray,
        text: state.text,
    })

    const callback = useCallback(() => {
        WebApp.showConfirm(
            "Create trade?",
            (confirmed) => {
                if (confirmed) {
                    dispatch(TRADE_CONFIRMED(installSessionKeyPlugin, createSessionKey, sendUserOperations))
                }
            }
        )
    }, [sendUserOperations, createSessionKey, installSessionKeyPlugin])

    useEffect(() => {
        if (privyUser) {
            mainButton.show()
            mainButton.onClick(callback)
            return () => {
                mainButton.hide()
                mainButton.offClick(callback)
            }
        }
    }, [ privyUser, sendUserOperations, createSessionKey ])
}