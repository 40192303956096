import { Page } from "@ui/components/containers/Page";
import { Text, Center, Flex, Button, useToast } from "@chakra-ui/react";
import { BaseCard } from "@ui/components/containers/BaseCard";

export function BoostScreen() {
    const toast = useToast();

    return (
        <Page>
            {/*header*/}
            <Center py="24px">
                <Text fontWeight="extrabold" fontSize="36">
                    Boost
                </Text>
            </Center>

            {/*Boosters*/}
            <Flex direction="column" gap="12px">
                <Text fontWeight="extrabold" fontSize="24">
                    Boosters
                </Text>
                <BaseCard>
                    <Flex direction="column" gap="12px">
                        <Text fontSize="14">
                            We're developing some game-changing features that will allow you to earn
                            as many points as possible!
                        </Text>
                        <Button
                            w="full"
                            variant="game_solid_accent"
                            onClick={() => {
                                toast({
                                    title: "We sure will sir!",
                                    status: "info",
                                    duration: 600,
                                    isClosable: false,
                                });
                            }}
                        >
                            Notify wen ready
                        </Button>
                    </Flex>
                </BaseCard>
            </Flex>
        </Page>
    );
}
