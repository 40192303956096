import {Flex} from "@chakra-ui/react";
import Divider from "../../../dashboard/components/details/Divider";
import spacing from "../../../../ui/theme/foundations/spacing";
import {PoolUIState} from "../PoolsUIState";
import PoolListItem from "./PoolListItem";

export default function PoolListItems(props: {
    items: PoolUIState[],
    onItemClick: (address: string) => void
}) {
    return props.items.map((item, index) => {
        const listItem = <PoolListItem
            item={item}
            onClick={(address) => props.onItemClick(address)}
        />
        return (
            <Flex
                direction='column'
                w='full'
            >
                {listItem}
                {index < props.items.length - 1 &&
                    <Divider paddingStart={spacing.divider_token_padding_start}/>}
            </Flex>
        )
    })
}