import { getAccessToken } from "@privy-io/react-auth";

const LOCAL_STORAGE_TOKEN = "stier-auth-token";

export const BASE_URL = process.env.REACT_APP_DECOMMAS_BASE_URL;

const getEndpoint = (pathWithoutLeadingSlash: string) => `${BASE_URL}/${pathWithoutLeadingSlash}`;

export enum Method {
    GET = "get",
    POST = "post",
    DELETE = "delete",
}

type RequestHeaders = Record<string, string>;

export type IResponseData<T = null> = {
    data: T;
};

type TRequestOptions = {
    url: string;
    params?: unknown;
    method?: Method;
    headers?: RequestHeaders;
    credentials?: boolean;
};

const baseHttpRequest = async <T>({
    url,
    params = {},
    method = Method.GET,
    headers = {},
    credentials = true,
}: TRequestOptions): Promise<T> => {
    const fetchUrl = `${url}${
        method === Method.GET
            ? `?${new URLSearchParams(params as Record<string, string>).toString()}`
            : ""
    }`;

    const basicHeaders: RequestHeaders = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST",
    };

    const resultHeaders: RequestHeaders = { ...basicHeaders, ...headers };

    const response = await fetch(fetchUrl, {
        headers: resultHeaders,
        method,
        credentials: credentials ? "include" : "omit",
        ...(method !== Method.GET
            ? {
                  body: JSON.stringify(params),
              }
            : {}),
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    }

    throw new Error(data.error?.message || data.error);
};

export const httpRequest = async <T>(request: TRequestOptions): Promise<T> => {
    return baseHttpRequest<T>(request);
};

export async function fetchAsArray<T>(endpoint: string, params = {}, headers = {}): Promise<T[]> {
    const response: IResponseData<T[]> = await baseHttpRequest({
        url: endpoint,
        params,
        headers,
    });

    return response?.data ?? [];
}

export async function fetchAsObject<T>(endpoint: string, params = {}, headers = {}): Promise<T> {
    const response: IResponseData<T> = await baseHttpRequest({
        url: endpoint,
        params,
        headers,
    });

    return response?.data;
}

export async function getPrivyHeader() {
    const token = await getAccessToken();
    return {
        Authorization: `Bearer ${token}`,
    };
}

export function saveAuthToken(token: string) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
}

export function getAuthHeader() {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN) || "";
    return {
        Authorization: `Bearer ${token}`,
    };
}

function fetchBackendApiAs<T, R>(
    pathWithoutLeadingSlash: string,
    isArray: boolean,
    params = {},
    headers = {},
): Promise<R> {
    const authHeader = getAuthHeader();

    const func = isArray ? fetchAsArray<T> : fetchAsObject<T>;

    return func(getEndpoint(pathWithoutLeadingSlash), params, {
        ...authHeader,
        ...headers,
    }) as Promise<R>;
}

export function fetchBackendApiAsArray<T>(
    pathWithoutLeadingSlash: string,
    params = {},
    headers = {},
) {
    return fetchBackendApiAs<T, T[]>(pathWithoutLeadingSlash, true, params, headers);
}

export function fetchBackendApiAsObject<T>(
    pathWithoutLeadingSlash: string,
    params = {},
    headers = {},
) {
    return fetchBackendApiAs<T, T>(pathWithoutLeadingSlash, false, params, headers);
}

export async function postBackendApi<T>(
    pathWithoutLeadingSlash: string,
    params = {},
    headers = {},
) {
    const authHeader = getAuthHeader();

    const response: IResponseData<T> = await httpRequest({
        url: getEndpoint(pathWithoutLeadingSlash),
        params,
        method: Method.POST,
        headers: { ...authHeader, ...headers },
    });

    return response.data;
}
