import { AppThunk } from "@app/store";
import { createSlice, PayloadAction as Effect } from "@reduxjs/toolkit";
import { RefData, Referral } from "@domain/tg_user/models";
import { getTgUser, getReferrals } from "@domain/tg_user/repo";

export interface FriendsState {
    referralsCount: number;
    refCode: string;
    refBalance: number;
    referrals: Referral[];

    refDataLoading: boolean;
    refDataLoadingError?: string;
    referralsLoading: boolean;
    referralsLoadingError?: string;
}

const initialState: FriendsState = {
    referralsCount: 0,
    refCode: "",
    refBalance: 0,
    referrals: [],
    refDataLoading: false,
    refDataLoadingError: undefined,
    referralsLoading: false,
    referralsLoadingError: undefined,
};

//slice

const slice = createSlice({
    name: "referral",
    initialState,
    reducers: {
        refDataLoadingStarted,
        refDataLoaded,
        refDataLoadingError,
        referralsLoadingStarted,
        referralsLoaded,
        referralsLoadingError,
    },
});

//actions

const loadData = (): AppThunk => async (dispatch) => {
    dispatch(loadUserRefData());
    dispatch(loadReferrals());
};

const loadUserRefData = (): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.refDataLoadingStarted());

    try {
        const data = await getTgUser();
        dispatch(
            slice.actions.refDataLoaded({
                refData: data.refData,
                refBalance: getState().farming.gameStats.refBalance
            }),
        );
    } catch (e) {
        dispatch(
            slice.actions.refDataLoadingError({
                error: (e as object).toString(),
            }),
        );
    }
};

const loadReferrals = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.referralsLoadingStarted());

    try {
        const referrals = await getReferrals();
        dispatch(
            slice.actions.referralsLoaded({
                referrals: referrals,
            }),
        );
    } catch (e) {
        dispatch(
            slice.actions.referralsLoadingError({
                error: (e as object).toString(),
            }),
        );
    }
};

//effects

function refDataLoadingStarted(state: FriendsState) {
    state.refDataLoading = true;
    state.refDataLoadingError = undefined;
}

function refDataLoaded(state: FriendsState, effect: Effect<{ refData: RefData, refBalance: number }>) {
    state.refDataLoading = false;
    state.refCode = effect.payload.refData.code;
    state.referralsCount = effect.payload.refData.referralsCount;
    state.refBalance = effect.payload.refBalance;
}

function refDataLoadingError(state: FriendsState, effect: Effect<{ error: string }>) {
    state.refDataLoading = false;
    state.refDataLoadingError = effect.payload.error;
}

function referralsLoadingStarted(state: FriendsState) {
    state.referralsLoading = true;
    state.referralsLoadingError = undefined;
}

function referralsLoaded(state: FriendsState, effect: Effect<{ referrals: Referral[] }>) {
    state.referralsLoading = false;
    state.referrals = effect.payload.referrals;
}

function referralsLoadingError(state: FriendsState, effect: Effect<{ error: string }>) {
    state.referralsLoading = false;
    state.referralsLoadingError = effect.payload.error;
}

export const referralReducer = slice.reducer,
    LOAD_DATA = loadData;
