import {Pool} from "../../../domain/pools/models";
import {AppThunk} from "../../../app/store";
import {createSlice, PayloadAction as Effect} from "@reduxjs/toolkit";
import {getPoolsForCurrentChain} from "../../../domain/pools/usecases";

export interface PoolsState {
    pools: Pool[],
    poolsLoading: boolean,
    poolsLoadingError: string | null,
    query: string,
}

const initialState: PoolsState = {
    pools: [],
    poolsLoading: false,
    poolsLoadingError: null,
    query: '',
}

const loadPools = (): AppThunk => async (dispatch, getState) => {
    const query = getState().pools.query
    dispatch(slice.actions.poolsLoadingStarted())
    try {
        const result = await getPoolsForCurrentChain(query)
        dispatch(slice.actions.poolsLoaded({
            pools: result
        }))
    } catch (e) {
        dispatch(slice.actions.poolsLoadingError({
            error: (e as object).toString()
        }))
    }
}

const changeQuery = (query: string): AppThunk => async (dispatch) => {
    dispatch(slice.actions.queryChanged({
        query: query
    }))
    dispatch(loadPools())
}

const slice = createSlice({
    name: 'pools',
    initialState,
    reducers: {
        poolsLoadingStarted,
        poolsLoaded,
        poolsLoadingError,
        queryChanged,
    }
})

function poolsLoadingStarted(state: PoolsState) {
    state.poolsLoading = true
    state.poolsLoadingError = null
}

function poolsLoaded(state: PoolsState, effect: Effect<{pools: Pool[]}>) {
    state.poolsLoading = false
    state.pools = effect.payload.pools
}

function poolsLoadingError(state: PoolsState, effect: Effect<{error: string}>) {
    state.poolsLoading = false
    state.poolsLoadingError = effect.payload.error
}

function queryChanged(state: PoolsState, effect: Effect<{query: string}>) {
    state.query = effect.payload.query
}

export const
    poolsReducer = slice.reducer,
    POOLS_LOAD_REQUIRED = loadPools,
    QUERY_CHANGED = changeQuery
