import {PoolNet} from "./models";
import {BASE_URL, fetchAsArray, fetchAsObject} from "../../utils";

export async function getPoolsNet(
    chainId: number,
    query: string,
): Promise<PoolNet[]> {
    try {
        return fetchAsArray(
            `${BASE_URL}/pool/list`,
            {
                chainId: chainId,
                value: query
            }
        )
    } catch (e) {
        throw Error('Error loading pools')
    }
}

export async function getPoolNet(
    chainId: number,
    address: string,
): Promise<PoolNet> {
    try {
        return fetchAsObject(
            `${BASE_URL}/pool`,
            {
                chainId: chainId,
                address: address
            }
        )
    } catch (e) {
        throw Error('Error loading pool\'s data')
    }
}

export async function getBaseTokenPriceNet(
    chainId: number,
    address: string,
): Promise<number> {
    try {
        return fetchAsObject(
            `${BASE_URL}/pool`,
            {
                chainId: chainId,
                address: address
            }
        )
    } catch (e) {
        throw Error('Error loading price')
    }
}