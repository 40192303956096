import { Box, HStack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import colors from "@ui/theme/foundations/colors";
import { QuestCategoryUIState } from "@presentation/quest/QuestsUIState";
import { useAppDispatch } from "@app/hooks";
import { SELECT_CATEGORY } from "@presentation/quest/feature";

export default function QuestCategories(props: {
    categories: QuestCategoryUIState[]
}) {
    const dispatch = useAppDispatch()

    return (
        <Wrap
            spacing={"8px"}
            justify={"center"}
        >
            {props.categories.map((category) => (
                <WrapItem>
                    <HStack
                        px={"14px"}
                        py={"4px"}
                        bgColor={category.selected ? colors.tg.stierBrand : colors.tg.sectionBgColor}
                        borderRadius={"16px"}
                        spacing={"6px"}
                        onClick={() => dispatch(SELECT_CATEGORY(category.id))}
                    >
                        {category.hasUnfinished &&
                            <Box
                                h={"8px"}
                                w={"8px"}
                                borderRadius={"full"}
                                bgColor={colors.tg.red}
                            />
                        }
                        <Text>{category.name}</Text>
                    </HStack>
                </WrapItem>
            ))}
        </Wrap>
    )
}