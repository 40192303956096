import {Trade} from "../../../domain/trades/models";
import {AppThunk} from "../../../app/store";
import {createSlice, PayloadAction as Effect} from "@reduxjs/toolkit";
import {cancelTradeNet, closeTradeNet} from "../../../storage/net/decommas/trades/tradesApi";
import {EMPTY_TRADE} from "../../../utils/consts";

export interface TradeDetailsState {
    trade: Trade,
    tradeCancelInProgress: boolean,
    tradeCancelError: string | null,
    tradeCanceled: boolean,
    tradeCloseInProgress: boolean,
    tradeCloseError: string | null,
    tradeClosed: boolean,
}

const initialState: TradeDetailsState = {
    trade: EMPTY_TRADE,
    tradeCancelInProgress: false,
    tradeCancelError: null,
    tradeCanceled: false,
    tradeCloseInProgress: false,
    tradeCloseError: null,
    tradeClosed: false,
}

const selectTrade = (id: string): AppThunk => async (dispatch, getState) => {
    //костылек
    const trade =
        getState().trades.trades.find((value) => value.id  === id)
        ?? getState().dashboard.trades.find((value) => value.id  === id)
    dispatch(slice.actions.tradeSelected({
        trade: trade!!
    }))
}

const cancelTrade = (): AppThunk => async (dispatch, getState) => {
    const trade = getState().tradeDetails.trade
    dispatch(slice.actions.tradeCancelInProgress())
    try {
        if (trade) await cancelTradeNet(trade.id)
        dispatch(slice.actions.tradeCanceled())
    } catch (e) {
        dispatch(slice.actions.tradeCancelError({
            error: (e as object).toString()
        }))
    }
}

const closeTrade = (): AppThunk => async (dispatch, getState) => {
    const trade = getState().tradeDetails.trade
    dispatch(slice.actions.tradeCloseInProgress())
    try {
        if (trade) await closeTradeNet(trade.id)
        dispatch(slice.actions.tradeClosed())
    } catch (e) {
        dispatch(slice.actions.tradeCloseError({
            error: (e as object).toString()
        }))
    }
}

const cleanUp = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.reset())
}

const slice = createSlice({
    name: 'trade',
    initialState,
    reducers: {
        tradeSelected,
        tradeCancelInProgress,
        tradeCancelError,
        tradeCanceled,
        tradeCloseInProgress,
        tradeCloseError,
        tradeClosed,
        reset,
    }
})

function tradeSelected(state: TradeDetailsState, effect: Effect<{trade: Trade}>) {
    state.trade = effect.payload.trade
}

function tradeCancelInProgress(state: TradeDetailsState) {
    state.tradeCancelInProgress = true
    state.tradeCancelError = null
}

function tradeCancelError(state: TradeDetailsState, effect: Effect<{error: string}>) {
    state.tradeCancelInProgress = false
    state.tradeCancelError = effect.payload.error
}

function tradeCanceled(state: TradeDetailsState) {
    state.tradeCancelInProgress = false
    state.tradeCanceled = true
}

function tradeCloseInProgress(state: TradeDetailsState) {
    state.tradeCloseInProgress = true
    state.tradeCloseError = null
}

function tradeCloseError(state: TradeDetailsState, effect: Effect<{error: string}>) {
    state.tradeCloseInProgress = false
    state.tradeCloseError = effect.payload.error
}

function tradeClosed(state: TradeDetailsState) {
    state.tradeCloseInProgress = false
    state.tradeClosed = true
}

function reset(state: TradeDetailsState) {
    Object.assign(state, initialState)
}

export const
    tradeDetailsReducer = slice.reducer,
    TRADE_SELECTED = selectTrade,
    CANCEL_TRADE_CONFIRMED = cancelTrade,
    CLOSE_TRADE_CONFIRMED = closeTrade,
    CLEAN_UP = cleanUp

