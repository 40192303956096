import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import spacing from "../../../../ui/theme/foundations/spacing";
import {BaseCard} from "../../../../ui/components/containers/BaseCard";
import TokensShortList from "./TokensShortList";
import TradesShortList from "./TradesShortList";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {featureStateToUIState} from "../../stateMapper";
import {TAB_CHANGED} from "../../feature";

export default function DetailsCard() {
    const state = useAppSelector(state => featureStateToUIState(state.dashboard))
    const dispatch = useAppDispatch()

    const handleTabChange = (index: number) => {
        dispatch(TAB_CHANGED(index))
    }

    return (
        <BaseCard>

            <Tabs
                variant='segmented'
                index={state.tab}
                onChange={(i) => handleTabChange(i)}
            >

                <TabList>
                    <Tab>Holdings</Tab>
                    <Tab>Trades</Tab>
                </TabList>

                <TabPanels pt={spacing.content_spacing_v}>
                    <TabPanel p='0xp'>
                        <TokensShortList />
                    </TabPanel>
                    <TabPanel p='0xp'>
                        <TradesShortList />
                    </TabPanel>
                </TabPanels>

            </Tabs>

        </BaseCard>
    )
}