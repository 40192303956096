import SectionPlaceholder from "../../../../ui/components/section/SectionPlaceholder";
import SectionHeader from "../../../../ui/components/section/SectionHeader";
import SectionLabel from "../../../../ui/components/section/SectionLabel";
import {BaseCard} from "../../../../ui/components/containers/BaseCard";
import TerminalInput from "../../../../ui/components/inputs/TerminalInput";
import {TotalUIState} from "../../TerminalUIState";
import {useAppDispatch} from "../../../../app/hooks";
import {Text} from "@chakra-ui/react";
import fontSizes from "../../../../ui/theme/foundations/fontSizes";
import colors from "../../../../ui/theme/foundations/colors";
import {TOTAL_CHANGED} from "../../feature";

export default function TotalPrice(
    props: {state: TotalUIState}
) {
    const dispatch = useAppDispatch()
    const state = props.state
    const handleChange = (value: string) => {
        dispatch(TOTAL_CHANGED(value))
    }

    return (
        <SectionPlaceholder
            header={
                <SectionHeader
                    left={
                        <SectionLabel text={state.title}/>
                    }
                    right={
                        <SectionLabel text={`${state.balance} ${state.quoteSymbol}`}/>
                    }
                />
            }
            content={
                <BaseCard variant='section'>
                    <TerminalInput
                        value={state.value}
                        logoUrl={state.quoteLogoUrl}
                        decimals={state.minLotStep.countDecimals()}
                        symbol={state.quoteSymbol}
                        onChange={handleChange}
                    />
                </BaseCard>
            }
            footer={
                state.error &&
                <Text
                    fontSize={fontSizes.header_section}
                    color={colors.tg.red}
                >
                    {state.error}
                </Text>
            }
        />
    )
}