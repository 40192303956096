import {Button, HStack, StackProps} from "@chakra-ui/react";
import {DepositIcon, SendIcon, TradeIcon} from "../../../../ui/icons/Icon";
import {useNavigate} from "react-router-dom";
import {Screen} from "../../../../router/Screen";
import spacing from "../../../../ui/theme/foundations/spacing";
import sizes from "../../../../ui/theme/foundations/sizes";

export default function DashboardActionButtons(props: StackProps) {
    const navigate = useNavigate()

    return (
        <HStack
            w='full'
            gap={spacing.content_spacing_h_medium}
            {...props}
        >
            <Button
                variant='wallet_action'
                leftIcon={ <SendIcon size={sizes.action_button_icon} /> }
                onClick={ () => navigate(Screen.WITHDRAW_LIST) }
            >
                Withdraw
            </Button>
            <Button
                variant='wallet_action'
                leftIcon={ <DepositIcon size={sizes.action_button_icon} /> }
                onClick={ () => navigate(Screen.DEPOSIT) }
            >
                Deposit
            </Button>
            <Button
                variant='wallet_action'
                leftIcon={ <TradeIcon size={sizes.action_button_icon} /> }
                onClick={ () => navigate(Screen.POOLS) }
            >
                Trade
            </Button>
        </HStack>
    )
}