import ClickableBox from "../../../../ui/components/containers/ClickableBox";
import {Box, Center, Flex, HStack, Image, Text} from "@chakra-ui/react";
import spacing from "../../../../ui/theme/foundations/spacing";
import colors from "../../../../ui/theme/foundations/colors";
import size from "../../../../ui/theme/foundations/sizes";
import fontSizes from "../../../../ui/theme/foundations/fontSizes";
import {PoolUIState} from "../PoolsUIState";

export default function PoolListItem(props: {
    item: PoolUIState,
    onClick: (address: string) => void
}) {
    const { item, onClick } = props

    return (
        <ClickableBox onClick={() => onClick(item.address) }>
            <HStack
                w='full'
                paddingX={spacing.list_item_padding_x}
                paddingY={spacing.list_item_padding_y}
                justifyContent='space-between'
            >
                <Flex
                    direction='row'
                >
                    <Center
                        zIndex='2'
                        h='23px'
                        w='23px'
                        borderRadius='full'
                        border='0.5px'
                        borderColor={colors.tg.bgColor}
                        bg='white'
                        marginBottom='11px'
                    >
                        <Image
                            src={props.item.baseLogoUrl}
                            h={size.trade_list_item_logo}
                            w={size.trade_list_item_logo}
                            border='0.5px'
                            borderColor={colors.tg.bgColor}
                            borderRadius='full'
                        />
                    </Center>
                    <Center
                        zIndex='1'
                        marginStart='-11px'
                        h='23px'
                        w='23px'
                        borderRadius='full'
                        border='0.5px'
                        borderColor={colors.tg.bgColor}
                        bg='white'
                        marginTop='11px'
                    >
                        <Image
                            src={props.item.quoteLogoUrl}
                            h={size.trade_list_item_logo}
                            w={size.trade_list_item_logo}
                            border='0.5px'
                            borderColor={colors.tg.bgColor}
                            borderRadius='full'
                        />
                    </Center>
                </Flex>

                <Flex
                    w='full'
                    direction='column'
                    gap={spacing.list_item_text_spacing_y}
                >
                    <HStack
                        w='full'
                        justifyContent='space-between'
                    >
                        <Flex>
                            <Text
                                fontSize={fontSizes.medium}
                                fontWeight='semibold'
                            >
                                {item.baseSymbol}
                            </Text>
                            <Text
                                fontSize={fontSizes.medium}
                                fontWeight='semibold'
                                color={colors.tg.textColorSecondary}
                            >
                                /{item.quoteSymbol}
                            </Text>
                        </Flex>
                        <Text
                            fontSize={fontSizes.medium}
                            fontWeight='semibold'
                        >
                            {item.price}
                        </Text>
                    </HStack>

                    <HStack
                        w='full'
                    >
                        <Box
                            px='4px'
                            py='1px'
                            borderRadius='6px'
                            backgroundColor={`${colors.tg.hintColor}26`}
                        >
                            <Text
                                color={colors.tg.textColorSecondary}
                                fontSize={fontSizes.small}
                            >
                                {item.dex}
                            </Text>
                        </Box>

                        <Box
                            px='4px'
                            py='1px'
                            borderRadius='6px'
                            backgroundColor={`${colors.tg.hintColor}26`}
                        >
                            <Text
                                color={colors.tg.textColorSecondary}
                                fontSize={fontSizes.small}
                            >
                                {item.prettyAddress}
                            </Text>
                        </Box>

                    </HStack>

                </Flex>

            </HStack>
        </ClickableBox>
    )
}