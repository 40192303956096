import { Page } from "@ui/components/containers/Page";
import { Text, useToast, Center, VStack, Box, Divider, Wrap, WrapItem, HStack } from "@chakra-ui/react";
import { BaseCard } from "@ui/components/containers/BaseCard";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { Quest } from "@domain/quest/models";
import { useCallback, useEffect } from "react";
import { LOAD_QUESTS, START_QUEST, CHECK_QUEST } from "./feature";
import colors from "@ui/theme/foundations/colors";
import QuestsEmptyState from "@presentation/quest/components/QuestsEmptyState";
import QuestListItem from "@presentation/quest/components/QuestListItem";
import { featureStateToUIState } from "@presentation/quest/stateMapper";
import QuestCategories from "@presentation/quest/components/QuestCategories";

export const QuestScreen = () => {
    const toast = useToast();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(LOAD_QUESTS());
    }, []);

    const state = useAppSelector((state) => featureStateToUIState(state.quest));

    const onStartQuestClick = useCallback((quest: Quest) => {
        dispatch(START_QUEST(quest.slug));
        if (quest.link) window.open(quest.link);
    }, []);

    const onClaimQuestClick = useCallback((quest: Quest) => {
        dispatch(CHECK_QUEST(quest.slug));
    }, []);

    useEffect(() => {
        if (state.questCheckingError) {
            toast({
                title: "Quest not completed yet",
                status: "error",
                position: "top",
                isClosable: true,
                duration: 2000,
            });
        }
    }, [state.questCheckingError]);

    const spacing = "12px";

    return (
        <Page>
            {/*header*/}
            <Center py="12px">
                <Text fontWeight="extrabold" fontSize="36">
                    Tasks
                </Text>
            </Center>

            {/*categories*/}
            <QuestCategories
                categories={state.categories}
            />

            {/*list*/}
            <BaseCard p={spacing}>
                {state.questsDisplayed.length ? (
                    <VStack gap={0}>
                        {state.questsDisplayed.map((quest, index) => (
                            <Box key={quest.slug} w="100%">
                                <QuestListItem
                                    quest={quest}
                                    started={state.startedQuests[quest.slug]}
                                    onStartClick={() => onStartQuestClick(quest)}
                                    onClaimClick={() => onClaimQuestClick(quest)}
                                />
                                {index < state.questsDisplayed.length - 1 && (
                                    <Divider
                                        w="auto"
                                        borderColor={colors.tg.textColorSecondary}
                                        my={spacing}
                                        marginLeft={`calc(3*${spacing})`}
                                        marginRight={`-${spacing}`}
                                        paddingRight={spacing}
                                    />
                                )}
                            </Box>
                        ))}
                    </VStack>
                ) : (
                    <QuestsEmptyState />
                )}
            </BaseCard>
        </Page>
    );
};