import { Center, Text } from "@chakra-ui/react";
import colors from "@ui/theme/foundations/colors";
import { MiningUIState } from "@presentation/farming/FarmingUIState";
import { useAppDispatch } from "@app/hooks";
import { MINING_ACTION, MINING_UPDATE } from "@presentation/farming/feature";
import { useEffect } from "react";

export default function MiningButton(props: {
    state: MiningUIState
}) {
    const dispatch = useAppDispatch()
    const { state } = props

    const buttonColor = state.enabled
        ? colors.tg.stierBrand
        : colors.tg.disabledControlColor

    useEffect(() => {
        dispatch(MINING_UPDATE())
    }, []);

    useEffect(() => {
        if (state.active) {
            const interval = setInterval(() => {
                dispatch(MINING_UPDATE())
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [state.active])

    return (
        <Center
            position={"relative"}
            bgColor={buttonColor}
            borderRadius={"12px"}
            py={"20px"}
            onClick={() => {
                if (state.enabled) dispatch(MINING_ACTION())
            }}
        >
            <Text
                fontFamily={"monospace"}
                fontWeight={"bold"}
                textColor={"#FFFFFF"}
            >
                {state.textPrimary}
            </Text>

            <Text
                fontFamily={"monospace"}
                position={"absolute"}
                textColor={"#FFFFFF"}
                fontSize={"12px"}
                right={0}
                px={"10px"}
            >
                {state.textSecondary}
            </Text>
        </Center>
    )
}