import {UserToken} from "../../domain/tokens/models";
import {createSlice, PayloadAction as Effect} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {getTokens} from "../../domain/tokens/repo";
import {filterSpam, sortTokens} from "../../domain/tokens/usecases";
import {getCurrentWallet} from "../../domain/wallet/repo";

export interface TokensState {
    tokens: UserToken[],
    tokensLoading: boolean,
    tokensLoadingError: string | null,
}

const initialState: TokensState = {
    tokens: [],
    tokensLoading: true,
    tokensLoadingError: null
}

const loadTokens = (): AppThunk => async (dispatch) => {
    const wallet = getCurrentWallet()
    dispatch(slice.actions.tokensLoadingStarted())
    try {
        const tokens = await getTokens(wallet.chainId, wallet.address)
        dispatch(slice.actions.tokensLoaded({
            tokens: tokens
        }))
    } catch (e) {
        dispatch(slice.actions.tokensLoadingError({
            error: (e as object).toString()
        }))
    }
}

const slice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        tokensLoadingStarted,
        tokensLoaded,
        tokensLoadingError,
    }
})

function tokensLoadingStarted(state: TokensState) {
    state.tokensLoading = true
    state.tokensLoadingError = null
}

function tokensLoaded(state: TokensState, effect: Effect<{tokens: UserToken[]}>) {
    state.tokensLoading = false
    //const filtered = filterSpam(effect.payload.tokens)
    state.tokens = sortTokens(effect.payload.tokens)
}

function tokensLoadingError(state: TokensState, effect: Effect<{error: string}>) {
    state.tokensLoading = false
    state.tokensLoadingError = effect.payload.error
}

export const
    tokensReducer = slice.reducer,
    TOKENS_LOAD_REQUIRED = loadTokens
