import {getPoolNet, getPoolsNet} from "../../storage/net/decommas/pools/poolsApi";
import {poolNetToDomain} from "./mappers";
import {Pool} from "./models";

export function getPools(
    chainId: number,
    value: string
): Promise<Pool[]> {
    return getPoolsNet(chainId, value)
        .then((pools) =>
            pools.map((pool) =>
                poolNetToDomain(pool)
            )
        )
}

export function getPool(
    chainId: number,
    address: string
): Promise<Pool> {
    return getPoolNet(chainId, address)
        .then((pool) => poolNetToDomain(pool))
}