import { encodeFunctionData } from "viem";
import { getEthereumClient } from "./client";
import ABI from "./erc20abi.json";
import { formatDecimalToFixed } from "./decimals";

export type EvmAddress = string;

const MAX_APPROVE_AMOUNT = 999999999999999000000000000000000n;

const MIN_APPROVE_AMOUNT = 499999999999999000000000000000000n;

export type THexAddress = `0x${string}`;

export const needsTokenApproval = async (
    chainId: number,
    token: EvmAddress,
    owner: EvmAddress,
    spender: EvmAddress
) => {
    const client = getEthereumClient(chainId);

    const data = await client.readContract({
        address: token as THexAddress,
        abi: ABI,
        functionName: "allowance",
        args: [owner, spender],
    });

    return (data as bigint) < MIN_APPROVE_AMOUNT;
};

export const createApproveCalldata = (token: string, spender: string) => ({
    target: token as THexAddress,
    data: encodeFunctionData({
        abi: ABI,
        functionName: "approve",
        args: [spender as THexAddress, MAX_APPROVE_AMOUNT],
    }),
});

export const tokenDecimals = async (
    chainId: number,
    token: EvmAddress
): Promise<number> => {
    const client = getEthereumClient(chainId);

    const decimals = await client.readContract({
        address: token as THexAddress,
        abi: ABI,
        functionName: "decimals",
    });

    return Number(decimals);
};

export const tokenBalance = async (
    chainId: number,
    token: EvmAddress,
    wallet?: EvmAddress
): Promise<string> => {
    if (!wallet) {
        return "0";
    }

    const client = getEthereumClient(chainId);

    const amountPromise = await client.readContract({
        address: token as THexAddress,
        abi: ABI,
        functionName: "balanceOf",
        args: [wallet],
    });

    const decimalsPromise = tokenDecimals(chainId, token);

    return Promise.all([amountPromise, decimalsPromise]).then(
        ([amount, decimals]) => {
            return formatDecimalToFixed(decimals, String(amount));
        }
    );
};
