import {Flex, HStack, Image, Text} from "@chakra-ui/react";
import spacing from "../../../ui/theme/foundations/spacing";
import size from "../../../ui/theme/foundations/sizes";
import colors from "../../../ui/theme/foundations/colors";
import fontSizes from "../../../ui/theme/foundations/fontSizes";
import ClickableBox from "../../../ui/components/containers/ClickableBox";
import {toFormatted} from "../../../ui/utils";
import {TokenUIState} from "../TokensUIState";

export default function TokenListItem(props: {
    item: TokenUIState,
    onClick?: (id: string) => void
}) {
    const { item, onClick } = props
    const percentage = (item.changePercentage > 0)
        ? `+${item.changePercentage}%` : `${item.changePercentage}%`
    const percentageColor = (item.changePercentage > 0)
        ? colors.tg.green : colors.tg.red

    return (
        <ClickableBox
            onClick={() => {
                if (onClick) onClick(item.symbol)
            }}
        >
            <HStack
                w='full'
                paddingX={spacing.list_item_padding_x}
                paddingY={spacing.list_item_padding_y}
                justifyContent='space-between'
            >
                <HStack gap={spacing.list_item_logo_padding_x}>
                    <Image
                        src={props.item.icon}
                        h={size.asset_list_item_logo}
                        w={size.asset_list_item_logo}
                        borderRadius='full'
                    />
                    <Flex
                        direction='column'
                        gap={spacing.list_item_text_spacing_y}
                    >
                        <Text
                            fontSize={fontSizes.medium}
                            fontWeight='semibold'
                        >
                            {item.symbol}
                        </Text>
                        <Flex gap={spacing.list_item_text_spacing_y}>
                            <Text
                                fontSize={fontSizes.small}
                                color={colors.tg.textColorSecondary}
                            >
                                ${toFormatted(item.priceUsd, 8)}
                            </Text>
                            {/*<Text
                                fontSize={fontSizes.small}
                                color={percentageColor}
                            >
                                {percentage}
                            </Text>*/}
                        </Flex>
                    </Flex>
                </HStack>
                <Flex
                    direction='column'
                    gap={spacing.list_item_text_spacing_y}
                >
                    <Text
                        fontSize={fontSizes.medium}
                        fontWeight='semibold'
                        textAlign='end'
                    >
                        {toFormatted(item.amount, 8)}
                    </Text>
                    <Text
                        fontSize={fontSizes.small}
                        color={colors.tg.textColorSecondary}
                        textAlign='end'
                    >
                        ${toFormatted(item.amountUsd, 8)}
                    </Text>
                </Flex>
            </HStack>
        </ClickableBox>
    )
}