import {extendTheme} from "@chakra-ui/react";
import colors from "./foundations/colors";
import styles from "./styles";
import fontSizes from "./foundations/fontSizes";
import textStyles from "./foundations/textStyles";
import spacing from "./foundations/spacing";
import sizes from "./foundations/sizes";
import radius from "./foundations/radius";
import BaseCard from "./components/BaseCard";
import Button from "./components/Button";
import Page from "./components/Page";
import Menu from "./components/Menu";
import Tabs from "./components/Tabs";
import Modal from "./components/Modal";

const overrides = {
    colors: colors,
    styles: styles,
    fontSizes: fontSizes,
    textStyles: textStyles,
    space: spacing,
    sizes: sizes,
    radii: radius,
    components: {
        BaseCard,
        Button,
        Page,
        Menu: Menu,
        Tabs: Tabs,
        Modal: Modal
    }
}

export default extendTheme(overrides)