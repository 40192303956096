declare global {
    interface Number {
        countDecimals(): number
        movePointLeft(step: number): number
        movePointRight(step: number): number
        mirror(): number
        toN(): number
    }
    interface String {
        capitalize(): string
        toN(): number
    }
}

Number.prototype.countDecimals = function (): number {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

    let str = this.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1].toN() || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return str.split("-")[1].toN() || 0;
}

Number.prototype.movePointLeft = function (step: number): number {
    return this.valueOf() / Math.pow(10, step)
}

Number.prototype.movePointRight = function (step: number): number {
    return this.valueOf() * Math.pow(10, step)
}

Number.prototype.mirror = function (): number {
    return this.valueOf() * -1
}

Number.prototype.toN = function(): number {
    return this.valueOf()
}

String.prototype.capitalize = function (): string {
    return this.valueOf().charAt(0).toUpperCase() + this.valueOf().slice(1);
}

String.prototype.toN = function (): number {
    const number = Number(this.valueOf())
    return Number.isNaN(number) ? 0 : number
}

export const removeNulls = <S>(value: S | undefined | null): value is S => value != null