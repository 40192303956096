import {HStack, Icon, Text} from "@chakra-ui/react";
import spacing from "../../theme/foundations/spacing";
import {IconType} from "react-icons";
import sizes from "../../theme/foundations/sizes";
import fontSizes from "../../theme/foundations/fontSizes";
import colors from "../../theme/foundations/colors";

export default function SectionLabel(
    props: { text: string, icon?: IconType }
) {
    const {text, icon} = props

    return (
        <HStack gap={spacing.section_label_spacing_x}>
            {icon &&
                <Icon as={icon} boxSize={sizes.header_label_icon} />
            }
            <Text
                fontSize={fontSizes.header_section}
                color={colors.tg.sectionHeaderTextColor}
                textTransform='uppercase'
            >
                {text}
            </Text>
        </HStack>
    )
}