import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import { StierTokenIcon } from "@ui/icons/Icon";
import colors from "@ui/theme/foundations/colors";
import { PointsUIState } from "@presentation/farming/FarmingUIState";

export default function PointsBalance(props: {
    state: PointsUIState,
}) {
    const { state } = props

    return (
        <Center
            w={"full"}
        >
            <VStack
                gap={"0px"}
            >
                <Text
                    fontSize={"14px"}
                >
                    Your SP balance
                </Text>

                <HStack
                    gap={"10px"}
                >

                    <StierTokenIcon
                        size={"24px"}
                        color={colors.tg.stierBrand}
                    />

                    <Text
                        fontSize={"46px"}
                        fontWeight={"semibold"}
                    >
                        {state.balance}
                    </Text>

                </HStack>

            </VStack>
        </Center>
    )
}