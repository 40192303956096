import {createMultiStyleConfigHelpers} from "@chakra-ui/react";
import colors from "../foundations/colors";
import spacing from "../foundations/spacing";
import radius from "../foundations/radius";
import fontSizes from "../foundations/fontSizes";


const helpers = createMultiStyleConfigHelpers(['tab', 'tablist'])

const Tabs = helpers.defineMultiStyleConfig({
    variants: {
        segmented: {
            tab: {
                bgColor: 'transparent',
                borderRadius: radius.segmented_control_button,
                py: spacing.button_segmented_control_padding_y,
                w: 'full',
                fontSize: fontSizes.medium,
                fontWeight: 'semibold',
                _selected: {
                    textColor: colors.tg.accentColor,
                    bgColor: colors.tg.buttonColorTransparent,
                },
                _active: {
                    bgColor: 'transparent',
                },
            },
            tablist: {
                bgColor: colors.tg.bgColorTransparent,
                p: spacing.segmented_control_container_padding,
                borderRadius: radius.segmented_control,
                w: 'full',
            }
        }
    }
})

export default Tabs