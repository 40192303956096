import {Tab, TabList, Tabs} from "@chakra-ui/react";
import {useAppDispatch} from "../../../../app/hooks";
import {OrderType} from "../../../../domain/trades/models";
import {ORDER_TYPE_CHANGED} from "../../feature";

export default function OrderTypeSelector(
    props: {orderType: OrderType}
) {
    const types = [OrderType.MARKET, OrderType.CONDITIONAL, OrderType.SMART_TRADE]
    const dispatch = useAppDispatch()
    const index = types.indexOf(props.orderType)

    const handleTabChange = (index: number) => {
        const value = types[index]
        dispatch(ORDER_TYPE_CHANGED(value))
    }

    return (
        <Tabs
            index={index}
            variant='segmented'
            onChange={(i) => handleTabChange(i)}
        >
            <TabList>
                <Tab>Market</Tab>
                <Tab>Conditional</Tab>
                <Tab>SmartTrade</Tab>
            </TabList>
        </Tabs>
    )
}