import ModalContainer from "../../../../ui/components/feedback/ModalContainer";
import {SearchIcon} from "../../../../ui/icons/Icon";

export function PoolsEmptyQueryState() {
    return (
        <ModalContainer
            icon={ <SearchIcon size='85px' /> }
            title={ "Search for the pools" }
            subtitle={ "Search by a token or a pool address" }
        />
    )
}

export function PoolsEmptySearchState(props: {
    query: string
}) {
    return (
        <ModalContainer
            icon={ <SearchIcon size='85px' /> }
            title={ "Couldn't find anything" }
            subtitle={ `There are no results for '${props.query}'` }
        />
    )
}