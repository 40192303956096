import {UserToken} from "../../domain/tokens/models";
import {EMPTY_TOKEN} from "../../utils/consts";
import {createSlice, PayloadAction as Effect} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {withdraw} from "../../domain/withdraw/usecases";

export interface WithdrawState {
    token: UserToken,
    address: string,
    withdrawInProgress: boolean,
    withdrawHash: string | null,
    withdrawError: string | null,
}

const initialState: WithdrawState = {
    token: EMPTY_TOKEN,
    address: '',
    withdrawInProgress: false,
    withdrawHash: null,
    withdrawError: null
}

const selectToken = (symbol: string): AppThunk => async (dispatch, getState) => {
    const token = getState().tokens.tokens.find((value) => value.symbol === symbol)
    if (token) dispatch(slice.actions.tokenSelected({ token: token }))
}

const changeAddress = (address: string): AppThunk => async (dispatch) => {
    dispatch(slice.actions.addressChanged({ address: address }))
}

const cleanUp = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.reset())
}

const initWithdrawal = (send: any): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.withdrawInProgress())
    const token = getState().withdraw.token
    const address = getState().withdraw.address
    try {
        const hash = await withdraw(send, token, address)
        dispatch(slice.actions.withdrawSuccess({ hash: hash }))
    } catch (e) {
        dispatch(slice.actions.withdrawError({
            error: (e as object).toString()
        }))
    }
}

const slice = createSlice({
    name: 'withdraw',
    initialState,
    reducers: {
        addressChanged,
        tokenSelected,
        withdrawInProgress,
        withdrawError,
        withdrawSuccess,
        reset,
    }
})

function addressChanged(state: WithdrawState, effect: Effect<{address: string}>) {
    state.address = effect.payload.address
}

function tokenSelected(state: WithdrawState, effect: Effect<{token: UserToken}>) {
    state.token = effect.payload.token
}

function withdrawInProgress(state: WithdrawState) {
    state.withdrawInProgress = true
    state.withdrawError = null
}

function withdrawError(state: WithdrawState, effect: Effect<{error: string}>) {
    state.withdrawInProgress = false
    state.withdrawError = effect.payload.error
}

function withdrawSuccess(state: WithdrawState, effect: Effect<{hash: string}>) {
    state.withdrawInProgress = false
    state.withdrawHash = effect.payload.hash
}

function reset(state: WithdrawState) {
    Object.assign(state, initialState)
}

export const
    withdrawReducer = slice.reducer,
    TOKEN_SELECTED = selectToken,
    ADDRESS_CHANGED = changeAddress,
    CLEAN_UP = cleanUp,
    WITHDRAWAL_INITIATED = initWithdrawal
