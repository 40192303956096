import {Box} from "@chakra-ui/react";
import colors from "../../../../ui/theme/foundations/colors";
import sizes from "../../../../ui/theme/foundations/sizes";

export default function Divider(props: {paddingStart?: string}) {
    let ps = props.paddingStart ?? '0px'
    return (
        <Box
            w='full'
            paddingStart={ps}
        >
            <Box
                w='full'
                h={sizes.divider_height}
                bgColor={colors.tg.hintColor}
                opacity='0.3'
            />
        </Box>
    )
}