import {Pool} from "../pools/models";

export interface Trade {
    id: string,
    pair: string,
    chainId: number,
    poolAddress: string,
    direction: OrderDirection,
    size: number,
    entryPrice?: number,
    total: number,
    status: string,
    poolInfo: Pool
    condition: OrderCondition | null,
    smartTrade?: {
        stopLoss?: OrderStopLoss;
        takeProfit?: OrderTakeProfit;
    },
    close?: {
        units: number,
        total: number,
        price: number
    }
}

export interface OrderCondition {
    price: string
}

export interface OrderTakeProfit {
    pricePct: string,
    price: string
}

export interface OrderStopLoss {
    pricePct: string,
    price: string
}

export interface TradesPage {
    items: Trade[],
    isLast: boolean
}

export enum OrderDirection {
    BUY = "buy",
    SELL = "sell",
}

export enum OrderStatus {
    SUCCESS = "success",
    CLOSED = "closed",
    FAILED = "failed",
    PENDING = "pending",
    CANCELLED = "cancelled",
    STOP_LOSS = "stopLoss"
}

export enum OrderType {
    MARKET = "MARKET",
    CONDITIONAL = "CONDITIONAL",
    SMART_TRADE = "SMART_TRADE",
}

