import {UserTokenNet} from "./models";
import {BASE_URL, fetchAsArray} from "../../utils";

export async function getTokensNet(
    chainId: number,
    address: string,
    limit: number = 100,
    offset: number = 0,
): Promise<UserTokenNet[]> {
    try {
        return fetchAsArray(
            `${BASE_URL}/datalayer/tokens`,
            {
                chainId: chainId,
                address: address,
                limit: limit,
                offset: offset
            }
        )
    } catch (e) {
        return []
    }
}