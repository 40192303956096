import {Box, useStyleConfig} from "@chakra-ui/react";

export function Page(props: any) {
    const {variant, ...rest} = props
    const styles = useStyleConfig(
        'Page',
        { variant: variant }
    )

    return <Box __css={styles} {...rest} />
}