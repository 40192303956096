import {Page} from "../../ui/components/containers/Page";
import {Box, Flex, Text} from "@chakra-ui/react";
import fontSizes from "../../ui/theme/foundations/fontSizes";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {featureStateToTokensUIState} from "./stateMapper";
import spacing from "../../ui/theme/foundations/spacing";
import {BaseCard} from "../../ui/components/containers/BaseCard";
import {useEffect, useState} from "react";
import {TOKENS_LOAD_REQUIRED} from "./feature";
import SkeletonListItems from "../../ui/components/feedback/SkeletonListItems";
import TokenListItems from "./components/TokenListItems";
import TokensEmptyState from "./components/TokensEmptyState";

export default function TokensScreen(props: {
    title?: string,
    onItemClick?: (symbol: string) => void,
}) {
    const state = useAppSelector(state => featureStateToTokensUIState(state.tokens))
    const title = props.title ?? "Your holdings"
    const defaultOnClick = (symbol: string) => {
        window.open(`https://www.coingecko.com/en/coins/${symbol.toLowerCase()}`, '_blank')
    }
    const onCLick = props.onItemClick ?? defaultOnClick

    const [initiated, setInitiated] = useState(false)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!initiated) {
            setInitiated(true)
            dispatch(TOKENS_LOAD_REQUIRED())
        }
    })

    const shouldShowSkeleton = state.items.length === 0 && state.loading
    const content = state.items.length > 0
        ? <TokenListItems
            items={state.items}
            onItemClick={(symbol) => onCLick(symbol)}
        />
        : <TokensEmptyState />
    const listItems = shouldShowSkeleton
        ? <SkeletonListItems count={5}/>
        : content


    return (
        <Page>
            <Flex
                direction='column'
                gap={spacing.content_spacing_v}
            >
                <Box w='full'>
                    <Text fontSize={fontSizes.page_title} as='b'>
                        {title}
                    </Text>

                </Box>
                <BaseCard>
                    <Flex direction='column'>
                        {listItems}
                    </Flex>
                </BaseCard>
            </Flex>
        </Page>
    )
}