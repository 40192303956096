import { Box, Image, Text, VStack } from "@chakra-ui/react";
import colors from "@ui/theme/foundations/colors";

export default function FriendsEmptyState() {
    return (
        <VStack
            p={"16px"}
            gap="12px"
        >
            <Box p="16px">
                <Image src="/referral.png" />
            </Box>
            <VStack gap="4px">
                <Text
                    fontWeight="extrabold"
                    fontSize="16px"
                    textAlign="center"
                >
                    Referral program is launched!
                </Text>
                <Text
                    textColor={colors.tg.textColorSecondary}
                    textAlign="center"
                    fontSize="14px"
                >
                    We’ll airdrop points from your friends very soon!
                </Text>
            </VStack>
        </VStack>
    )
}