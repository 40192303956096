import { FriendUIState } from "@presentation/friends/FriendsUIState";
import FriendListItem from "@presentation/friends/components/FriendListItem";
import Divider from "@presentation/dashboard/components/details/Divider";
import { Flex, Text } from "@chakra-ui/react";

export default function FriendListItems(props: { items: FriendUIState[]; showMore: boolean }) {
    return (
        <>
            {props.items.map((item, index) => {
                const listItem = <FriendListItem item={item} />;

                return (
                    <Flex direction="column" w="full">
                        {listItem}
                        {index < props.items.length - 1 && <Divider paddingStart={"58px"} />}
                    </Flex>
                );
            })}
            {props.showMore && (
                <Text px="16px" py="12px">
                    ... and more
                </Text>
            )}
        </>
    );
}
