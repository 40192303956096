import {OrderDirection, Trade} from "@domain/trades/models";
import {Pool, PoolPrice, PoolToken} from "@domain/pools/models";
import {UserToken} from "@domain/tokens/models";
import {Wallet} from "@domain/wallet/models";
import {EvmChainId} from "@decommas/sdk";
import { Boost, TinderStats } from "@domain/tinder/models";
import { TgUser, TgUserInfo } from "@domain/tg_user/models";

const EMPTY_POOL_TOKEN: PoolToken = {
    address: "",
    symbol: "",
    decimals: -1,
    name: "",
    price: -1,
    logoUrl: "",
    isZeroToken: false
}

const EMPTY_POOL_LIMITS: PoolPrice = {
    price: -1,
    baseLotStep: -1,
    quoteLotStep: -1
}

export const EMPTY_POOL: Pool = {
    address: "",
    liquidity: -1,
    chainName: "",
    chainId: -1,
    dex: "",
    ticker: "",
    baseToken: EMPTY_POOL_TOKEN,
    quoteToken: EMPTY_POOL_TOKEN,
    limits: EMPTY_POOL_LIMITS,
}

export const EMPTY_TRADE: Trade = {
    id: "",
    pair: "",
    chainId: -1,
    poolAddress: "",
    direction: OrderDirection.BUY,
    size: -1,
    total: -1,
    status: "",
    poolInfo: EMPTY_POOL,
    condition: null
}

export const EMPTY_TOKEN: UserToken = {
    address: "",
    chainName: "",
    chainId: -1,
    name: "",
    symbol: "",
    logoUrl: "",
    decimals: -1,
    actualPrice: "",
    amount: "",
}

export const NO_WALLET: Wallet = {
    address: '',
    chainId: -1,
}

export const EMPTY_TG_USER: TgUser = {
    refData: {
        code: "",
        referralsCount: 0,
    }
}

export const EMPTY_TG_USER_INFO: TgUserInfo = {
    userData: {
        id: "",
        firstName: "",
        lastName: "",
        userName: "",
        photo: "",
    },
}

export const EMPTY_TINDER_STATS: TinderStats = {
    totalSwipes: 0,
    level: 0,
    balance: 0,
    refBalance: 0,
    swipeBalance: 0,
    energy: 0,
    hedges: 0,
    aiTrader: false,
    aiPoints: 0,
    maxAiPoints: 0,
    maxHoursForAi: 0,
    lastAction: null,
}

export const EMPTY_BOOST: Boost = {
    id: "",
    name: "",
    description: null,
    picture: null,
    price: 0,
    slug: "",
}

export const NO_VAL = "no_val"
export const DEFAULT_TP_PERCENT = '10'
export const DEFAULT_SL_PERCENT = '5'
export const DEFAULT_AMOUNT = '0.001'
export const BOOST_LEVEL = "levelup"

export interface Chain {
    title: string,
    id: EvmChainId,
    logoUrl: string,
}

export const CHAINS: Chain[] = [
    {
        title: "Base",
        id: EvmChainId.BASE,
        logoUrl: 'https://altcoinsbox.com/wp-content/uploads/2023/02/base-logo-in-blue.webp',
    },
]