export enum SignsAfterPoint {
    TOKEN = 6,
    USD = 2,
}

export const toFixedWithoutRounding = (num?: number, signsAfterPoint = 2): string => {
    // Math.floor при отрицательном числе округляет его
    // Math.floor(-999.999999999) = -1000
    // Math.floor(999.999999999) = -999
    // поэтому мы выносим знак, делаем вычисления и в конце возвращаем знак
    const sign = Math.sign(num ?? 0);
    const res = Math.floor(Math.abs(num ?? 0) * 10 ** signsAfterPoint) / 10 ** signsAfterPoint;
    return (sign * res).toFixed(signsAfterPoint);
};

export function formatBalance(
    amount: number | string,
    signsAfterPoint: number = SignsAfterPoint.TOKEN,
    withCommas: boolean = true,
): string {
    if (
        (amount === 0 || amount === "0" || amount === "") &&
        signsAfterPoint === SignsAfterPoint.USD
    ) {
        return "0.00";
    }

    const preparedValue = Number.isNaN(amount) ? "0" : amount;
    const fixedValue = +toFixedWithoutRounding(+preparedValue, signsAfterPoint);
    const stringValue = withCommas ? fixedValue.toLocaleString("en-US") : String(fixedValue);

    const [value, decimals] = stringValue.split(".");
    const splicedDecimals = decimals?.slice(0, signsAfterPoint) || "";

    return `${value}${splicedDecimals ? "." : ""}${splicedDecimals}`;
}

export const formatLargeNumber = (value: number | string) => {
    const number = Number(value);

    if (Number.isNaN(number)) {
        return 0;
    }

    if (Math.abs(number) > 9999 && Math.abs(number) < 999999) {
        return `${Math.sign(number) * Number((Math.abs(number) / 1000).toFixed(2))}k`;
    }

    if (Math.abs(number) > 999999 && Math.abs(number) < 999999999) {
        return `${Math.sign(number) * Number((Math.abs(number) / 1000000).toFixed(2))}M`;
    }

    if (Math.abs(number) > 999999999) {
        return `${Math.sign(number) * Number((Math.abs(number) / 1000000000).toFixed(2))}B`;
    }

    return number.toFixed(2);
};
