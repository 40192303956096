import { PrivyProvider } from "@privy-io/react-auth";
import { AlchemyPrivySmartAccountProvider } from "./AlchemyProvider";
import {ReactNode} from "react";
import colors from "../../../ui/theme/foundations/colors";
import {getChainConfig, SupportedChains} from "../../blockchain/config";
import {base} from "@alchemy/aa-core";

const PRIVY_APP_ID = process.env.REACT_APP_PRIVY_APP_ID || ''

export const AAProvider = (props: { children: ReactNode }) => {
  return (
      <PrivyProvider
          appId={PRIVY_APP_ID}
          config={{
              loginMethods: ["email", "wallet"],
              appearance: {
                  theme: colors.tg.secondaryBgColor as `#${string}`,
                  accentColor: colors.tg.accentColor as `#${string}`,
                  walletList: ['wallet_connect'],
              },
              defaultChain: base,
              supportedChains: SupportedChains.map((id) => getChainConfig(id).chain),
              embeddedWallets: {
                  createOnLogin: "all-users",
                  noPromptOnSignature: true,
              },
          }
      }
    >
          <AlchemyPrivySmartAccountProvider>
              {props.children}
          </AlchemyPrivySmartAccountProvider>
      </PrivyProvider>
  )
}
