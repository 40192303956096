import {Box, BoxProps} from "@chakra-ui/react";
import colors from "../../theme/foundations/colors";
import radius from "../../theme/foundations/radius";

export default function ClickableBox(
    props: BoxProps
) {
    const {children, ...rest} = props

    return (
        <Box
            _active={{
                bgColor: colors.tg.bgColorTransparent,
                borderRadius: radius.main_button,
            }}
            {...rest}
        >
            {children}
        </Box>
    )
}