import {useNavigate} from "react-router-dom";
import ModalContainer from "../../../ui/components/feedback/ModalContainer";
import {DepositIcon, EmptyIcon} from "../../../ui/icons/Icon";
import {Button} from "@chakra-ui/react";
import {Screen} from "../../../router/Screen";

export default function TokensEmptyState() {
    const navigate = useNavigate()

    return (
        <ModalContainer
            icon={ <EmptyIcon size='85px' /> }
            title={ "Nothing here yet" }
            subtitle={ "Deposits tokens to start trading" }
            action={
                <Button
                    variant='wallet_action'
                    leftIcon={ <DepositIcon size='action_button_icon' /> }
                    onClick={ () => navigate(Screen.DEPOSIT) }
                >
                    Deposit
                </Button>
            }
        />
    )
}