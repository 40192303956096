import SectionHeader from "../../../../ui/components/section/SectionHeader";
import SectionLabel from "../../../../ui/components/section/SectionLabel";
import {Flex, Text} from "@chakra-ui/react";
import {BaseCard} from "../../../../ui/components/containers/BaseCard";
import SectionPlaceholder from "../../../../ui/components/section/SectionPlaceholder";
import fontSizes from "../../../../ui/theme/foundations/fontSizes";
import colors from "../../../../ui/theme/foundations/colors";
import TerminalInput from "../../../../ui/components/inputs/TerminalInput";
import {ConditionUIState} from "../../TerminalUIState";
import {useAppDispatch} from "../../../../app/hooks";
import spacing from "../../../../ui/theme/foundations/spacing";
import {CONDITION_PRICE_CHANGED} from "../../feature";

export default function ConditionPrice(
    props: {state: ConditionUIState}
) {
    const dispatch = useAppDispatch()
    const state = props.state
    const handleChange = (value: string) => {
        dispatch(CONDITION_PRICE_CHANGED(value))
    }

    return (
        <SectionPlaceholder
            header={
                <SectionHeader
                    left={
                        <SectionLabel text='Condition price'/>
                    }
                />
            }
            content={
                <BaseCard variant='section'>
                    <TerminalInput
                        value={state.value}
                        logoUrl={state.quoteLogoUrl}
                        symbol={state.quoteSymbol}
                        onChange={handleChange}
                    />
                </BaseCard>
            }
            footer={
                <Flex
                    direction='column'
                    gap={spacing.list_item_text_spacing_y}
                >
                    {state.error &&
                        <Text
                            fontSize={fontSizes.header_section}
                            color={colors.tg.red}
                        >
                            {state.error}
                        </Text>}
                    <Text
                        fontSize={fontSizes.header_section}
                        color={colors.tg.sectionHeaderTextColor}
                    >
                        {`Price of ${state.baseSymbol} to trigger market order`}
                    </Text>
                </Flex>
            }
        />
    )
}