import {UserToken} from "../tokens/models";
import {encodeFunctionData} from "viem";
import {ABI} from "./abi";
import {THexAddress} from "../blockchain/erc20";
import {UserOperation} from "../auth/alchemy/smartAccount";

export async function withdraw(
    sendUserOperations: (operations: UserOperation[]) => Promise<THexAddress>,
    token: UserToken,
    address: string
): Promise<string> {
    return token.address
        ? withdrawToken(sendUserOperations, token, address)
        : withdrawNative(sendUserOperations, token, address)
}

async function withdrawToken(
    sendUserOperation: (operations: UserOperation[]) => Promise<THexAddress>,
    token: UserToken,
    address: string,
): Promise<string> {
    const data = encodeFunctionData({
        abi: ABI,
        functionName: "transfer",
        args: [address, token.amount],
    });

    return await sendUserOperation([{
        target: token.address as THexAddress,
        data,
    }])
}

async function withdrawNative(
    sendUserOperation: (operations: UserOperation[]) => Promise<THexAddress>,
    token: UserToken,
    address: string,
): Promise<string> {
    return await sendUserOperation([{
        target: address as THexAddress,
        data: "0x0",
        value: token.amount,
    }]);
}