import {Trade} from "../../domain/trades/models";
import {AppThunk} from "../../app/store";
import {createSlice, PayloadAction as Effect} from "@reduxjs/toolkit";
import {getTradesPage} from "../../domain/trades/usecases";

const PAGE_SIZE = 5

export interface TradesState {
    active: boolean,
    trades: Trade[],
    tradesLoading: boolean,
    tradesLoadingError: string | null,
    isLastPage: boolean,
}

const initialState: TradesState = {
    active: true,
    trades: [],
    tradesLoading: false,
    tradesLoadingError: null,
    isLastPage: false,
}

const loadPage = (reload: boolean): AppThunk => async (dispatch, getState) => {
    const state = getState().trades
    const nextPage = reload
        ? 0
        : Math.floor(state.trades.length/PAGE_SIZE)
    dispatch(slice.actions.tradesLoadingStarted())
    try {
        const tradesPage = await getTradesPage({
            active: state.active,
            perPage: PAGE_SIZE,
            page: nextPage,
        })
        dispatch(slice.actions.tradesLoaded({
            trades: tradesPage.items,
            isLastPage: tradesPage.isLast,
            reload: reload
        }))
    } catch (e) {
        dispatch(slice.actions.tradesLoadingError({
            error: (e as object).toString()
        }))
    }
}

const changeTab = (active: boolean): AppThunk => async (dispatch) => {
    dispatch(slice.actions.tabChanged({
        active: active
    }))
    dispatch(NEW_PAGE_REQUESTED(true))
}

const slice = createSlice({
    name: 'trades',
    initialState,
    reducers: {
        tradesLoadingStarted,
        tradesLoaded,
        tradesLoadingError,
        tabChanged,
    }
})

function tradesLoadingStarted(state: TradesState) {
    state.tradesLoading = true
    state.tradesLoadingError = null
}

function tradesLoaded(state: TradesState, effect: Effect<{reload: boolean, trades: Trade[], isLastPage: boolean}>) {
    const trades = effect.payload.reload
        ? effect.payload.trades
        : state.trades.concat(effect.payload.trades)
    state.tradesLoading = false
    state.trades = trades
    state.isLastPage = effect.payload.isLastPage
}

function tradesLoadingError(state: TradesState, effect: Effect<{error: string}>) {
    state.tradesLoading = false
    state.tradesLoadingError = effect.payload.error
}

function tabChanged(state: TradesState, effect: Effect<{active: boolean}>) {
    state.trades = []
    state.active = effect.payload.active
    state.isLastPage = false
}

export const
    tradesReducer = slice.reducer,
    NEW_PAGE_REQUESTED = loadPage,
    TAB_CHANGED = changeTab