import * as forge from "node-forge";

export interface EncryptedAes {
    key: string,
    iv: string,
    content: string,
}

export function encryptWithPublicKey(key: string, content: string) {
    const publicKey = forge.pki.publicKeyFromPem(key)

    return publicKey.encrypt(content, "RSAES-PKCS1-V1_5")
}

export async function encryptAes(publicKey: string, content: string): Promise<EncryptedAes> {
    const key = forge.random.getBytesSync(32)
    const iv = forge.random.getBytesSync(16)

    const cipher = forge.cipher.createCipher("AES-CBC", key)
    cipher.start({ iv })
    cipher.update(forge.util.createBuffer(content))
    cipher.finish()

    const encryptedContent = cipher.output.getBytes()
    const encryptedContentBase64 = forge.util.encode64(encryptedContent)

    const encryptedKey = encryptWithPublicKey(publicKey, key)
    const encryptedIv = encryptWithPublicKey(publicKey, iv)

    return {
        key: forge.util.encode64(encryptedKey),
        iv: forge.util.encode64(encryptedIv),
        content: encryptedContentBase64,
    }
}