import { ReferralNet, TgUserInfoNet, TgUserNet } from "@storage/net/decommas/user/models";
import { Referral, TgUser, TgUserInfo } from "@domain/tg_user/models";

export function tgUserNetToDomain(net: TgUserNet): TgUser {
    return {
        refData: {
            code: net.ref.code,
            referralsCount: net.ref.referrals,
        },
    };
}

export function tgUserInfoNetToDomain(net: TgUserInfoNet): TgUserInfo {
    return {
        userData: {
            id: net.tgInfo.id,
            firstName: net.tgInfo.firstName,
            lastName: net.tgInfo.lastName,
            userName: net.tgInfo.username,
            photo: net.tgInfo.photoFile,
        },
    };
}

export function referralNetToDomain(referral: ReferralNet): Referral {
    return {
        userData: {
            id: referral.id,
            firstName: referral.tgInfo.firstName,
            lastName: referral.tgInfo.lastName,
            userName: referral.tgInfo.username,
            photo: referral.tgInfo.photoFile,
        },
        referrals: referral.referrals,
    };
}
