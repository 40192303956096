import {InviteNet} from "../../storage/net/decommas/user/models";
import {AppThunk} from "../../app/store";
import {getUserStatsNet} from "../../storage/net/decommas/user/authAPI";
import {createSlice, PayloadAction as Effect} from "@reduxjs/toolkit";

export interface InvitesState {
    invites: InviteNet[],
    invitesLoading: boolean,
    invitesLoadingError: string | null,
}

const initialState: InvitesState = {
    invites: [],
    invitesLoading: false,
    invitesLoadingError: null,
}

const loadInvites = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.invitesLoadingStarted())
    try {
        const invites = await getUserStatsNet()
            .then((stats) => stats.invites)
        dispatch(slice.actions.invitesLoaded({
            invites: invites,
        }))
    } catch (e) {
        dispatch(slice.actions.invitesLoadingError({
            error: (e as object).toString()
        }))
    }
}

const slice = createSlice({
    name: 'invites',
    initialState,
    reducers: {
        invitesLoadingStarted,
        invitesLoaded,
        invitesLoadingError,
    }
})

function invitesLoadingStarted(state: InvitesState) {
    state.invitesLoading = true
    state.invitesLoadingError = null
}

function invitesLoaded(state: InvitesState, effect: Effect<{invites: InviteNet[]}>) {
    state.invitesLoading = false
    state.invites = effect.payload.invites
}

function invitesLoadingError(state: InvitesState, effect: Effect<{error: string}>) {
    state.invitesLoading = false
    state.invitesLoadingError = effect.payload.error
}

export const
    invitesReducer = slice.reducer,
    INVITES_LOAD_REQUIRED = loadInvites