import {Page} from "../../../ui/components/containers/Page";
import {Box, Flex, Input, Text} from "@chakra-ui/react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {featureStateToPoolsUIState} from "./stateMapper";
import {useEffect, useState} from "react";
import {QUERY_CHANGED, POOLS_LOAD_REQUIRED} from "./feature";
import spacing from "../../../ui/theme/foundations/spacing";
import fontSizes from "../../../ui/theme/foundations/fontSizes";
import {BaseCard} from "../../../ui/components/containers/BaseCard";
import SectionPlaceholder from "../../../ui/components/section/SectionPlaceholder";
import SkeletonListItems from "../../../ui/components/feedback/SkeletonListItems";
import {PoolsEmptyQueryState, PoolsEmptySearchState} from "./components/PoolsEmptyState";
import PoolListItems from "./components/PoolListItems";
import {useNavigate} from "react-router-dom";
import {Screen} from "../../../router/Screen";
import {POOL_SELECTED} from "../feature";

export default function PoolsScreen() {
    const state = useAppSelector(state => featureStateToPoolsUIState(state.pools))
    const navigate = useNavigate()

    const [initiated, setInitiated] = useState(false)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!initiated) {
            setInitiated(true)
            dispatch(POOLS_LOAD_REQUIRED)
        }
    })

    const noItems = state.items.length === 0
    const shouldShowSkeleton = noItems && state.loading
    const emptyState = state.query.length > 0
        ? <PoolsEmptySearchState query={state.query} />
        : <PoolsEmptyQueryState />
    const content = state.items.length > 0
        ? <PoolListItems
            items={state.items}
            onItemClick={ (address) => {
                dispatch(POOL_SELECTED(address))
                navigate(Screen.TERMINAL, { replace: true })
            }}
        />
        : emptyState
    const listItems = shouldShowSkeleton
        ? <SkeletonListItems count={5}/>
        : content

    return (
        <Page>
            <Flex
                direction='column'
                gap={spacing.content_spacing_v}
            >
                <Box w='full'>
                    <Text
                        fontSize={fontSizes.page_title}
                        as='b'
                    >
                        Select pool
                    </Text>
                </Box>

                <SectionPlaceholder
                    content={
                        <BaseCard variant='section'>
                            <Input
                                paddingX={spacing.card_padding_h}
                                paddingY={spacing.card_padding_v}
                                variant='unstyled'
                                value={state.query}
                                placeholder='Search for a pool'
                                onChange={ (event) => dispatch(
                                    QUERY_CHANGED(event.currentTarget.value)
                                ) }
                            />
                        </BaseCard>
                    }
                />

                <BaseCard>
                    <Flex direction='column'>
                        {listItems}
                    </Flex>
                </BaseCard>
            </Flex>
        </Page>
    )
}