import { QuestNet, QuestsCategoryNet } from "@storage/net/decommas/quest/models";
import { Quest, QuestsCategory } from "./models";

export const questNetToDomain = (questNet: QuestNet): Quest => {
    return { ...questNet, finished: questNet.createdAt };
};

export const questsCategoryNetToDomain = (questsCategoryNet: QuestsCategoryNet): QuestsCategory => {
    return {
        id: questsCategoryNet.id,
        name: questsCategoryNet.name,
        quests: questsCategoryNet.quests.map((quest) => questNetToDomain(quest)),
    };
};
