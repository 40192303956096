import ClickableBox from "../../../ui/components/containers/ClickableBox";
import {Box, Center, Flex, HStack, Image, Text} from "@chakra-ui/react";
import spacing from "../../../ui/theme/foundations/spacing";
import size from "../../../ui/theme/foundations/sizes";
import colors from "../../../ui/theme/foundations/colors";
import fontSizes from "../../../ui/theme/foundations/fontSizes";
import {TradeUIState} from "../TradesUIState";

export default function TradeListItem(props: {
    item: TradeUIState,
    onClick?: (id: string) => void
}) {
    const { item, onClick } = props

    return (
        <ClickableBox
            onClick={() => {
                if (onClick) onClick(item.id)
            }}
        >
            <HStack
                w='full'
                paddingX={spacing.list_item_padding_x}
                paddingY={spacing.list_item_padding_y}
                justifyContent='space-between'
            >
                <Flex
                    direction='row'
                >
                    <Center
                        zIndex='2'
                        h='23px'
                        w='23px'
                        borderRadius='full'
                        border='0.5px'
                        borderColor={colors.tg.bgColor}
                        bg='white'
                        marginBottom='11px'
                    >
                        <Image
                            src={props.item.baseLogoUrl}
                            h={size.trade_list_item_logo}
                            w={size.trade_list_item_logo}
                            border='0.5px'
                            borderColor={colors.tg.bgColor}
                            borderRadius='full'
                        />
                    </Center>
                    <Center
                        zIndex='1'
                        marginStart='-11px'
                        h='23px'
                        w='23px'
                        borderRadius='full'
                        border='0.5px'
                        borderColor={colors.tg.bgColor}
                        bg='white'
                        marginTop='11px'
                    >
                        <Image
                            src={props.item.quoteLogoUrl}
                            h={size.trade_list_item_logo}
                            w={size.trade_list_item_logo}
                            border='0.5px'
                            borderColor={colors.tg.bgColor}
                            borderRadius='full'
                        />
                    </Center>
                </Flex>

                <Flex
                    w='full'
                    direction='column'
                    gap={spacing.list_item_text_spacing_y}
                >
                    <HStack
                        w='full'
                        justifyContent='space-between'
                    >
                        <Flex>
                            <Text
                                fontSize={fontSizes.medium}
                                fontWeight='semibold'
                            >
                                {item.baseSymbol}
                            </Text>
                            <Text
                                fontSize={fontSizes.medium}
                                fontWeight='semibold'
                                color={colors.tg.textColorSecondary}
                            >
                                /{item.quoteSymbol}
                            </Text>
                        </Flex>
                        <Text
                            fontSize={fontSizes.medium}
                            color={item.statusPrimaryColor}
                            fontWeight='semibold'
                        >
                            {item.statusPrimary}
                        </Text>
                    </HStack>
                    <HStack
                        w='full'
                        justifyContent='space-between'
                    >
                        <Box
                            px='4px'
                            py='1px'
                            borderRadius='6px'
                            backgroundColor={`${item.typeColor}26`}
                        >
                            <Text
                                color={item.typeColor}
                                fontSize={fontSizes.small}
                            >
                                {item.type}
                            </Text>
                        </Box>
                        <Text
                            fontSize={fontSizes.small}
                            color={item.statusSecondaryColor}
                        >
                            {item.statusSecondary}
                        </Text>
                    </HStack>

                </Flex>

            </HStack>
        </ClickableBox>
    )
}