import {createMultiStyleConfigHelpers} from "@chakra-ui/react";
import spacing from "../foundations/spacing";
import colors from "../foundations/colors";
import fontSizes from "../foundations/fontSizes";
import radius from "../foundations/radius";


const helpers = createMultiStyleConfigHelpers(['menu', 'item', 'list'])

const Menu = helpers.defineMultiStyleConfig({
    variants: {
        network_selector: {
            list: {
                py: spacing.menu_item_padding,
                bgColor: colors.tg.secondaryBgColor,
                borderRadius: radius.card,
                border: 'none',
                boxShadow: '2xl'
            },
            item: {
                color: colors.tg.textColor,
                bgColor: colors.tg.secondaryBgColor,
                borderRadius: radius.card,
                py: spacing.menu_item_padding,
                fontSize: fontSizes.large,
            }
        }
    }
})

export default Menu