import ModalContainer from "@ui/components/feedback/ModalContainer";
import { EmptyIcon } from "@ui/icons/Icon";

export default function QuestsEmptyState() {
    return (
        <ModalContainer
            icon={<EmptyIcon size="85px" />}
            title="No tasks for you yet"
            subtitle="Come back later"
        />
    );
};