import {
    BASE_URL,
    Method,
    fetchAsArray,
    fetchAsObject,
    getAuthHeader,
    httpRequest,
} from "@storage/net/utils";
import { TinderSwipeResultNet, TinderPoolShortNet, TinderPoolDetailsNet, TinderStatsNet, BoostNet } from "./models";

export async function checkTinderSwipe(chainId: number, poolAddress: string, direction: number) {
    const headers = getAuthHeader();
    try {
        return await httpRequest<TinderSwipeResultNet>({
            url: `${BASE_URL}/swipe`,
            params: [
                {
                    chainId,
                    poolAddress,
                    direction,
                },
            ],
            method: Method.POST,
            headers,
        });
    } catch (e) {
        console.error("Error swiping token");
    }
}

export async function getTinderPoolShort(): Promise<TinderPoolShortNet[]> {
    const headers = getAuthHeader();

    try {
        return fetchAsArray<TinderPoolShortNet>(`${BASE_URL}/swipe/pools`, {}, headers);
    } catch (e) {
        throw Error("Error loading swipeable tokens");
    }
}

export async function getTinderPoolDetails(
    chainId: number,
    address: string,
): Promise<TinderPoolDetailsNet> {
    const headers = getAuthHeader();

    try {
        return fetchAsObject<TinderPoolDetailsNet>(
            `${BASE_URL}/swipe/pool`,
            { chainId, address },
            headers,
        );
    } catch (e) {
        throw Error(`Error loading tinder pool ${address} ${chainId}`);
    }
}

export async function getTinderStatsNet(): Promise<TinderStatsNet> {
    const headers = getAuthHeader();

    try {
        return fetchAsObject<TinderStatsNet>(
            `${BASE_URL}/swipe/stats`,
            { },
            headers,
        );
    } catch (e) {
        throw Error(`Error loading tinder stats`);
    }
}

export async function claimOrStartMiningNet(): Promise<any> {
    const headers = getAuthHeader();
    try {
        return await httpRequest<TinderSwipeResultNet>({
            url: `${BASE_URL}/tgauth/claim`,
            params: [],
            method: Method.POST,
            headers,
        });
    } catch (e) {
        console.error("Error mining action");
    }
}

export async function getBoostsNet(): Promise<BoostNet[]> {
    const headers = getAuthHeader();

    try {
        return fetchAsArray<BoostNet>(
            `${BASE_URL}/boost`,
            { },
            headers,
        );
    } catch (e) {
        throw Error(`Error loading boosts`);
    }
}

export async function buyBoost(slug: string) {
    const headers = getAuthHeader();
    try {
        return await httpRequest<any>({
            url: `${BASE_URL}/boost`,
            params: {
                slug: slug,
            },
            method: Method.POST,
            headers,
        });
    } catch (e) {
        throw Error(`Error buying boost`);
    }
}