import SectionPlaceholder from "../../../../ui/components/section/SectionPlaceholder";
import SectionHeader from "../../../../ui/components/section/SectionHeader";
import SectionLabel from "../../../../ui/components/section/SectionLabel";
import {BaseCard} from "../../../../ui/components/containers/BaseCard";
import {TakeProfitUIState} from "../../TerminalUIState";
import {Button, HStack, IconButton, Text} from "@chakra-ui/react";
import spacing from "../../../../ui/theme/foundations/spacing";
import {CloseIcon} from "../../../../ui/icons/Icon";
import sizes from "../../../../ui/theme/foundations/sizes";
import colors from "../../../../ui/theme/foundations/colors";
import DecimalInput from "../inputs/DecimalInput";
import {useAppDispatch} from "../../../../app/hooks";
import {TP_CHANGED, TP_ENABLED} from "../../feature";
import fontSizes from "../../../../ui/theme/foundations/fontSizes";

export default function TakeProfit(props: {
    state: TakeProfitUIState,
}) {
    const dispatch = useAppDispatch()

    const state = props.state
    const handleChange = (value: string) => {
        dispatch(TP_CHANGED(value))
    }

    return (
        <SectionPlaceholder
            header={
                <SectionHeader
                    left={
                        <SectionLabel text={state.title}/>
                    }
                />
            }
            content={
                state.enabled
                    ? (
                        <HStack spacing={spacing.content_spacing_h_medium}>
                            <BaseCard variant='section'>
                                <HStack
                                    w='full'
                                    paddingX={spacing.card_padding_h}
                                    paddingY={spacing.card_padding_v}
                                    justifyContent='space-between'
                                >
                                    <DecimalInput
                                        precision={2}
                                        value={state.value}
                                        onChange={handleChange}
                                    />
                                    <Text as='b'>%</Text>
                                </HStack>
                            </BaseCard>
                            <IconButton
                                variant='icon'
                                aria-label='close'
                                bgColor={colors.tg.redTransparent}
                                icon={<CloseIcon color={colors.tg.red} size={sizes.refresh_icon}/>}
                                onClick={() => dispatch(TP_ENABLED(false))}
                            />
                        </HStack>
                    )
                    : (
                        <Button
                            w='full'
                            variant='main'
                            onClick={() => dispatch(TP_ENABLED(true)) }
                        >
                            Enable Take Profit
                        </Button>
                    )
            }
            footer={
                state.error &&
                <Text
                    fontSize={fontSizes.header_section}
                    color={colors.tg.red}
                >
                    {state.error}
                </Text>
            }
        />
    )
}