import SectionPlaceholder from "../section/SectionPlaceholder";
import SectionHeader from "../section/SectionHeader";
import fontSizes from "../../theme/foundations/fontSizes";
import colors from "../../theme/foundations/colors";
import {Skeleton} from "@chakra-ui/react";
import radius from "../../theme/foundations/radius";

export default function SkeletonSectionItem() {
    return (
        <SectionPlaceholder
            header={
                <SectionHeader
                    left={
                        <Skeleton
                            borderRadius='6px'
                            w='60px'
                            h={fontSizes.medium}
                            startColor={colors.tg.skeletonStart}
                            endColor={colors.tg.skeletonEnd}
                        />
                    }
                />
            }
            content={
                <Skeleton
                    borderRadius={radius.card}
                    w='full'
                    h='50px'
                    startColor={colors.tg.skeletonStart}
                    endColor={colors.tg.skeletonEnd}
                />
            }
            footer={
                <Skeleton
                    borderRadius='6px'
                    w='120px'
                    h={fontSizes.medium}
                    startColor={colors.tg.skeletonStart}
                    endColor={colors.tg.skeletonEnd}
                />
            }
        />
    )
}