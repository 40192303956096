import {HStack, Text} from "@chakra-ui/react";
import fontSizes from "../../../../ui/theme/foundations/fontSizes";
import colors from "../../../../ui/theme/foundations/colors";
import ClickableBox from "../../../../ui/components/containers/ClickableBox";
import {PairUIState} from "../../TerminalUIState";

export default function Price(
    props: {state: PairUIState}
) {
    const state = props.state
    return (
        <ClickableBox>
            <HStack>
                <Text
                    fontSize={fontSizes.header_section}
                    color={colors.tg.accentColor}
                >
                    {`1 ${state.baseSymbol} = ${state.price} ${state.quoteSymbol}`}
                </Text>
            </HStack>
        </ClickableBox>
    )
}