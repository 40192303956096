import SkeletonListItem from "./SkeletonListItem";
import { Flex } from "@chakra-ui/react";
import Divider from "../../../presentation/dashboard/components/details/Divider";
import spacing from "../../theme/foundations/spacing";

export default function SkeletonListItems(props: { count: number }) {
    return Array.from({ length: props.count }).map((item, index) => {
        const listItem = <SkeletonListItem />;

        return (
            <Flex direction="column" w="full" key={index}>
                {listItem}
                {index < props.count - 1 && (
                    <Divider paddingStart={spacing.divider_token_padding_start} />
                )}
            </Flex>
        );
    });
}
