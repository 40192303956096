import {Trade, TradesPage} from "./models";
import {getTrades} from "./repo";
import {getCurrentWallet} from "../wallet/repo";

export async function getTradesForDashboard(): Promise<Trade[]> {
    const wallet = getCurrentWallet()
    const active = await getTrades({
        active: true,
        perPage: 5,
        page: 0,
        chainId: wallet.chainId,
    })
    const finished = await getTrades({
        active: false,
        perPage: 5,
        page: 0,
        chainId: wallet.chainId,
    })
    return active.concat(finished)
}

export async function getTradesPage(params: {
    active: boolean,
    perPage: number,
    page: number,
}): Promise<TradesPage> {
    const wallet = getCurrentWallet()
    return getTrades({
        active: params.active,
        perPage: params.perPage,
        page: params.page,
        chainId: wallet.chainId,
    }).then((result) => {
        const isLast = result.length < params.perPage
        return {
            items: result,
            isLast: isLast
        }
    })
}