import {Page} from "../../ui/components/containers/Page";
import {BaseCard} from "../../ui/components/containers/BaseCard";
import ClickableBox from "../../ui/components/containers/ClickableBox";
import spacing from "../../ui/theme/foundations/spacing";
import {Box, Flex, HStack, Text} from "@chakra-ui/react";
import colors from "../../ui/theme/foundations/colors";
import sizes from "../../ui/theme/foundations/sizes";
import {LogOutIcon} from "../../ui/icons/Icon";
import fontSizes from "../../ui/theme/foundations/fontSizes";
import {useAppDispatch} from "../../app/hooks";
import {USER_LOGGED_OUT} from "../auth/feature";
import {useSmartAccount} from "../../domain/auth/alchemy/AlchemyProvider";
import {sendEvent} from "../../utils/analytics";
import { WebApp } from "@ui/telegram/utils";

export function Settings() {
    const dispatch = useAppDispatch()
    const {logout} = useSmartAccount()

    return (
        <Page>

            <Flex
                direction='column'
                gap={spacing.content_spacing_v}
            >
                <Box w='full'>
                    <Text
                        fontSize={fontSizes.page_title}
                        as='b'
                    >
                        Settings
                    </Text>
                </Box>
            </Flex>

            <BaseCard variant='section'>

                <ClickableBox
                    w='full'
                    px={spacing.card_padding_h}
                    py={spacing.card_padding_v}
                    onClick={() =>
                        WebApp.showConfirm(
                            "Do you want to log out?",
                            (confirmed) => {
                                if (confirmed) {
                                    sendEvent("sign-out-click")
                                    logout().then(() =>
                                        dispatch(USER_LOGGED_OUT())
                                    )
                                }
                            }
                        )
                    }
                >
                    <HStack
                        w='full'
                        gap={spacing.list_item_logo_padding_x}
                        justifyContent='space-between'
                    >
                        <Text>Log out</Text>
                        <LogOutIcon
                            color={colors.tg.red}
                            size={sizes.default_icon}
                        />
                    </HStack>
                </ClickableBox>

            </BaseCard>
        </Page>
    )

}