import { combineReducers, configureStore, ThunkAction, UnknownAction } from "@reduxjs/toolkit";
import { accountReducer } from "@presentation/auth/feature";
import { terminalReducer } from "@presentation/terminal/feature";
import { dashboardReducer } from "@presentation/dashboard/feature";
import { tokensReducer } from "@presentation/tokens/feature";
import { tradesReducer } from "@presentation/trades/feature";
import { poolsReducer } from "@presentation/terminal/pools/feature";
import { tradeDetailsReducer } from "@presentation/trades/trade/feature";
import { invitesReducer } from "@presentation/invites/feature";
import { withdrawReducer } from "@presentation/withdraw/feature";
import { tinderReducer } from "@presentation/tinder/feature";
import { referralReducer } from "@presentation/friends/feature";
import { questReducer } from "@presentation/quest/feature";
import { farmingReducer } from "@presentation/farming/feature";

const rootReducer = combineReducers({
    account: accountReducer,
    terminal: terminalReducer,
    dashboard: dashboardReducer,
    tinder: tinderReducer,
    referral: referralReducer,
    farming: farmingReducer,
    tokens: tokensReducer,
    trades: tradesReducer,
    tradeDetails: tradeDetailsReducer,
    pools: poolsReducer,
    invites: invitesReducer,
    withdraw: withdrawReducer,
    quest: questReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    UnknownAction
>;
