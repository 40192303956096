import { WebApp } from "@ui/telegram/utils";

const bgColor = WebApp.themeParams.bg_color ?? "#18232E";
const textColor = WebApp.themeParams.text_color ?? "#FFFFFF";
const textColorSecondary = `${textColor}99`;
const hintColor = WebApp.themeParams.hint_color ?? "#83898E";
const linkColor = WebApp.themeParams.link_color ?? "#2EA6FF";
const linkTransparent = `${linkColor}26`;
const buttonColor = WebApp.themeParams.button_color ?? "#2EA6FF";
const buttonColorTransparent = `${buttonColor}26`;
const buttonConfirm = "#2EB851";
const buttonConfirmTransparent = `${buttonConfirm}26`;
const secondaryBgColor = WebApp.themeParams.secondary_bg_color ?? "#18232E";
const secondaryBgColorTransparent = `${secondaryBgColor}99`;
const sectionBgColor = WebApp.themeParams.section_bg_color ?? "#212F3F";
const sectionHeaderTextColor = WebApp.themeParams.section_header_text_color ?? "#83898E";
const accentColor = WebApp.themeParams.accent_text_color ?? buttonColor;
const green = "#34C759";
const greenTransparent = `${green}26`;
const red = "#FE2C55";
const redTransparent = `${red}26`;
const yellow = "#EAA432";
const yellowTransparent = `${yellow}26`;
const gold = "#DAA520";
const goldTransparent = `${gold}26`;

export const colors = {
    tg: {
        bgColor: secondaryBgColor,
        bgColorTransparent: secondaryBgColorTransparent,
        textColor,
        textColorSecondary,
        hintColor,
        linkColor,
        linkTransparent,
        buttonConfirm,
        buttonConfirmTransparent,
        accentColor,
        disabledControlColor: "#ABAFB3",
        buttonColor,
        buttonColorTransparent,
        secondaryBgColor,
        sectionBgColor,
        sectionHeaderTextColor,
        green,
        greenTransparent,
        red,
        redTransparent,
        yellow,
        yellowTransparent,
        gold,
        goldTransparent,
        skeletonStart: "#C6C6C670",
        skeletonEnd: "#C6C6C626",
        gray: "#7A7A7A",
        stierBrand: "#A08AFF",
    },
};

export default colors;
