import { CreateAccountsRequest, MissingPermissionsNet, PoolAccountNet, TradeNet } from "./models";
import {
    BASE_URL,
    fetchAsArray,
    fetchAsObject,
    getPrivyHeader,
    httpRequest,
    IResponseData,
    Method,
} from "../../utils";
import { TradeCreateData } from "../../../../domain/trades/create/models";

export async function getTradesNet(
    active: boolean = true,
    chainId: number,
    orderBy?: string,
    direction?: string,
    offset?: number,
    limit?: number,
): Promise<TradeNet[]> {
    const header = await getPrivyHeader();
    const endpoint = active ? "/trade/orders" : "/trade/orders/history";

    try {
        return fetchAsArray(
            `${BASE_URL}${endpoint}`,
            {
                orderBy: orderBy,
                direction: direction,
                offset: offset,
                limit: limit,
                chainId: chainId,
            },
            header,
        );
    } catch (e) {
        return [];
    }
}

export async function createTradeNet(data: TradeCreateData): Promise<boolean> {
    const header = await getPrivyHeader();
    try {
        await httpRequest({
            url: `${BASE_URL}/trade/market`,
            params: {
                pool: data.pool.address,
                chainId: data.pool.chainId,
                direction: String(data.direction).toLowerCase(),
                amount: data.amount,
                options: data.options,
                condition: data.condition ? { price: data.condition } : undefined,
                smartTrade: data.smartTrade,
            },
            method: Method.POST,
            headers: header,
        });
        return true;
    } catch (e) {
        throw Error("Error creating trade");
    }
}

export async function cancelTradeNet(id: string): Promise<boolean> {
    const header = await getPrivyHeader();
    try {
        await httpRequest({
            url: `${BASE_URL}/trade/orders/cancel/${id}`,
            params: { id: id },
            method: Method.POST,
            headers: header,
        });
        return true;
    } catch (e) {
        throw Error("Error cancelling trade");
    }
}

export async function closeTradeNet(id: string): Promise<boolean> {
    const header = await getPrivyHeader();
    try {
        await httpRequest({
            url: `${BASE_URL}/trade/orders/close/${id}`,
            params: { id: id },
            method: Method.POST,
            headers: header,
        });
        return true;
    } catch (e) {
        throw Error("Error closing trade");
    }
}

/** @deprecated */
export async function getPoolAccountNet(chainId: number, pool: string): Promise<PoolAccountNet> {
    const header = await getPrivyHeader();
    try {
        return fetchAsObject(
            `${BASE_URL}/trade/account`,
            {
                chainId: chainId,
                pool: pool,
            },
            header,
        );
    } catch (e) {
        throw Error((e as object).toString());
    }
}

export async function getMissingPermissions(chainId: number): Promise<MissingPermissionsNet[]> {
    const header = await getPrivyHeader();
    try {
        return fetchAsArray(
            `${BASE_URL}/userops/missing-permissions`,
            { chainId: chainId },
            header,
        );
    } catch (e) {
        return [];
    }
}

/** @deprecated */
export async function createPoolAccountNet(
    chainId: number,
    pool: string,
    permission: any,
): Promise<boolean> {
    const header = await getPrivyHeader();
    try {
        await httpRequest({
            url: `${BASE_URL}/trade/account`,
            params: {
                chainId: chainId,
                pool: pool,
                permission: permission,
            },
            method: Method.POST,
            headers: header,
        });
        return true;
    } catch (e) {
        throw Error("Error creating pool account");
    }
}

export async function createTradingAccountsNet(data: CreateAccountsRequest[]): Promise<boolean> {
    const header = await getPrivyHeader();
    try {
        return await httpRequest({
            url: `${BASE_URL}/trade/accounts`,
            params: data,
            method: Method.POST,
            headers: header,
        });
    } catch (e) {
        throw Error("Error creating trading accounts");
    }
}

export async function getTransportKey(): Promise<string> {
    const header = await getPrivyHeader();
    try {
        const result = await httpRequest<IResponseData<string>>({
            url: `${BASE_URL}/trade/transport-key`,
            method: Method.POST,
            headers: header,
        });
        return result.data;
    } catch (e) {
        throw Error((e as object).toString());
    }
}
