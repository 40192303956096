import {Page} from "../../ui/components/containers/Page";
import QRCode from 'react-qr-code'
import {Button, Flex, Text, useToast, VStack} from "@chakra-ui/react";
import spacing from "../../ui/theme/foundations/spacing";
import fontSizes from "../../ui/theme/foundations/fontSizes";
import colors from "../../ui/theme/foundations/colors";
import {BaseCard} from "../../ui/components/containers/BaseCard";
import {useAppSelector} from "../../app/hooks";
import {EvmChainId, EvmChainIdToNameMap} from "@decommas/sdk";
import {CHAINS} from "../../utils/consts";

export default function DepositScreen() {
    const toast = useToast()
    const address = useAppSelector((state) => state.account.wallet.address)
    const chainId = useAppSelector((state) => state.account.wallet.chainId)
    const currentChain = CHAINS.find(value => value.id.valueOf() === chainId) ?? CHAINS[0]
    const SUBTITLE = `Please only transfer ${currentChain.title} ERC20 tokens to this address. During the closed alpha, we cover all network gas fees; no native coin needed.`

    return (
        <Page>
            <Flex
                direction='column'
                gap={spacing.content_spacing_v}
            >
                <Text fontSize={fontSizes.page_title} as='b'>
                    Deposit
                </Text>
                <Text
                    textColor={colors.tg.textColorSecondary}
                    fontSize={fontSizes.small}
                >
                    {SUBTITLE}
                </Text>
            </Flex>
            <VStack gap={spacing.content_spacing_v}>
                <BaseCard
                    bg='#FFFFFF'
                    w='80%'
                >
                    <QRCode
                        value={address}
                        style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%"
                        }}
                    />
                    <Text
                        textColor='#000000'
                        fontSize={fontSizes.small}
                        as='b'
                    >
                        {address}
                    </Text>
                </BaseCard>
                <Button
                    variant='wallet_action'
                    onClick={ () => {
                        navigator.clipboard.writeText(address)
                        toast({
                            title: 'Copied!',
                            status: 'success',
                            duration: 600,
                            isClosable: false,
                        })
                    }}
                >
                    Copy
                </Button>
            </VStack>
        </Page>
    )
}