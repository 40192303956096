import {TradeNet} from "../../storage/net/decommas/trades/models";
import {OrderDirection, Trade} from "./models";
import {poolNetToDomain} from "../pools/mappers";

export function tradeNetToDomain(trade: TradeNet): Trade {
    return {
        id: trade.id,
        pair: trade.pair,
        chainId: trade.chainId,
        poolAddress: trade.poolAddress,
        direction: trade.direction as OrderDirection,
        size: trade.size.toN(),
        entryPrice: trade.averageEntryPrice
            ? trade.averageEntryPrice.toN()
            : undefined,
        total: trade.total.toN(),
        status: trade.status,
        condition: trade.condition,
        smartTrade: trade.smartTrade,
        poolInfo: poolNetToDomain(trade.poolInfo),
        close: trade.close ? {
            units: trade.close.units.toN(),
            total: trade.close.total.toN(),
            price: trade.close.price.toN()
        } : undefined
    }
}