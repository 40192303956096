import {defineStyleConfig} from "@chakra-ui/react";
import spacing from "../foundations/spacing";

const Page = defineStyleConfig({
    baseStyle: {
        width: 'full',
        height: 'full',
        px: spacing.page_padding_h,
        py: spacing.page_padding_v,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        gap: spacing.content_spacing_v,
        boxSizing: 'border-box',
    },
})

export default Page