import { PoolNet, PoolPriceNet, PoolTokenNet } from "../../storage/net/decommas/pools/models";
import { Pool, PoolPrice, PoolToken } from "./models";

export function poolNetToDomain(pool: PoolNet): Pool {
    return {
        address: pool.address,
        liquidity: Number(pool.liquidity),
        chainName: pool.chainName,
        chainId: pool.chainId,
        dex: pool.dex,
        ticker: pool.ticker,
        baseToken: tokenNetToDomain(pool.baseToken),
        quoteToken: tokenNetToDomain(pool.quoteToken),
        limits: poolPriceNetToDomain(pool.price),
    };
}

export function tokenNetToDomain(token: PoolTokenNet): PoolToken {
    return {
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        name: token.name,
        price: Number(token.price),
        logoUrl: token.logoUrl,
        isZeroToken: token.isZeroToken,
    };
}

function poolPriceNetToDomain(price?: PoolPriceNet): PoolPrice {
    return {
        price: price?.value ?? 0,
        baseLotStep: price?.baseLotStep ?? 0,
        quoteLotStep: price?.quoteLotStep ?? 0,
    };
}
