import { Referral, TgUser, TgUserInfo } from "@domain/tg_user/models";
import {
    getTgReferralsNet,
    getTgUserInfoNet,
    getTgUserNet,
} from "@storage/net/decommas/user/authAPI";
import {
    tgUserNetToDomain,
    referralNetToDomain,
    tgUserInfoNetToDomain,
} from "@domain/tg_user/mappers";

export function getTgUser(): Promise<TgUser> {
    return getTgUserNet().then((user) => tgUserNetToDomain(user));
}

export function getTgUserInfo(): Promise<TgUserInfo> {
    return getTgUserInfoNet().then((info) => tgUserInfoNetToDomain(info));
}

export async function getReferrals(): Promise<Referral[]> {
    return getTgReferralsNet().then((referrals) => referrals.map(referralNetToDomain));
}
