import {UserTokenNet} from "../../storage/net/decommas/tokens/models";
import {UserToken} from "./models";

export function tokenNetToDomain(token: UserTokenNet): UserToken {
    return {
        address: token.address,
        chainName: token.chainName,
        chainId: token.chainId,
        name: token.name,
        symbol: token.symbol,
        logoUrl: token.logoUrl,
        decimals: token.decimals,
        actualPrice: token.actualPrice,
        amount: token.amount
    }
}