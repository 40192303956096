import { Center } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "@ui/components/containers/Page";
import { StierLogo } from "@ui/icons/Icon";
import { TG_AUTH_UPDATED } from "./feature";
import { featureStateToUIState } from "./stateMapper";
import { AuthUiState } from "./AuthUIState";
import sizes from "@ui/theme/foundations/sizes";
import colors from "@ui/theme/foundations/colors";
import { WebApp } from "@ui/telegram/utils";

const FallbackInitData = process.env.REACT_APP_MOCK_WEBAPP_INIT_DATA || "";

export function AuthScreen() {
    const state = useAppSelector((state) => featureStateToUIState(state.account));

    useTgAuthUpdates();
    useOnAuthRedirect(state);

    return (
        <Page pt="160px">
            <Center>
                <StierLogo size={sizes.auth_logo} textColor={colors.tg.textColor} />
            </Center>
        </Page>
    );
}

function useTgAuthUpdates() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(TG_AUTH_UPDATED(WebApp.initData || FallbackInitData));
    }, []);
}

function useOnAuthRedirect(state: AuthUiState) {
    const navigate = useNavigate();
    useEffect(() => {
        if (state.shouldRedirect) {
            navigate(state.nextScreen, { replace: true });
        }
    }, [state.shouldRedirect]);
}
