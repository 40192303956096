import {Pool} from "../../pools/models";
import {getOrAddWallet, getCurrentWallet} from "../../wallet/repo";
import {getDexRouters} from "../../../storage/net/decommas/userops/useropsApi";
import {
    createTradingAccountsNet,
    createTradeNet,
    getMissingPermissions,
    getTransportKey
} from "../../../storage/net/decommas/trades/tradesApi";
import {encryptAes} from "./encrypt";
import {createApproveCalldata, needsTokenApproval, THexAddress} from "../../blockchain/erc20";
import {TradeCreateData} from "./models";
import {UserOperation} from "../../auth/alchemy/smartAccount";
import {CreateAccountsRequest} from "../../../storage/net/decommas/trades/models";

export default async function createTrade(
    data: TradeCreateData,
    installSessionKeyPlugin: () => Promise<void>,
    createSessionKey: (permission: THexAddress[]) => Promise<string>,
    sendUserOperation: (operations: UserOperation[]) => Promise<THexAddress>
) {
    await installSessionKeyPlugin()
    await getOrAddWallet(data.pool.chainId)
    await createAccounts(data.pool, createSessionKey)
    await approveTokens(data.pool, sendUserOperation)
    await createTradeNet(data)
}

async function createAccounts(
    pool: Pool,
    createSessionKey: (permission: THexAddress[]) => Promise<string>,
): Promise<boolean> {
    const missingPermissions = await getMissingPermissions(pool.chainId)
    const hasMissingPermissions = missingPermissions.length > 0
    const requestData: CreateAccountsRequest[] = []

    if (hasMissingPermissions) {
        const transportKey = await getTransportKey()
        for (const item of missingPermissions) {
            const sessionKey = await createSessionKey(item.permissions)
            const encryptedSessionKey = await encryptAes(transportKey, sessionKey)
            requestData.push({
                dexName: item.dexName,
                chainId: pool.chainId,
                permission: encryptedSessionKey,
            })
        }
        return await createTradingAccountsNet(requestData)
    } else {
        return !hasMissingPermissions
    }
}

async function approveTokens(
    pool: Pool,
    sendUserOperation: (operations: UserOperation[]) => Promise<THexAddress>,
) {
    const wallet = getCurrentWallet()
    const target = await getRouter(pool)
    const [needsBaseApproval, needsQuoteApproval] = await Promise.all(
        [pool.baseToken.address, pool.quoteToken.address].map((token) =>
            needsTokenApproval(wallet.chainId, token, wallet.address, target)
        )
    );

    const operations = [];
    if (needsBaseApproval) {
        operations.push(
            createApproveCalldata(pool.baseToken.address, target)
        );
    }
    if (needsQuoteApproval) {
        operations.push(
            createApproveCalldata(pool.quoteToken.address, target)
        );
    }

    if (operations.length) {
        try {
            await sendUserOperation(operations);
        } catch (err) {
            console.log(err);
        }
    }
}

async function getRouter(pool: Pool): Promise<string> {
    const routers = await getDexRouters()
    const address = routers[pool.dex]?.[String(pool.chainId)];
    if (!address) {
        throw new Error(`Unknown dex: ${pool.dex}:${pool.chainId}`);
    }

    return address;
}