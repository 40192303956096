import {Flex, IconButton, Skeleton, Stack} from "@chakra-ui/react";
import NetworkSelector from "./NetworkSelector";
import Balance from "./Balance";
import {RefreshIcon} from "../../../../ui/icons/Icon";
import spacing from "../../../../ui/theme/foundations/spacing";
import sizes from "../../../../ui/theme/foundations/sizes";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {featureStateToUIState} from "../../stateMapper";
import colors from "../../../../ui/theme/foundations/colors";
import {REFRESH_REQUIRED} from "../../feature";

export default function NetworkAndBalance() {
    const state = useAppSelector(state => featureStateToUIState(state.dashboard))
    const shouldShowSkeleton = state.totalBalanceLoading && state.tokens.items.length === 0
    const dispatch = useAppDispatch()

    return (
        <Stack
            direction='row'
            justifyContent='space-between'
        >
            <Flex direction='column'>
                <NetworkSelector />
                {shouldShowSkeleton
                    ? <BalanceSkeleton />
                    : <Balance balance={state.totalBalance}/>
                }
            </Flex>
            <IconButton
                variant='icon'
                aria-label='refresh'
                mt={spacing.card_padding_v}
                icon={<RefreshIcon size={sizes.refresh_icon} />}
                onClick={() => dispatch(REFRESH_REQUIRED())}
            />
        </Stack>
    )
}

function BalanceSkeleton() {
    return (
        <Skeleton
            borderRadius='10px'
            w='160px'
            h='44px'
            startColor={colors.tg.skeletonStart}
            endColor={colors.tg.skeletonEnd}
        />
    )
}