import { Center, Image, VStack, Text, HStack, Button, useDisclosure } from "@chakra-ui/react";
import colors from "@ui/theme/foundations/colors";
import { LevelUIState, UserInfoUIState } from "@presentation/farming/FarmingUIState";
import { ShieldIcon } from "@ui/icons/Icon";
import LevelUpgradeModal from "@presentation/farming/components/LevelUpgradeModal";

export default function UserInfo(props: {
    state: UserInfoUIState,
    level: LevelUIState
}) {
    const { state, level } = props
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {isOpen &&
                <LevelUpgradeModal
                    state={level}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            }
            <Center
                w={"full"}
            >
                <VStack gap={"10px"}>

                    {state.photo
                        ? <Image
                            src={state.photo}
                            w={"80px"}
                            h={"80px"}
                            borderRadius='full'
                        />
                        : <Center
                            w={"80px"}
                            h={"80px"}
                            borderRadius='full'
                            bgColor={colors.tg.stierBrand}
                        >
                            <Text
                                fontSize={"26px"}
                                fontWeight={"semibold"}
                            >
                                {state.initials.toUpperCase()}
                            </Text>
                        </Center>
                    }

                    <VStack gap={"2px"}>

                        <Text
                            fontSize={"18px"}
                            fontWeight={"semibold"}
                        >
                            {`Hi, ${state.name}!`}
                        </Text>

                        <HStack gap={"10px"}>

                            {/*label*/}
                            <HStack gap={"4px"}>
                                <ShieldIcon
                                    size={"20px"}
                                    color={colors.tg.gold}
                                />
                                <Text
                                    fontWeight={"semibold"}
                                >
                                    {`Lvl. ${state.level}`}
                                </Text>
                            </HStack>

                            {/*button*/}
                            <Button
                                textColor={colors.tg.gold}
                                bg={colors.tg.goldTransparent}
                                borderRadius={"8px"}
                                px={"6px"}
                                height={"28px"}
                                fontSize={"14px"}
                                onClick={onOpen}
                            >
                                Upgrade
                            </Button>

                        </HStack>

                    </VStack>

                </VStack>

            </Center>
        </>

    )
}