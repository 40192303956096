import {createSlice, PayloadAction as Effect} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {getTokens} from "../../domain/tokens/repo";
import {getTradesForDashboard} from "../../domain/trades/usecases";
import {UserToken} from "../../domain/tokens/models";
import {Trade} from "../../domain/trades/models";
import {filterSpam, sortTokens} from "../../domain/tokens/usecases";
import {getCurrentWallet} from "../../domain/wallet/repo";

export interface DashboardState {
    tokens: UserToken[],
    tokensLoading: boolean,
    tokensLoadingError?: string,
    trades: Trade[],
    tradesLoading: boolean,
    tradesLoadingError?: string,
    tab: number
}

const initialState: DashboardState = {
    tokensLoading: true,
    tradesLoading: true,
    tokens: [],
    trades: [],
    tokensLoadingError: undefined,
    tradesLoadingError: undefined,
    tab: 0
}

const refresh = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.cleanUp())
    dispatch(loadData())
}

const changeTab = (index: number): AppThunk => async (dispatch) => {
    dispatch(slice.actions.tabChanged({ index: index }))
}

const loadData = (): AppThunk => async (dispatch) => {
    dispatch(loadTokens())
    dispatch(loadTrades())
}

const loadTokens = (): AppThunk => async (dispatch) => {
    const wallet = getCurrentWallet()
    dispatch(slice.actions.tokensLoadingStarted())
    try {
        const tokens = await getTokens(wallet.chainId, wallet.address)
        dispatch(slice.actions.tokensLoaded({
            tokens: tokens
        }))
    } catch (e) {
        dispatch(slice.actions.tokensLoadingError({
            error: (e as object).toString()
        }))
    }
}

const loadTrades = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.tradesLoadingStarted())
    try {
        const trades = await getTradesForDashboard()
        dispatch(slice.actions.tradesLoaded({
            trades: trades
        }))
    } catch (e) {
        dispatch(slice.actions.tradesLoadingError({
            error: (e as object).toString()
        }))
    }
}

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        tokensLoadingStarted,
        tokensLoaded,
        tokensLoadingError,
        tradesLoadingStarted,
        tradesLoaded,
        tradesLoadingError,
        tabChanged,
        cleanUp,
    }
})

function tokensLoadingStarted(state: DashboardState) {
    state.tokensLoading = true
    state.tokensLoadingError = undefined
}

function tokensLoaded(state: DashboardState, effect: Effect<{tokens: UserToken[]}>) {
    state.tokensLoading = false
    //const filtered = filterSpam(effect.payload.tokens)
    state.tokens = sortTokens(effect.payload.tokens)
}

function tokensLoadingError(state: DashboardState, effect: Effect<{error: string}>) {
    state.tokensLoading = false
    state.tokensLoadingError = effect.payload.error
}

function tradesLoadingStarted(state: DashboardState) {
    state.tradesLoading = true
    state.tradesLoadingError = undefined
}

function tradesLoaded(state: DashboardState, effect: Effect<{trades: Trade[]}>) {
    state.tradesLoading = false
    state.trades = effect.payload.trades
}

function tradesLoadingError(state: DashboardState, effect: Effect<{error: string}>) {
    state.tradesLoading = false
    state.tradesLoadingError = effect.payload.error
}

function tabChanged(state: DashboardState, effect: Effect<{index: number}>) {
    state.tab = effect.payload.index
}

function cleanUp(state: DashboardState) {
    state.trades = []
    state.tokens = []
}

export const
    dashboardReducer = slice.reducer,
    REFRESH_REQUIRED = refresh,
    TAB_CHANGED = changeTab,
    DATA_LOAD_REQUIRED = loadData
