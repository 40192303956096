import {DashboardState} from "./feature";
import {DashboardUIState} from "./DashboardUIState";
import {tokenToUIState} from "../tokens/stateMapper";
import {tradeToUIState} from "../trades/stateMapper";

export function featureStateToUIState(state: DashboardState): DashboardUIState {
    const tokens = state.tokens.map((token) => tokenToUIState(token))
    const trades = state.trades.map((trade) => tradeToUIState(trade))
    const totalBalance = tokens
        .map((token) => token.amountUsd)
        .reduce((sum, val) => sum + val, 0)

    return {
        totalBalance: totalBalance,
        totalBalanceLoading: state.tokensLoading,
        tokens: {
            items: tokens.slice(0, 5),
            loading: state.tokensLoading,
            loadingError: state.tokensLoadingError,
        },
        trades: {
            items: trades.slice(0, 5),
            loading: state.tradesLoading,
            loadingError: state.tradesLoadingError,
        },
        tab: state.tab
    }
}