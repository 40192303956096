import {featureStateToTradeDetailsUIState} from "./stateMapper";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {TradeActionsUIState} from "./TradeDetailsUIState";
import {Page} from "../../../ui/components/containers/Page";
import spacing from "../../../ui/theme/foundations/spacing";
import {Box, Button, Flex, Text} from "@chakra-ui/react";
import fontSizes from "../../../ui/theme/foundations/fontSizes";
import SectionPlaceholder from "../../../ui/components/section/SectionPlaceholder";
import SectionHeader from "../../../ui/components/section/SectionHeader";
import SectionLabel from "../../../ui/components/section/SectionLabel";
import {BaseCard} from "../../../ui/components/containers/BaseCard";
import KeyValueItems from "../../../ui/components/KeyValueItems";
import TradeListItem from "../components/TradeListItem";
import colors from "../../../ui/theme/foundations/colors";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {CANCEL_TRADE_CONFIRMED, CLEAN_UP, CLOSE_TRADE_CONFIRMED} from "./feature";
import { WebApp } from "@ui/telegram/utils";

export default function TradeDetailsScreen() {
    const state = useAppSelector(state => featureStateToTradeDetailsUIState(state.tradeDetails))
    const dispatch = useAppDispatch()

    useStatusPopUp(state.actions)

    return (
        <Page>
            <Flex direction='column' gap={spacing.content_spacing_v}>
                <Box w='full' position='static'>
                    <Text fontSize={fontSizes.page_title} as='b'>
                        Trade
                    </Text>

                </Box>
            </Flex>

            <BaseCard>
                <TradeListItem item={state.preview}/>
            </BaseCard>

            {state.actions.visible &&
                <Flex
                    w='full'
                    direction='row'
                    gap={spacing.card_padding_h}
                >
                    {state.actions.cancel.visible &&
                        <Button
                            variant='main'
                            w='full'
                            textColor={colors.tg.yellow}
                            bgColor={colors.tg.yellowTransparent}
                            onClick={() =>
                                WebApp.showConfirm(
                                    state.actions.cancel.confirmText,
                                    (confirmed) => {
                                        if (confirmed) dispatch(CANCEL_TRADE_CONFIRMED())
                                    }
                                )
                            }
                        >
                            Cancel
                        </Button>
                    }
                    {state.actions.close.visible &&
                        <Button
                            variant='main'
                            w='full'
                            textColor={colors.tg.red}
                            bgColor={colors.tg.redTransparent}
                            onClick={() =>
                                WebApp.showConfirm(
                                    "Your position will be closed at the market price, are you sure?",
                                    (confirmed) => {
                                        if (confirmed) dispatch(CLOSE_TRADE_CONFIRMED())
                                    }
                                )
                            }
                        >
                            Close
                        </Button>
                    }
                </Flex>
            }

            <SectionPlaceholder
                header={
                    <SectionHeader
                        left={
                            <SectionLabel text={'Pool'}/>
                        }
                    />
                }
                content={
                    <BaseCard>
                        <KeyValueItems items={state.pool} />
                    </BaseCard>
                }
            />

            <SectionPlaceholder
                header={
                    <SectionHeader
                        left={
                            <SectionLabel text={'Position'}/>
                        }
                    />
                }
                content={
                    <BaseCard>
                        <KeyValueItems items={state.position} />
                    </BaseCard>
                }
            />

            {state.smartTrade &&
                <SectionPlaceholder
                    header={
                        <SectionHeader
                            left={
                                <SectionLabel text={'Smart Trade'}/>
                            }
                        />
                    }
                    content={
                        <BaseCard>
                            <KeyValueItems items={state.smartTrade} />
                        </BaseCard>
                    }
                />
            }

        </Page>
    )
}

function useStatusPopUp(state: TradeActionsUIState) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (state.cancel.error) {
            WebApp.showAlert(state.cancel.error)
        }
    }, [state.cancel.error])
    useEffect(() => {
        if (state.cancel.success) {
            WebApp.showAlert(
                "Trade cancel initiated!",
                () => {
                    dispatch(CLEAN_UP())
                    navigate(-1)
                }
            )
        }
    }, [state.cancel.success])

    useEffect(() => {
        if (state.close.error) {
            WebApp.showAlert(state.close.error)
        }
    }, [state.close.error])
    useEffect(() => {
        if (state.close.success) {
            WebApp.showAlert(
                "Trade close initiated!",
                () => {
                    dispatch(CLEAN_UP())
                    navigate(-1)
                }
            )
        }
    }, [state.close.success])
}