import {
    boostNetToDomain,
    tinderPoolDetailsNetToDomain,
    tinderPoolShortNetToDomain,
    tinderStatsNetToDomain,
} from "./mappers";
import { TinderPoolShort, TinderPoolDetails, TinderStats, Boost } from "./models";
import {
    getTinderPoolShort as getTinderPoolShortApi,
    getTinderPoolDetails as getTinderPoolDetailsApi,
    getTinderStatsNet, getBoostsNet,
} from "@storage/net/decommas/tinder/tinderAPI";

export async function getTinderPoolsShort(): Promise<TinderPoolShort[]> {
    return getTinderPoolShortApi().then((items) => items.map(tinderPoolShortNetToDomain));
}

export async function getTinderPoolDetails({
    chainId,
    address,
}: TinderPoolShort): Promise<TinderPoolDetails> {
    return getTinderPoolDetailsApi(chainId, address).then(tinderPoolDetailsNetToDomain);
}

export async function getTinderStats(): Promise<TinderStats> {
    return getTinderStatsNet().then(tinderStatsNetToDomain)
}

export async function getBoosts(): Promise<Boost[]> {
    return getBoostsNet().then((boosts) => boosts.map(boostNetToDomain));
}