import {Wallet} from "./models";
import {store} from "../../app/store";
import {createUserWalletNet, getUserWalletNet} from "../../storage/net/decommas/user/authAPI";

export function getCurrentWallet(): Wallet {
    return store.getState().account.wallet
}

export async function getOrAddWallet(chainId: number): Promise<string> {
    const address = getCurrentWallet().address
    const wallet = await getUserWalletNet(chainId)

    return wallet ?? await createUserWalletNet(chainId, address)
}