import { defineStyleConfig } from "@chakra-ui/react";
import colors from "../foundations/colors";
import radius from "../foundations/radius";
import spacing from "../foundations/spacing";
import fontSizes from "../foundations/fontSizes";

const QuestVariant = {
    padding: "6px 10px",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "8px",
    height: "auto",
    lineHeight: "normal",
};

const Button = defineStyleConfig({
    variants: {
        wallet_action: {
            paddingX: spacing.button_wallet_action_padding_x,
            paddingY: spacing.button_wallet_action_padding_y,
            bgColor: colors.tg.buttonColorTransparent,
            textColor: colors.tg.accentColor,
            fontSize: fontSizes.medium,
            borderRadius: radius.action_button,
        },
        network_selector: {
            paddingX: spacing.button_network_select_padding_x,
            paddingY: spacing.button_network_select_padding_y,
            bgColor: "transparent",
            width: "max",
            textColor: colors.tg.accentColor,
            fontSize: fontSizes.medium,
            borderRadius: radius.action_button,
        },
        icon: {
            padding: spacing.button_refresh_padding,
            bgColor: colors.tg.buttonColorTransparent,
            borderRadius: radius.card,
        },
        main: {
            paddingY: spacing.button_main_padding_y,
            bgColor: colors.tg.buttonColorTransparent,
            textColor: colors.tg.accentColor,
            fontSize: fontSizes.large,
            borderRadius: radius.main_button,
        },
        game_solid: {
            paddingY: "14px",
            fontSize: "16px",
            fontWeight: "extrabold",
            borderRadius: "12px",
            textColor: "#FFFFFF",
        },
        game_solid_accent: {
            paddingY: "14px",
            fontSize: "16px",
            fontWeight: "extrabold",
            borderRadius: "12px",
            bgColor: colors.tg.accentColor,
            textColor: "#FFFFFF",
        },
        game_solid_green: {
            paddingY: "14px",
            fontSize: "16px",
            fontWeight: "extrabold",
            borderRadius: "12px",
            bgColor: colors.tg.green,
            textColor: "#FFFFFF",
        },
        quest_green: {
            ...QuestVariant,
            bgColor: colors.tg.buttonConfirmTransparent,
            textColor: colors.tg.buttonConfirm,
        },
        quest_blue: {
            ...QuestVariant,
            bgColor: colors.tg.linkTransparent,
            textColor: colors.tg.linkColor,
        },
        game_stroked_negative: {
            paddingY: "14px",
            fontSize: "16px",
            fontWeight: "extrabold",
            borderRadius: "12px",
            borderWidth: "1px",
            borderColor: colors.tg.red,
            bgColor: colors.tg.redTransparent,
            textColor: colors.tg.red,
        },
    },
});

export default Button;
