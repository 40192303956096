import {colors} from "./foundations/colors";

const styles = {
    global: {
        body: {
            background: colors.tg.secondaryBgColor,
            color: colors.tg.textColor,
        }
    }
}

export default styles