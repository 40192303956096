import spacing from "../../theme/foundations/spacing";
import {HStack, Image, Text} from "@chakra-ui/react";
import colors from "../../theme/foundations/colors";
import sizes from "../../theme/foundations/sizes";
import DecimalInput from "../../../presentation/terminal/components/inputs/DecimalInput";

export default function TerminalInput(
    props: {
        value: string,
        logoUrl?: string,
        symbol?: string,
        decimals?: number,
        onChange: (value: string) => void
    }
) {
    const {value, decimals, logoUrl, symbol, onChange} = props
    const inputWidth = symbol ? '60%' : 'full'

    return (
        <HStack
            w='full'
            paddingX={spacing.card_padding_h}
            paddingY={spacing.card_padding_v}
            justifyContent='space-between'
        >
            <DecimalInput
                width={inputWidth}
                precision={decimals}
                value={value}
                onChange={onChange}
            />
            <HStack gap={spacing.list_item_logo_padding_x}>
                {logoUrl &&
                    <Image
                        src={logoUrl}
                        h={sizes.terminal_token_logo}
                        w={sizes.terminal_token_logo}
                        opacity='0.6'
                        borderRadius='full'
                    />
                }
                {symbol &&
                    <Text
                        color={colors.tg.textColorSecondary}
                    >
                        {symbol}
                    </Text>
                }
            </HStack>
        </HStack>
    )
}