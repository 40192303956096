import {DexRoutersNet} from "./models";
import {BASE_URL, fetchAsObject, getPrivyHeader} from "../../utils";
import {Permission} from "@zerodev/sdk";
import {THexAddress} from "../../../../domain/blockchain/erc20";

export async function getDexRouters(): Promise<DexRoutersNet> {
    const header = await getPrivyHeader()
    try {
        return fetchAsObject(
            `${BASE_URL}/userops/routers`,
            header,
        )
    } catch (e) {
        throw Error("Error getting dex routers")
    }
}

export async function getPoolPermissionData(
    chainId: number,
    pool: string,
): Promise<THexAddress[]> {
    const header = await getPrivyHeader()
    try {
        return fetchAsObject(
            `${BASE_URL}/userops/permissions`,
            {
                chainId: chainId,
                pool: pool
            },
            header,
        )
    } catch (e) {
        throw Error((e as object).toString())
    }
}