import { State } from "./feature";
import { removeNulls } from "../../utils/extensions";
import { OrderDirection, OrderType } from "../../domain/trades/models";
import { toFormatted } from "../../ui/utils";
import {
    validateGreaterThan,
    validateGreaterThanOrEqual,
    validateHasValue,
    validateLowerThan,
    validateLowerThanOrEqual,
    validateStep,
} from "../../utils/validations";
import { DEFAULT_SL_PERCENT, DEFAULT_TP_PERCENT } from "../../utils/consts";

const DEFAULT_MIN_LOT_SIZE = 0.001;

export default function validateState(state: State): Map<string, string[]> {
    const errors = new Map<string, string[]>();

    if (state.orderType === OrderType.CONDITIONAL) {
        errors.set("condition", validateCondition(state));
    }
    errors.set("amount", validateAmount(state));
    errors.set("total", validateTotal(state));
    errors.set("takeProfit", validateTakeProfit(state));
    errors.set("stopLoss", validateStopLoss(state));

    return errors;
}

function validateAmount(state: State): string[] {
    const errors = [];
    const isSell = state.orderDirection === OrderDirection.SELL;
    const token = state.pool?.baseToken;
    const balanceGwei = state.balances[token?.address ?? ""] ?? 0;
    const balance = balanceGwei.movePointLeft(token?.decimals ?? 16);
    const balanceFormatted = toFormatted(balance, 8);

    errors.push(
        validateHasValue(state.amount),
        validateGreaterThanOrEqual(state.amount.toN(), state.pool.limits.baseLotStep),
        validateStep(state.amount.toN(), state.pool.limits.baseLotStep),
        isSell
            ? validateLowerThanOrEqual(
                  state.amount.toN(),
                  balanceFormatted.toN(),
                  "Insufficient balance",
              )
            : null,
    );
    return errors.filter(removeNulls);
}

function validateCondition(state: State): string[] {
    const errors = [];

    errors.push(
        validateHasValue(state.conditionPrice),
        validateGreaterThan(state.conditionPrice.toN(), 0),
    );

    return errors.filter(removeNulls);
}

function validateTotal(state: State): string[] {
    const errors = [];
    const isBuy = state.orderDirection === OrderDirection.BUY;
    const token = state.pool?.quoteToken;
    const balanceGwei = state.balances[token?.address ?? ""] ?? 0;
    const balance = balanceGwei.movePointLeft(token?.decimals ?? 16);
    const balanceFormatted = toFormatted(balance, 8);

    errors.push(
        validateHasValue(state.total),
        validateGreaterThanOrEqual(state.total.toN(), state.pool.limits.quoteLotStep),
        isBuy
            ? validateLowerThanOrEqual(
                  state.total.toN(),
                  balanceFormatted.toN(),
                  "Insufficient balance",
              )
            : null,
    );

    return errors.filter(removeNulls);
}

function validateTakeProfit(state: State): string[] {
    if (!state.takeProfitEnabled) return [];

    const errors = [];
    const isBuy = state.orderDirection === OrderDirection.BUY;

    errors.push(
        validateHasValue(state.takeProfitPercent),
        isBuy
            ? validateGreaterThan(state.takeProfitPercent.toN(), 0)
            : validateLowerThan(state.takeProfitPercent.toN(), 0),
        isBuy
            ? validateGreaterThanOrEqual(state.takeProfitPercent.toN(), DEFAULT_TP_PERCENT.toN())
            : validateLowerThanOrEqual(state.takeProfitPercent.toN(), DEFAULT_TP_PERCENT.toN()),
        isBuy ? undefined : validateGreaterThan(state.stopLossPercent.toN(), -100),
    );

    return errors.filter(removeNulls);
}

function validateStopLoss(state: State): string[] {
    if (!state.stopLossEnabled) return [];

    const errors = [];
    const isBuy = state.orderDirection === OrderDirection.BUY;
    const priceChangeMultiplier = 1 + state.stopLossPercent.toN().movePointLeft(2);
    const currentPrice = state.pool?.baseToken.price ?? 0;
    const orderPrice = currentPrice * priceChangeMultiplier;
    const orderAmount = state.total.toN() / orderPrice;

    errors.push(
        validateHasValue(state.stopLossPercent),
        isBuy
            ? validateLowerThan(state.stopLossPercent.toN().mirror(), 0)
            : validateGreaterThan(state.stopLossPercent.toN(), 0),
        isBuy
            ? validateLowerThanOrEqual(
                  state.stopLossPercent.toN().mirror(),
                  DEFAULT_SL_PERCENT.toN().mirror(),
              )
            : validateGreaterThanOrEqual(state.stopLossPercent.toN(), DEFAULT_SL_PERCENT.toN()),
        isBuy ? validateGreaterThan(state.stopLossPercent.toN().mirror(), -100) : undefined,
        isBuy
            ? undefined
            : validateGreaterThanOrEqual(
                  orderAmount,
                  DEFAULT_MIN_LOT_SIZE,
                  "Order size will be lower than min lot size, try increasing the sell amount",
              ),
    );

    return errors.filter(removeNulls);
}
