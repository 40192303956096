import {Trade} from "./models";
import {getTradesNet} from "../../storage/net/decommas/trades/tradesApi";
import {tradeNetToDomain} from "./mappers";

export async function getTrades(
    params: {
        active: boolean,
        orderBy?: string,
        direction?: string,
        page: number,
        perPage: number,
        chainId: number,
    }
): Promise<Trade[]> {
    const limit = params.perPage
    const offset = limit * params.page

    return getTradesNet(
        params.active,
        params.chainId,
        params.orderBy,
        params.direction,
        offset,
        limit
    )
        .then((trades) =>
            trades.map((trade) =>
                tradeNetToDomain(trade)
            )
        )
}