import { createTgUserNet, createUserNet, userExists } from "@storage/net/decommas/user/authAPI";

export async function isDecommasAuthorized(): Promise<boolean> {
    return await userExists();
}

export function createUser(chainId: number, wallet: string, invite?: string): Promise<boolean> {
    return createUserNet(chainId, wallet, invite);
}

export function createTgUser(init: any, invite?: string) {
    return createTgUserNet(init, invite);
}
