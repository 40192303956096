import { Page } from "@ui/components/containers/Page";
import { Text, HStack, Flex, Button, useToast, VStack } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { featureStateToUIState } from "@presentation/friends/stateMapper";
import colors from "@ui/theme/foundations/colors";
import { BaseCard } from "@ui/components/containers/BaseCard";
import { LOAD_DATA } from "@presentation/friends/feature";
import { useEffect } from "react";
import FriendListItems from "@presentation/friends/components/FriendListItems";
import FriendsEmptyState from "@presentation/friends/components/FriendsEmptyState";
import SkeletonListItems from "@ui/components/feedback/SkeletonListItems";
import { toFormatted } from "@ui/utils";

export function FriendsScreen() {
    const state = useAppSelector((state) => featureStateToUIState(state.referral));
    const toast = useToast();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(LOAD_DATA());
    }, []);

    const shouldShowSkeleton = state.referrals.length === 0 && state.referralsLoading;
    const content =
        state.referrals.length > 0 ? (
            <FriendListItems
                items={state.referrals}
                showMore={state.referrals.length < state.referralCount}
            />
        ) : (
            <FriendsEmptyState />
        );
    const listItems = shouldShowSkeleton ? <SkeletonListItems count={5} /> : content;

    return (
        <Page>
            {/*header*/}
            <VStack py="12px">
                <HStack>
                    <Text
                        fontWeight="extrabold"
                        fontSize="36"
                    >
                        Referrals:
                    </Text>
                    <Text
                        fontWeight="extrabold"
                        fontSize="36"
                        textColor={colors.tg.stierBrand}
                    >
                        {state.referralCount}
                    </Text>
                </HStack>
                <HStack>
                    <Text
                        fontWeight="semibold"
                        fontSize="16"
                        textColor={colors.tg.green}
                    >
                        {`+${toFormatted(state.refBalance, 2)} SP`}
                    </Text>
                    <Text
                        fontWeight="semibold"
                        fontSize="16"
                    >
                        from friends
                    </Text>
                </HStack>
            </VStack>

            {/*ref code*/}
            <Flex
                direction="column"
                gap="12px"
            >
                <BaseCard>
                    <HStack
                        direction="column"
                        gap="12px"
                    >
                        <Text
                            w={"full"}
                            fontWeight="semi-bold"
                            fontSize="14"
                        >
                            Get 200 SP for each invited friend, 15% of their SP and 5% of their friends SP!
                        </Text>
                        <Button
                            variant="wallet_action"
                            w={"35%"}
                            onClick={() => {
                                navigator.clipboard.writeText(state.link);
                                toast({
                                    title: "Copied!",
                                    status: "success",
                                    position: "top",
                                    duration: 600,
                                    isClosable: false,
                                });
                            }}
                        >
                            COPY LINK
                        </Button>
                    </HStack>
                </BaseCard>
            </Flex>

            {/*friends list*/}
            <Flex
                direction="column"
                gap="12px"
            >
                <Text
                    fontWeight="extrabold"
                    fontSize="24"
                >
                    Friends
                </Text>
                <BaseCard variant={"section"}>
                    <Flex direction="column">{listItems}</Flex>
                </BaseCard>
            </Flex>
        </Page>
    );
}
