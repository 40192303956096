import { Box, calc, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import colors from "../../ui/theme/foundations/colors";
import { Screen } from "../../router/Screen";
import { useEffect, useState } from "react";
import { FriendsNavIcon, QuestNavIcon, FarmingNavIcon } from "@ui/icons/Icon";

export function Main() {
    const navigate = useNavigate();
    const height = calc("100dvh").subtract("80px").toString();

    useEffect(() => {
        navigate(Screen.FARMING);
    }, []);

    return (
        <Box w="100%">
            <Box w="100%" h={height}>
                <Outlet />
            </Box>
            <BottomNavigation />
        </Box>
    );
}

function BottomNavigation() {
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);
    const getTabColor = (index: number) => {
        return index === currentTab ? colors.tg.accentColor : colors.tg.textColorSecondary;
    };

    return (
        <Flex direction="column" width="full" gap="0" position="fixed" bottom="0">
            <Box w="full" h="0.2px" bgColor={colors.tg.hintColor} opacity="0.3" />
            <HStack
                width="full"
                height="80px"
                bg={colors.tg.sectionBgColor}
                justifyContent="space-around"
            >
                <VStack
                    gap="4px"
                    onClick={() => {
                        setCurrentTab(0);
                        navigate(Screen.FARMING);
                    }}
                    padding="10px"
                >
                    <FarmingNavIcon size="26px" color={getTabColor(0)} />
                    <Text fontSize="12px" textColor={getTabColor(0)}>
                        Farming
                    </Text>
                </VStack>
                <VStack
                    gap="4px"
                    onClick={() => {
                        setCurrentTab(1);
                        navigate(Screen.QUEST);
                    }}
                    padding="10px"
                >
                    <QuestNavIcon size={26} color={getTabColor(1)} />
                    <Text fontSize="12px" textColor={getTabColor(1)}>
                        Tasks
                    </Text>
                </VStack>
                <VStack
                    gap="4px"
                    onClick={() => {
                        setCurrentTab(2);
                        navigate(Screen.FRIENDS);
                    }}
                    padding="10px"
                >
                    <FriendsNavIcon size="26px" color={getTabColor(2)} />
                    <Text fontSize="12px" textColor={getTabColor(2)}>
                        Friends
                    </Text>
                </VStack>
            </HStack>
        </Flex>
    );
}
