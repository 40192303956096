import { Page } from "@ui/components/containers/Page";
import { Text, VStack } from "@chakra-ui/react";
import QRCode from "react-qr-code";

const startupLink = process.env.REACT_APP_WEB_APP_DIRECT_LINK || "";

export const NoDesktopScreen = () => {
    const botNameMatch = startupLink.match(/\/\/t.me\/(\w+)\//);

    const botName = (botNameMatch && botNameMatch[1]) || "";

    return (
        <Page justifyContent="center">
            <VStack gap={4}>
                <Text fontWeight="extrabold" fontSize="26">
                    Play on mobile
                </Text>
                <QRCode
                    value={startupLink}
                    style={{
                        height: "auto",
                        maxWidth: "70%",
                        minWidth: "240px",
                        width: "100%",
                    }}
                />
                <Text fontWeight="bold" fontSize="18">{`@${botName}`}</Text>
            </VStack>
        </Page>
    );
};
