import { createSlice, PayloadAction as Effect } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { Pool } from "../../domain/pools/models";
import { OrderDirection, OrderType } from "../../domain/trades/models";
import { OrderOptions, TradeCreateData } from "../../domain/trades/create/models";
import createTrade from "../../domain/trades/create/createTrade";
import {
    DEFAULT_SL_PERCENT,
    DEFAULT_TP_PERCENT,
    EMPTY_POOL,
    DEFAULT_AMOUNT,
    NO_VAL,
} from "../../utils/consts";
import { getBalances } from "../../domain/tokens/usecases";
import { THexAddress } from "../../domain/blockchain/erc20";
import { UserOperation } from "../../domain/auth/alchemy/smartAccount";
import { getPool } from "../../domain/pools/repo";

const DEFAULT_ORDER_OPTIONS: OrderOptions = {
    gasPrice: "normal",
    gasPriceCustom: "0",
    slippage: "1.0",
};

export interface State {
    pool: Pool;
    poolLoading: boolean;
    poolLoadingError: string | null;
    balances: Record<string, number>;
    balancesLoading: boolean;
    balancesLoadingError: string | null;
    orderType: OrderType;
    orderDirection: OrderDirection;
    conditionPrice: string;
    amount: string;
    total: string;
    takeProfitEnabled: boolean;
    takeProfitPercent: string;
    stopLossEnabled: boolean;
    stopLossPercent: string;
    chartAvailable: boolean;
    createTradeInProgress: boolean;
    createTradeSuccess: boolean;
    createTradeError: string | null;
}

const initialState: State = {
    pool: EMPTY_POOL,
    poolLoading: false,
    poolLoadingError: null,
    balances: {},
    balancesLoading: true,
    balancesLoadingError: null,
    orderType: OrderType.SMART_TRADE,
    orderDirection: OrderDirection.BUY,
    conditionPrice: NO_VAL,
    amount: NO_VAL,
    total: NO_VAL,
    takeProfitEnabled: true,
    takeProfitPercent: DEFAULT_TP_PERCENT,
    stopLossEnabled: true,
    stopLossPercent: DEFAULT_SL_PERCENT,
    chartAvailable: false,
    createTradeInProgress: false,
    createTradeSuccess: false,
    createTradeError: null,
};

const initTrade =
    (
        installSessionKeyPlugin: () => Promise<void>,
        createSessionKey: (permission: THexAddress[]) => Promise<string>,
        sendUserOperation: (operations: UserOperation[]) => Promise<THexAddress>,
    ): AppThunk =>
    async (dispatch, getState) => {
        dispatch(slice.actions.createTradeInProgress());
        const data = getCreateTradeData(getState().terminal);
        try {
            await createTrade(data, installSessionKeyPlugin, createSessionKey, sendUserOperation);
            dispatch(slice.actions.createTradeSuccess());
        } catch (e) {
            dispatch(
                slice.actions.createTradeError({
                    error: (e as object).toString(),
                }),
            );
        }
    };

function getCreateTradeData(state: State): TradeCreateData {
    return {
        pool: state.pool,
        direction: state.orderDirection,
        amount: state.amount.toN(),
        condition:
            state.orderType === OrderType.CONDITIONAL ? state.conditionPrice.toN() : undefined,
        smartTrade:
            state.orderType === OrderType.SMART_TRADE
                ? {
                      takeProfit: state.takeProfitEnabled
                          ? {
                                pricePct: state.takeProfitPercent,
                            }
                          : undefined,
                      stopLoss: state.stopLossEnabled
                          ? {
                                pricePct: state.stopLossPercent,
                            }
                          : undefined,
                  }
                : undefined,
        options: DEFAULT_ORDER_OPTIONS,
    };
}

const selectPool =
    (address: string): AppThunk =>
    async (dispatch, getState) => {
        const pool = getState().pools.pools.filter((pool) => pool.address === address)[0];
        dispatch(loadPoolData(pool.chainId, pool.address));
        dispatch(loadBalances());
    };

const changeOrderType =
    (type: OrderType): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.orderTypeChanged({ type: type }));
    };

const changeOrderDirection =
    (direction: OrderDirection): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.orderDirectionChanged({ direction: direction }));
    };

const changeCondition =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.conditionPriceChanged({ value: value }));
    };

const changeAmount =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.amountChanged({ value: value }));
    };

const changeTotal =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.totalChanged({ value: value }));
    };

const enableTakeProfit =
    (enabled: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.tpEnabled({ enabled: enabled }));
    };

const changeTakeProfit =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.tpChanged({ value: value }));
    };

const enableStopLoss =
    (enabled: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.slEnabled({ enabled: enabled }));
    };

const changeStopLoss =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.slChanged({ value: value }));
    };

const cleanUp = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.reset());
};

const loadBalances = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.balancesLoading());
    try {
        const balances = await getBalances();
        dispatch(slice.actions.balancesLoaded({ balances: balances }));
    } catch (e) {
        dispatch(
            slice.actions.balancesLoadingError({
                error: (e as object).toString(),
            }),
        );
    }
};

const updatePrice = (): AppThunk => async (dispatch, getState) => {
    const state = getState().terminal;
    dispatch(loadPoolData(state.pool.chainId, state.pool.address));
};

const loadPoolData =
    (chainId: number, address: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(slice.actions.poolLoading());
        try {
            const pool = await getPool(chainId, address);
            dispatch(slice.actions.poolLoaded({ pool: pool }));
            const state = getState().terminal;
            const amount = state.amount !== NO_VAL ? state.amount : DEFAULT_AMOUNT;
            dispatch(changeAmount(amount));
        } catch (e) {
            dispatch(
                slice.actions.poolLoadingError({
                    error: (e as object).toString(),
                }),
            );
        }
    };

const slice = createSlice({
    name: "terminal",
    initialState,
    reducers: {
        balancesLoading,
        balancesLoaded,
        balancesLoadingError,
        orderTypeChanged,
        orderDirectionChanged,
        conditionPriceChanged,
        amountChanged,
        totalChanged,
        tpChanged,
        tpEnabled,
        slChanged,
        slEnabled,
        createTradeInProgress,
        createTradeError,
        createTradeSuccess,
        poolLoading,
        poolLoaded,
        poolLoadingError,
        reset,
    },
});

function balancesLoading(state: State) {
    state.balancesLoading = true;
    state.balancesLoadingError = null;
}

function balancesLoaded(state: State, effect: Effect<{ balances: Map<string, number> }>) {
    state.balancesLoading = false;
    state.balances = Object.fromEntries(effect.payload.balances);
}

function balancesLoadingError(state: State, effect: Effect<{ error: string }>) {
    state.balancesLoading = false;
    state.balancesLoadingError = effect.payload.error;
}

function orderTypeChanged(state: State, effect: Effect<{ type: OrderType }>) {
    state.orderType = effect.payload.type;
    const price =
        state.orderType === OrderType.CONDITIONAL ? state.conditionPrice : state.pool.limits.price;
    const newTotal = state.amount.toN() * Number(price);
    state.total = newTotal.toFixed(newTotal.countDecimals());
    if (state.orderType === OrderType.SMART_TRADE) {
        state.orderDirection = OrderDirection.BUY;
        state.takeProfitEnabled = true;
        state.takeProfitPercent = DEFAULT_TP_PERCENT;
        state.stopLossEnabled = true;
        state.stopLossPercent = DEFAULT_SL_PERCENT;
    } else {
        state.takeProfitEnabled = false;
        state.stopLossEnabled = false;
    }
}

function orderDirectionChanged(state: State, effect: Effect<{ direction: OrderDirection }>) {
    state.orderDirection = effect.payload.direction;
}

function conditionPriceChanged(state: State, effect: Effect<{ value: string }>) {
    state.conditionPrice = effect.payload.value;
    const newTotal = state.amount.toN() * state.conditionPrice.toN();
    state.total = newTotal.toFixed(state.pool.limits.quoteLotStep.countDecimals());
}

function amountChanged(state: State, effect: Effect<{ value: string }>) {
    state.amount = effect.payload.value;
    const price =
        state.orderType === OrderType.CONDITIONAL
            ? state.conditionPrice.toN()
            : state.pool.limits.price;
    const total = state.amount.toN() * price;
    const precision = Math.min(
        total.countDecimals(),
        state.pool.limits.quoteLotStep.countDecimals(),
    );
    state.total = total.toFixed(precision);
}

function totalChanged(state: State, effect: Effect<{ value: string }>) {
    state.total = effect.payload.value;
    const price =
        state.orderType === OrderType.CONDITIONAL
            ? state.conditionPrice.toN()
            : state.pool.limits.price;
    const amount = state.total.toN() / price;
    const precision = Math.min(
        amount.countDecimals(),
        state.pool.limits.baseLotStep.countDecimals(),
    );
    state.amount = amount.toFixed(precision);
}

function tpChanged(state: State, effect: Effect<{ value: string }>) {
    state.takeProfitPercent = effect.payload.value;
}

function tpEnabled(state: State, effect: Effect<{ enabled: boolean }>) {
    state.takeProfitEnabled = effect.payload.enabled;
    state.takeProfitPercent = DEFAULT_TP_PERCENT;
}

function slChanged(state: State, effect: Effect<{ value: string }>) {
    state.stopLossPercent = effect.payload.value;
}

function slEnabled(state: State, effect: Effect<{ enabled: boolean }>) {
    state.stopLossEnabled = effect.payload.enabled;
    state.stopLossPercent = DEFAULT_SL_PERCENT;
}

function createTradeInProgress(state: State) {
    state.createTradeError = null;
    state.createTradeInProgress = true;
}

function createTradeError(state: State, effect: Effect<{ error: string }>) {
    state.createTradeError = effect.payload.error;
    state.createTradeInProgress = false;
}

function createTradeSuccess(state: State) {
    state.createTradeError = null;
    state.createTradeInProgress = false;
    state.createTradeSuccess = true;
}

function reset(state: State) {
    Object.assign(state, initialState);
}

function poolLoading(state: State) {
    state.poolLoading = true;
    state.poolLoadingError = null;
}

function poolLoaded(state: State, effect: Effect<{ pool: Pool }>) {
    state.poolLoading = false;
    state.pool = effect.payload.pool;
    const price = effect.payload.pool.limits.price;
    if (state.conditionPrice === NO_VAL) {
        state.conditionPrice = price.toFixed(price.countDecimals());
    }
}

function poolLoadingError(state: State, effect: Effect<{ error: string }>) {
    state.poolLoading = false;
    state.balancesLoadingError = effect.payload.error;
}

export const terminalReducer = slice.reducer,
    POOL_SELECTED = selectPool,
    ORDER_TYPE_CHANGED = changeOrderType,
    ORDER_DIRECTION_CHANGED = changeOrderDirection,
    CONDITION_PRICE_CHANGED = changeCondition,
    AMOUNT_CHANGED = changeAmount,
    TOTAL_CHANGED = changeTotal,
    TP_ENABLED = enableTakeProfit,
    TP_CHANGED = changeTakeProfit,
    SL_ENABLED = enableStopLoss,
    SL_CHANGED = changeStopLoss,
    TRADE_CONFIRMED = initTrade,
    PRICE_UPDATE_REQUESTED = updatePrice,
    CLEAN_UP = cleanUp;
