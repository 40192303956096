import { Page } from "@ui/components/containers/Page";
import {
    Box,
    Button,
    Center,
    HStack,
    Image,
    Modal,
    ModalContent,
    ModalOverlay,
    Spinner,
    Stack,
    Text,
    useDisclosure,
    VStack,
    Skeleton,
} from "@chakra-ui/react";
import fontSizes from "../../ui/theme/foundations/fontSizes";
import colors from "../../ui/theme/foundations/colors";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { featureStateToUIState } from "./stateMapper";
import { SwipeDirection, TinderPoolDetails, TinderSwipeResult } from "@domain/tinder/models";
import { ReactNode, useEffect, useRef, useState } from "react";
import TinderCard from "react-tinder-card";
import { CHECK_SWIPE, CLAIM, DATA_LOAD_REQUIRED, LOAD_STATS } from "./feature";
import { TinderUIState } from "./TinderUIState";
import { NumberFormat } from "@ui/components/text/NumberFormat";
import { HeartIcon, HeartStrikethroughIcon, ShieldIcon, StierTokenIcon } from "@ui/icons/Icon";
import Chart from "react-apexcharts";
import { hapticImpact, hapticNotification } from "@ui/telegram/utils";
import ModalContainer from "@ui/components/feedback/ModalContainer";

export function TinderScreen() {
    const state = useAppSelector((state) => featureStateToUIState(state.tinder));
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(DATA_LOAD_REQUIRED());
        dispatch(LOAD_STATS());
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(LOAD_STATS())
        }, 15000)

        return () => clearInterval(interval)
    }, [])

    const onSwipe = (direction: SwipeDirection) => dispatch(CHECK_SWIPE(direction));

    const onContinue = () => dispatch(CLAIM());

    return (
        <Page>
            <Stack direction="column" justifyContent="space-between" h="full">

                <HStack
                    justifyContent="space-between"
                >
                    <GameStatItem
                        icon={
                            <ShieldIcon
                                size={"24px"}
                                color={colors.tg.gold}
                            />
                        }
                        text={`Lvl. ${state.stats.level}`}
                    />
                    <GameStatItem
                        icon={
                            <HeartIcon
                                size={"24px"}
                                color={colors.tg.red}
                            />
                        }
                        text={`${state.stats.energy}/30`}
                    />

                </HStack>

                <Center>
                    <HStack gap="10px">
                        <StierTokenIcon
                            size={"24px"}
                            color={colors.tg.stierBrand}
                        />
                        {state.totalPointsLoading ? (
                            <Skeleton
                                borderRadius="10px"
                                w="120px"
                                h="36px"
                                startColor={colors.tg.skeletonStart}
                                endColor={colors.tg.skeletonEnd}
                            />
                        ) : (
                            <Text fontWeight="extrabold" fontSize="36px">
                                {state.totalPoints}
                            </Text>
                        )}
                    </HStack>
                </Center>

                <Center w="full" h="full">
                    {state.stats.energy === 0 ? (
                        <EnergyLock />
                    ) : (
                        state.tokensLoading ? (
                            <Loader />
                        ) : (
                            <CardsStack state={state} onSwipe={onSwipe} onContinue={onContinue} />
                        )
                    )}
                </Center>

                <VStack gap="0">
                    <Text fontSize={fontSizes.small} textColor={colors.tg.textColorSecondary}>
                        The data presented is 2 days old
                    </Text>
                    <Text fontSize={fontSizes.small} textColor={colors.tg.textColorSecondary}>
                        Try to guess the move
                    </Text>
                </VStack>
            </Stack>
        </Page>
    );
}

function CardsStack({
    state,
    onSwipe,
    onContinue,
}: {
    state: TinderUIState;
    onSwipe: (direction: SwipeDirection) => void;
    onContinue: () => void;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const swipeThreshold = window.innerWidth / 3;
    const cardRef = useRef();

    const swipe = async (direction: SwipeDirection) => {
        console.log(`Manual swipe ${direction}`);
        await (cardRef.current as any)?.swipe(direction);
    };

    const swipeResultVisible = state.swipeResultLoading || !!state.swipeResult;
    const swipeDisabled = !state.currentTokenDetails || swipeResultVisible;

    const preventSwipe = ["up", "down", ...(swipeDisabled ? ["left", "right"] : [])];

    const onCloseResult = () => {
        hapticImpact();
        onClose();
        onContinue();
    };

    return (
        <>
            {state.currentTokenDetails && swipeResultVisible ? (
                <ResultModal
                    token={state.currentTokenDetails}
                    result={state.swipeResult}
                    loading={state.swipeResultLoading}
                    isOpen={isOpen}
                    onClose={onCloseResult}
                    pointsPerCorrect={state.pointsPerCorrect}
                />
            ) : (
                <TinderCard
                    className="swipe"
                    ref={cardRef as any}
                    preventSwipe={preventSwipe}
                    onCardLeftScreen={() => {
                        (cardRef.current as any)?.restoreCard();
                    }}
                    onSwipe={(dir) => {
                        if (dir === "left" || dir === "right") {
                            onOpen();
                            onSwipe(dir);
                        }
                    }}
                    swipeRequirementType="position"
                    swipeThreshold={swipeThreshold}
                >
                    {state.currentTokenDetails === null ? (
                        <VStack justifyContent="center" w="full" p="16px" minHeight={300}>
                            <Loader />
                        </VStack>
                    ) : (
                        <CardContent
                            token={state.currentTokenDetails}
                            onLeft={() => swipe("left")}
                            onRight={() => swipe("right")}
                        />
                    )}
                </TinderCard>
            )}
        </>
    );
}

function TokenLogo(props: { src: string; size: string }) {
    const [error, setError] = useState(false);

    return (
        <Image
            src={error ? "/nologo.png" : props.src}
            boxSize={props.size}
            h={props.size}
            w={props.size}
            borderRadius="full"
            onError={(e) => {
                setError(true);
            }}
        />
    );
}

function CardContent(props: { token: TinderPoolDetails; onLeft: () => void; onRight: () => void }) {
    const token = props.token;
    const chartColor = getColor(token.oldData.change.h24);
    const chartShow = token.oldData.candles.length > 5;

    return (
        <VStack
            padding="16px"
            direction="column"
            w="90vw"
            position="relative"
            border="1px"
            borderColor={`${colors.tg.hintColor}1A`}
            borderRadius="24px"
            box-shadow="base"
            bg={colors.tg.sectionBgColor}
            gap="20px"
        >
            {/*Header*/}
            <HStack gap="10px" w="full" justifyContent="space-between">
                <Box flex="auto 0 0">
                    <TokenLogo src={token.logoUrl} size="100px" />
                </Box>

                <VStack w={"full"} gap="4px">
                    <Text fontSize="24px" textAlign="center" fontWeight="extrabold" noOfLines={2}>
                        {token.name?.toUpperCase()}
                    </Text>
                    <HStack gap="4px">
                        <Box
                            px="8px"
                            py="2px"
                            borderRadius="10px"
                            backgroundColor={`${colors.tg.hintColor}26`}
                        >
                            <Text
                                fontSize={fontSizes.small}
                                textColor={colors.tg.textColorSecondary}
                            >
                                {`$${token.symbol}`}
                            </Text>
                        </Box>
                    </HStack>
                </VStack>
            </HStack>

            {/*Props*/}
            <VStack w="full" gap="10px">
                <HStack justifyContent="space-around" w="full">
                    <TokenProp
                        title={"Price"}
                        value={
                            <>
                                $<NumberFormat>{token.oldData.price}</NumberFormat>
                            </>
                        }
                    />
                    <TokenProp title={"Liquidity"} value={formatCompact(token.oldData.liquidity)} />
                    <TokenProp title={"Volume"} value={formatCompact(token.oldData.volume24h)} />
                    <TokenProp title={"FDV"} value={formatCompact(token.oldData.fdv)} />
                </HStack>
                <HStack justifyContent="space-around" w="full">
                    <TokenProp
                        title={"1H"}
                        value={formatPercent(token.oldData.change.h1)}
                        color={getColor(token.oldData.change.h1)}
                    />
                    <TokenProp
                        title={"6H"}
                        value={formatPercent(token.oldData.change.h6)}
                        color={getColor(token.oldData.change.h6)}
                    />
                    <TokenProp
                        title={"24h"}
                        value={formatPercent(token.oldData.change.h24)}
                        color={getColor(token.oldData.change.h24)}
                    />
                </HStack>
            </VStack>

            {/*Chart*/}
            {chartShow && (
                <Box pointerEvents="none">
                    <Chart
                        type="line"
                        height="66px"
                        series={[
                            {
                                data: token.oldData.candles.map((candle) => candle.close),
                            },
                        ]}
                        options={{
                            chart: {
                                type: "line",
                                animations: {
                                    enabled: false,
                                },
                                sparkline: {
                                    enabled: true,
                                },
                            },
                            stroke: {
                                curve: "smooth",
                                width: 2,
                                colors: [chartColor],
                            },
                            tooltip: {
                                enabled: false,
                            },
                            grid: {
                                padding: {
                                    left: -10,
                                    right: -10,
                                    top: -10,
                                    bottom: -10,
                                },
                            },
                        }}
                    />
                </Box>
            )}

            {/*Buttons*/}
            <HStack gap="10px" w="full">
                <Button
                    w="full"
                    variant="game_stroked_negative"
                    onClick={() => {
                        hapticImpact();
                        props.onLeft();
                    }}
                    className="pressable"
                >
                    Skip
                </Button>
                <Button
                    w="full"
                    variant="game_solid_green"
                    onClick={() => {
                        hapticImpact();
                        props.onRight();
                    }}
                    className="pressable"
                >
                    BUY
                </Button>
            </HStack>
        </VStack>
    );
}

function TokenProp(props: { title: string; value: string | ReactNode; color?: string }) {
    const color = props.color ?? colors.tg.textColor;
    const { value, title } = props;

    return (
        <VStack gap="2px">
            <Text fontSize="10px" textColor={colors.tg.textColorSecondary}>
                {title.toUpperCase()}
            </Text>
            <Text fontSize="14px" fontWeight="bold" textColor={color}>
                {typeof value === "string" ? value.toUpperCase() : value}
            </Text>
        </VStack>
    );
}

function ResultModalContent(props: {
    token: TinderPoolDetails;
    result: TinderSwipeResult;
    onClose: () => void;
    pointsPerCorrect: number;
}) {
    const {
        token,
        result: { direction },
        onClose,
        pointsPerCorrect,
    } = props;
    const priceChange = token.price - token.oldData.price;
    const priceChangePercent = (priceChange / token.oldData.price) * 100;
    const hasGrown = priceChange > 0;
    const hapticType = hasGrown
        ? direction === "right"
            ? "success"
            : "error"
        : direction === "right"
        ? "error"
        : "success";
    const userPic = hasGrown
        ? direction === "right"
            ? "/success_right.png"
            : "/success_left.png"
        : direction === "right"
        ? "/fail_right.png"
        : "/fail_left.png";
    const title = hasGrown
        ? direction === "right"
            ? "It’s a match!"
            : "It’s a miss out!"
        : direction === "right"
        ? "It’s a rekt!"
        : "It’s a save!";
    const titleColor = hasGrown
        ? direction === "right"
            ? colors.tg.green
            : colors.tg.red
        : direction === "right"
        ? colors.tg.red
        : colors.tg.green;
    const priceChangeColor = hasGrown ? colors.tg.green : colors.tg.red;
    const points = hasGrown
        ? direction === "right"
            ? `${pointsPerCorrect} points`
            : "1 point"
        : direction === "right"
        ? "1 point"
        : `${pointsPerCorrect} points`;
    const movement = hasGrown ? "has grown" : "has fallen";

    useEffect(() => {
        hapticNotification(hapticType);
    }, []);

    return (
        <VStack w="full" p="16px" gap="16px">
            <HStack gap="20px" mt="12px">
                <Image boxSize="80px" borderRadius="full" src={userPic} />
                <Box flex="auto 0 0">
                    <TokenLogo src={token.logoUrl} size="80px" />
                </Box>
            </HStack>

            <Text fontSize="26px" textColor={titleColor} fontWeight="extrabold">
                {title}
            </Text>

            <HStack gap="4px">
                <Text>{`$${token.symbol} ${movement}`}</Text>
                {token.oldData.price && (
                    <Text textColor={priceChangeColor} fontWeight="bold">
                        {formatPercent(priceChangePercent)}
                    </Text>
                )}
            </HStack>

            <HStack gap="4px">
                <Text textColor={colors.tg.textColorSecondary} decoration="line-through">
                    $<NumberFormat>{token.oldData.price}</NumberFormat>
                </Text>
                <Text textColor={priceChangeColor} fontWeight="bold">
                    $<NumberFormat>{token.price}</NumberFormat>
                </Text>
            </HStack>

            <Button w="full" onClick={onClose} variant="game_solid_green">
                {`Claim ${points}`}
            </Button>
        </VStack>
    );
}

function ResultModal(props: {
    token: TinderPoolDetails;
    result: TinderSwipeResult | null;
    loading: boolean;
    isOpen: boolean;
    onClose: () => void;
    pointsPerCorrect: number;
}) {
    const { token, result, loading, isOpen, onClose, pointsPerCorrect } = props;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}
            variant="result"
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                {loading || !result ? (
                    <VStack justifyContent="center" w="full" p="16px" minHeight={300}>
                        <Loader />
                    </VStack>
                ) : (
                    <ResultModalContent
                        result={result}
                        token={token}
                        onClose={onClose}
                        pointsPerCorrect={pointsPerCorrect}
                    />
                )}
            </ModalContent>
        </Modal>
    );
}

function Loader() {
    return (
        <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={colors.tg.accentColor}
            size="xl"
        />
    );
}

function GameStatItem(props: {
    icon: ReactNode,
    text: string
}) {
    return (
        <HStack
            gap={"4px"}
            bgColor={colors.tg.sectionBgColor}
            borderRadius={"8px"}
            px={"8px"}
            py={"6px"}
        >
            {props.icon}
            <Text
                fontSize={"16px"}
                fontWeight={"semibold"}
            >
                {props.text}
            </Text>
        </HStack>
    )
}

function EnergyLock() {
    return (
        <ModalContainer
            icon={
                <HeartStrikethroughIcon
                    size={"85px"}
                    color={colors.tg.red}
                />
            }
            title={"HP drained"}
            subtitle={"HP restores 1 point per minute"}
        />
    )
}

function formatCompact(val: number): string {
    return Intl.NumberFormat("en-US", {
        notation: "compact",
        style: "currency",
        currency: "USD",
    }).format(val);
}

function formatPercent(val: number): string {
    return Intl.NumberFormat("en-US", {
        style: "percent",
        signDisplay: "always",
        maximumFractionDigits: 2,
    }).format(val / 100);
}

function getColor(val: number): string {
    return val < 0 ? colors.tg.red : colors.tg.green;
}
