import {WithdrawState} from "./feature";
import {removeNulls} from "../../utils/extensions";
import {isAddress} from "viem";

export default function validateState(state: WithdrawState): Map<string, string[]> {
    const errors = new Map<string, string[]>()

    if (state.address.length > 0) {
        errors.set('address', validateAddress(state))
    }

    return errors
}

function validateAddress(state: WithdrawState): string[] {
    const errors = []

    errors.push(
        isAddress(state.address) ? undefined : "Incorrect address",
    )

    return errors.filter(removeNulls)
}