import {getPools} from "./repo";
import {Pool} from "./models";
import {getCurrentWallet} from "../wallet/repo";

export function getPoolsForCurrentChain(
    query: string
): Promise<Pool[]> {
    const chainId = getCurrentWallet().chainId
    return getPools(
        chainId,
        query
    )
}