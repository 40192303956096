import TokensScreen from "../tokens/TokensScreen";
import {useAppDispatch} from "../../app/hooks";
import {TOKEN_SELECTED} from "./feature";
import {useNavigate} from "react-router-dom";
import {Screen} from "../../router/Screen";

export default function WithdrawSelectScreen() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    return (
        <TokensScreen
            title='Select token'
            onItemClick={(symbol) => {
                dispatch(TOKEN_SELECTED(symbol))
                navigate(Screen.WITHDRAW_FORM)
            }}
        />
    )
}