import {NO_VAL} from "./consts";

export function validateHasValue(value: string): string | undefined {
    const res = value !== '' && value !== NO_VAL && value !== '-'
    if (!res) return "Can't be blank"
}

export function validateStep(value: number, step: number): string | undefined {
    const res = value.countDecimals() <= step.countDecimals()
    if (!res) return `Should be a multiple of ${step.toFixed(step.countDecimals())}`
}

export function validateGreaterThan(value: number, size: number): string | undefined {
    const res = value > size
    if (!res) return `Should be greater than ${size.toFixed(size.countDecimals())}`
}

export function validateGreaterThanOrEqual(
    value: number,
    size: number,
    msg?: string
): string | undefined {
    const message = msg ?? `Should be greater than or equal ${size.toFixed(size.countDecimals())}`
    const res = value >= size
    if (!res) return message
}

export function validateLowerThan(value: number, size: number): string | undefined {
    const res = value < size
    if (!res) return `Should be lower than ${size.toFixed(size.countDecimals())}`
}

export function validateLowerThanOrEqual(
    value: number,
    size: number,
    msg?: string
): string | undefined {
    const message = msg ?? `Should be lower than or equal ${size.toFixed(size.countDecimals())}`
    const res = value <= size
    if (!res) return message
}
