import {HStack, Text} from "@chakra-ui/react";
import {toFormatted} from "../../../../ui/utils";
import spacing from "../../../../ui/theme/foundations/spacing";
import fontSizes from "../../../../ui/theme/foundations/fontSizes";
import colors from "../../../../ui/theme/foundations/colors";

export default function Balance(props: {
    balance: number
}) {
    return (
        <HStack spacing={spacing.content_spacing_h_small}>
            <Text
                as='b'
                fontSize={fontSizes.extra_large}
                textColor={colors.tg.textColorSecondary}
            >
                $
            </Text>
            <Text
                as='b'
                fontSize={fontSizes.extra_large}
            >
                {toFormatted(props.balance, 2)}
            </Text>
        </HStack>
    )
}