import { FriendUIState } from "@presentation/friends/FriendsUIState";
import { Flex, HStack, Image, Text } from "@chakra-ui/react";
import size from "@ui/theme/foundations/sizes";
import colors from "@ui/theme/foundations/colors";

export default function FriendListItem(props: {
    item: FriendUIState,
}) {

    const { item } = props

    return (
        <HStack
            w={"full"}
            px={"16px"}
            py={"12px"}
            gap={"10px"}
        >

            <Image
                src={item.imageUrl}
                h={size.asset_list_item_logo}
                w={size.asset_list_item_logo}
                borderRadius='full'
            />

            <HStack
                w={"full"}
                justifyContent={"space-between"}
            >

                <Flex
                    direction={"column"}
                    gap={"2px"}
                >
                    <Text
                        fontSize={"14px"}
                    >
                        {item.fullName}
                    </Text>
                    <Text
                        fontSize={"12px"}
                        color={colors.tg.textColorSecondary}
                    >
                        {`@${item.userName}`}
                    </Text>
                </Flex>

                <Text
                    fontSize={"12px"}
                    color={colors.tg.textColorSecondary}
                >
                    {`${item.friendsCount} friends`}
                </Text>

            </HStack>
        </HStack>
    )
}