import { useAppDispatch } from "@app/hooks";
import { LevelUIState, PerkUIState } from "@presentation/farming/FarmingUIState";
import {
    Box,
    Button,
    Center,
    HStack,
    Modal,
    ModalContent,
    ModalOverlay,
    Spinner,
    Text, useToast,
    VStack,
} from "@chakra-ui/react";
import colors from "@ui/theme/foundations/colors";
import { ShieldIcon } from "@ui/icons/Icon";
import { useEffect } from "react";
import { LEVEL_CLEAN_UP, LOAD_LEVEL_BOOST, UPGRADE_LEVEL } from "@presentation/farming/feature";

export default function LevelUpgradeModal(props: {
    state: LevelUIState,
    isOpen: boolean,
    onClose: () => void,
}) {
    const dispach = useAppDispatch()
    const toast = useToast();
    const { state, isOpen, onClose } = props

    useEffect(() => {
        if (state.upgradeError) {
            toast({
                title: state.upgradeError,
                status: "error",
                position: "top",
                isClosable: true,
                duration: 2000,
            })
        }
    }, [state.upgradeError])

    useEffect(() => {
        dispach(LOAD_LEVEL_BOOST())
        return () => {
            dispach(LEVEL_CLEAN_UP())
        }
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}
            variant="result"
        >
            <ModalOverlay />
            <ModalContent>
                <Center>
                    {state.loading ? (
                        <Loader />
                    ) : (
                        state.upgradeSuccess ? (
                            <LevelUpSuccess
                                level={state.level}
                                onClose={onClose}
                            />
                        ) : (
                            <LevelUpgradeModalContent
                                level={state.level}
                                cost={state.upgradeCost}
                                perks={state.perks}
                                upgradeAvailable={state.upgradeAvailable}
                                upgradeInProgress={state.upgradeInProgress}
                            />
                        )
                    )}
                </Center>
            </ModalContent>
        </Modal>
    )

}

function LevelUpgradeModalContent(props: {
    level: number,
    cost: number,
    perks: PerkUIState[],
    upgradeAvailable: boolean,
    upgradeInProgress: boolean,
}) {
    const dispatch = useAppDispatch()
    const buttonColor = props.upgradeAvailable ? colors.tg.gold : colors.tg.disabledControlColor
    const buttonText = props.upgradeAvailable ? `Buy for ${props.cost} SP` : "Not enough SP"
    return (
        <VStack
            w={"full"}
            p={"16px"}
            gap={"16px"}
        >
            {/*header*/}
            <VStack
                gap={"0px"}
            >
                <ShieldIcon
                    color={colors.tg.gold}
                    size={"46px"}
                />
                <Text
                    fontWeight={"extrabold"}
                    fontSize={"26"}
                >
                    {`Get Lvl ${props.level + 1}`}
                </Text>
                <Text
                    fontWeight={"bold"}
                    fontSize={"14"}
                >
                    {`-${props.cost} SP`}
                </Text>
            </VStack>

            {/*perks*/}
            <HStack
                w={"full"}
                justifyContent={"space-around"}
            >
                {props.perks.map((perk) => {
                    return <UpgradePerk
                        title={perk.name}
                        prev={perk.prev}
                        next={perk.next}
                    />
                })}
            </HStack>

            {/*button*/}
            <Button
                variant={"game_solid"}
                w={"full"}
                bg={buttonColor}
                onClick={() => {
                    if (props.upgradeAvailable && !props.upgradeInProgress) {
                        dispatch(UPGRADE_LEVEL())
                    }
                }}
            >
                {props.upgradeInProgress ? (
                    <Spinner />
                ) : buttonText }
            </Button>
        </VStack>
    )
}

function LevelUpSuccess(props: {
    level: number,
    onClose: () => void
}) {
    return (
        <VStack
            w={"full"}
            p={"16px"}
            gap={"16px"}
        >
            {/*header*/}
            <VStack
                gap={"0px"}
            >
                <ShieldIcon
                    color={colors.tg.gold}
                    size={"76px"}
                />
                <Text
                    fontWeight={"extrabold"}
                    fontSize={"26"}
                >
                    {`You've reached Lvl ${props.level}`}
                </Text>
            </VStack>

            {/*button*/}
            <Button
                variant={"game_solid"}
                w={"full"}
                bg={colors.tg.gold}
                onClick={props.onClose}
            >
                Okay
            </Button>

        </VStack>
    )
}

function UpgradePerk(props: {
    title: string,
    prev: string,
    next: string,
}) {

    return (
        <VStack
            gap="0px"
        >
            <Text
                fontSize="14px"
            >
                {props.title}
            </Text>
            <HStack>
                <Text
                    fontSize="14px"
                    textDecoration={"line-through"}
                    color={colors.tg.textColorSecondary}
                >
                    {props.prev}
                </Text>
                <Text
                    fontSize="16px"
                    fontWeight={"bold"}
                    color={colors.tg.green}
                >
                    {props.next}
                </Text>
            </HStack>
        </VStack>
    );
}

function Loader() {
    return (
        <Box
            p={"16px"}
        >
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color={colors.tg.gold}
                size="xl"
            />
        </Box>
    );
}