import { Page } from "@ui/components/containers/Page";
import UserInfo from "@presentation/farming/components/UserInfo";
import PointsBalance from "@presentation/farming/components/PointsBalance";
import GameCard from "@presentation/farming/components/GameCard";
import MiningButton from "@presentation/farming/components/MiningButton";
import { Flex } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { featureStateToUIState } from "@presentation/farming/stateMapper";
import { LOAD_DATA, LOAD_GAME_DATA } from "@presentation/farming/feature";
import { useEffect } from "react";

export function FarmingScreen() {
    const state = useAppSelector((state) => featureStateToUIState(state.farming))
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(LOAD_DATA())
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(LOAD_GAME_DATA())
        }, 15000)

        return () => clearInterval(interval)
    }, [])

    return (
        <Page
            justifyContent={"space-between"}
        >

            <UserInfo
                state={state.userInfo}
                level={state.level}
            />

            <PointsBalance
                state={state.points}
            />

            <Flex
                direction={"column"}
                gap={"16px"}
            >

                <GameCard
                    state={state.game}
                />

                <MiningButton
                    state={state.mining}
                />

            </Flex>

        </Page>
    )
}