import { AppThunk } from "@app/store";
import { QuestsCategory } from "@domain/quest/models";
import { getQuests } from "@domain/quest/repo";
import { checkQuest } from "@domain/quest/usecases";
import { createSlice, PayloadAction as Effect } from "@reduxjs/toolkit";
import { saveStartedQuests, loadStartedQuests } from "@storage/net/decommas/quest/questAPI";

export type QuestsState = {
    quests: QuestsCategory[];
    selectedCategoryId: string;
    questsLoading: boolean;
    startedQuests: Record<string, boolean>;

    questChecking: boolean;
    questCheckingError: boolean;
};

const initialState: QuestsState = {
    quests: [],
    selectedCategoryId: "",
    startedQuests: {},
    questsLoading: false,
    questChecking: false,
    questCheckingError: false,
};

const slice = createSlice({
    name: "quest",
    initialState,
    reducers: {
        questsLoadingStarted,
        questsLoaded,
        questsLoadingError,
        questStarted,
        questCheckStarted,
        questCheckFinished,
        questCheckFailed,
        clearCheckErrors,
        categorySelected,
    },
});

const selectCategory = (id: string): AppThunk => async (dispatch) => {
    dispatch(loadQuests())
    dispatch(slice.actions.categorySelected({ id: id }))
}

const loadQuests = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.questsLoadingStarted());

    try {
        const quests = await getQuests();
        const startedQuests = loadStartedQuests();
        dispatch(slice.actions.questsLoaded({ quests, startedQuests }));
    } catch {
        dispatch(slice.actions.questsLoadingError());
    }
};

const startQuest =
    (questId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(slice.actions.questStarted({ questId }));
        const startedQuests = getState().quest.startedQuests;

        saveStartedQuests(startedQuests);
    };

const checkQuestCompletion =
    (questId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(slice.actions.questCheckStarted());
        try {
            const result = await checkQuest(questId);

            if (result) {
                dispatch(slice.actions.questCheckFinished({ questId }));
            } else {
                dispatch(slice.actions.questCheckFailed({ questId }));
            }
        } catch {
            dispatch(slice.actions.questCheckFailed({ questId }));
        } finally {
            saveStartedQuests(getState().quest.startedQuests);

            setTimeout(() => dispatch(slice.actions.clearCheckErrors()), 1000);
        }
    };

function categorySelected(state: QuestsState, effect: Effect<{id: string}>) {
    state.selectedCategoryId = effect.payload.id
}

function questsLoadingStarted(state: QuestsState) {
    state.questsLoading = true;
}
function questsLoaded(
    state: QuestsState,
    effect: Effect<{ quests: QuestsCategory[]; startedQuests: Record<string, boolean> }>,
) {
    state.quests = effect.payload.quests;
    state.startedQuests = effect.payload.startedQuests;
    state.questsLoading = false;
}

function questsLoadingError(state: QuestsState) {
    state.questsLoading = false;
}

function questStarted(state: QuestsState, effect: Effect<{ questId: string }>) {
    state.startedQuests[effect.payload.questId] = true;
}

function questCheckStarted(state: QuestsState) {
    state.questChecking = true;
    state.questCheckingError = false;
}

function clearCheckErrors(state: QuestsState) {
    state.questCheckingError = false;
}

function questCheckFinished(state: QuestsState, effect: Effect<{ questId: string }>) {
    state.questChecking = false;

    const quests = state.quests
        .map((category) => category.quests)
        .reduce((res, value) => res.concat(value), [])
    const quest = quests.find((item) => item.slug === effect.payload.questId);

    if (quest) {
        quest.finished = Date.now().toString();
    }
}

function questCheckFailed(state: QuestsState, effect: Effect<{ questId: string }>) {
    state.questChecking = false;
    state.questCheckingError = true;
    state.startedQuests[effect.payload.questId] = false;
}

export const questReducer = slice.reducer,
    SELECT_CATEGORY = selectCategory,
    LOAD_QUESTS = loadQuests,
    START_QUEST = startQuest,
    CHECK_QUEST = checkQuestCompletion;
