import {
    TinderSwipeResultNet,
    TinderPoolNet,
    TinderPoolShortNet,
    TinderPoolDetailsNet,
    TinderStatsNet, BoostNet,
} from "@storage/net/decommas/tinder/models";
import {
    Boost,
    SwipeDirection,
    TinderPool,
    TinderPoolDetails,
    TinderPoolShort,
    TinderStats,
    TinderSwipeResult,
} from "./models";
import { poolNetToDomain } from "../pools/mappers";

export function tinderStatsNetToDomain(statsNet: TinderStatsNet): TinderStats {
    return {
        totalSwipes: statsNet.totalSwipes.toN(),
        level: statsNet.level.toN(),
        balance: statsNet.balance.toN(),
        refBalance: statsNet.refBalance.toN(),
        swipeBalance: statsNet.swipeBalance.toN(),
        energy: statsNet.energy.toN(),
        hedges: statsNet.hedges.toN(),
        aiTrader: statsNet.aiTrader,
        aiPoints: statsNet.aiPoints.toN(),
        maxAiPoints: statsNet.maxAiPoints.toN(),
        maxHoursForAi: statsNet.maxHoursForAi.toN(),
        lastAction: statsNet.lastAction
    }
}

export function boostNetToDomain(boostNet: BoostNet): Boost {
    return {
        id: boostNet.id,
        name: boostNet.name,
        description: boostNet.description,
        picture: boostNet.picture,
        price: boostNet.price,
        slug: boostNet.slug,
    }
}

/**
 * @deprecated
 */
export function tinderPoolNetToDomain(poolNet: TinderPoolNet): TinderPool {
    const domain = poolNetToDomain(poolNet);

    // @ts-ignore
    delete domain.limits;
    return { ...domain };
}

export function tinderPoolShortNetToDomain(poolNet: TinderPoolShortNet): TinderPoolShort {
    return { ...poolNet };
}

export function tinderPoolDetailsNetToDomain(poolNet: TinderPoolDetailsNet): TinderPoolDetails {
    return {
        ...poolNet,
    };
}

export function tinderSwipeResultNetToDomain(swipeResult: TinderSwipeResultNet): TinderSwipeResult {
    return {
        result: swipeResult.result,
        poolAddress: swipeResult.tokenAddress,
        direction: netToDirection(swipeResult.direction),
    };
}

export function directionToNet(direction: SwipeDirection): number {
    return direction === "left" ? 0 : 1;
}

export function netToDirection(direction: number): SwipeDirection {
    return direction === 0 ? "left" : "right";
}
