import {ReactNode} from "react";
import spacing from "../../theme/foundations/spacing";
import {Box, Flex, HStack} from "@chakra-ui/react";

export default function SectionPlaceholder(
    props: {
        header?: ReactNode,
        content: ReactNode,
        footer?: ReactNode
    }
) {
    const {header, content, footer} = props

    return (
        <Flex
            direction='column'
            w='full'
            gap={spacing.section_around_padding_y}
        >
            {header &&
                <Box
                    w='full'
                    paddingX={spacing.section_header_padding_x}
                >
                    {header}
                </Box>
            }

            <Box w='full'>
                {content}
            </Box>

            {footer &&
                <Box
                    w='full'
                    paddingX={spacing.section_header_padding_x}
                >
                    {footer}
                </Box>
            }

        </Flex>
    )
}