import {
    BASE_URL,
    fetchAsObject,
    getPrivyHeader,
    httpRequest,
    Method,
    IResponseData,
    getAuthHeader,
    fetchAsArray,
} from "../../utils";
import { ReferralNet, TgUserInfoNet, TgUserNet, UserStatsNet } from "./models";

export async function createTgUserNet(init: any, inviter?: string) {
    try {
        return httpRequest<IResponseData<{ user: string; token: string }>>({
            url: `${BASE_URL}/tgauth`,
            params: {
                init,
                inviter,
            },
            method: Method.POST,
        });
    } catch (e) {
        throw Error(
            "Unhandled sign up error. Please sign out and try to sign up later in a few minutes",
        );
    }
}

export async function getTgUserNet(): Promise<TgUserNet> {
    const headers = getAuthHeader();
    try {
        const user = await fetchAsObject<TgUserNet>(`${BASE_URL}/tgauth`, {}, headers);
        return user;
    } catch (e) {
        throw Error("Error loading tg user");
    }
}

export async function getTgUserInfoNet(): Promise<TgUserInfoNet> {
    const headers = getAuthHeader();
    try {
        const user = await fetchAsObject<TgUserInfoNet>(`${BASE_URL}/tgauth/info`, {}, headers);
        return user;
    } catch (e) {
        throw Error("Error loading tg user info");
    }
}

export async function getTgReferralsNet(): Promise<ReferralNet[]> {
    const headers = getAuthHeader();
    try {
        return fetchAsArray<ReferralNet>(`${BASE_URL}/tgauth/referrals`, {}, headers);
    } catch (e) {
        throw Error("Error loading referral data");
    }
}

export async function createUserNet(
    chainId: number,
    wallet: string,
    inviteCode?: string,
): Promise<boolean> {
    const header = await getPrivyHeader();
    try {
        await httpRequest({
            url: `${BASE_URL}/auth`,
            params: {
                invite: inviteCode,
                wallet: wallet,
                chainId: chainId,
            },
            method: Method.POST,
            headers: header,
        });
        return true;
    } catch (e) {
        if ((e as object).toString().includes("code invalid")) {
            throw Error("Your referral code is invalid");
        }
        throw Error(
            "Unhandled sign up error. Please sign out and try to sign up later in a few minutes",
        );
    }
}

export async function userExists(): Promise<boolean> {
    const header = await getPrivyHeader();
    try {
        await httpRequest({
            url: `${BASE_URL}/user`,
            headers: header,
        });
        return true;
    } catch (e) {
        return false;
    }
}

export async function getUserWalletNet(chainId: number): Promise<string | null> {
    const header = await getPrivyHeader();
    try {
        return fetchAsObject(
            `${BASE_URL}/user/wallet`,
            {
                chainId: chainId,
            },
            header,
        );
    } catch (e) {
        throw Error((e as object).toString());
    }
}

export async function createUserWalletNet(chainId: number, address: string): Promise<string> {
    const header = await getPrivyHeader();
    try {
        return httpRequest({
            url: `${BASE_URL}/user/wallet`,
            params: {
                chainId: chainId,
                address: address,
            },
            method: Method.POST,
            headers: header,
        });
    } catch (e) {
        throw Error((e as object).toString());
    }
}

export async function getUserStatsNet(): Promise<UserStatsNet> {
    const header = await getPrivyHeader();
    try {
        return fetchAsObject(`${BASE_URL}/user/invites`, {}, header);
    } catch (e) {
        throw Error((e as object).toString());
    }
}
