import colors from "@ui/theme/foundations/colors";
import { DiscordIcon, StierNoTextLogo, TelegramIcon, TwitterXIcon, YoutubeIcon } from "@ui/icons/Icon";

export default function QuestIcon(props: {
    platform: string
}) {
    const { platform } = props;
    const iconProps = { size: 24, color: colors.tg.stierBrand };

    if (platform === "telegram") {
        return <TelegramIcon {...iconProps} />;
    }
    if (platform === "discord") {
        return <DiscordIcon {...iconProps} />;
    }
    if (platform === "twitter") {
        return <TwitterXIcon {...iconProps} />;
    }
    if (platform === "youtube") {
        return <YoutubeIcon {...iconProps} />;
    }

    return <StierNoTextLogo {...iconProps} />;
};