import ClickableBox from "../../../../ui/components/containers/ClickableBox";
import spacing from "../../../../ui/theme/foundations/spacing";
import {CandlesIcon} from "../../../../ui/icons/Icon";
import {BaseCard} from "../../../../ui/components/containers/BaseCard";

export default function Chart() {
    return (
        <BaseCard w='max-content' p='0px'>
            <ClickableBox
                paddingX={spacing.card_padding_h}
                paddingY={spacing.card_padding_v}
            >
                <CandlesIcon size='22px'/>
            </ClickableBox>
        </BaseCard>
    )
}