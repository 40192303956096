import {TradeDetailsState} from "./feature";
import {TradeDetailsUIState} from "./TradeDetailsUIState";
import {tradeToUIState} from "../stateMapper";
import {OrderStatus, Trade} from "../../../domain/trades/models";
import {Pool} from "../../../domain/pools/models";
import {toFormatted} from "../../../ui/utils";

export function featureStateToTradeDetailsUIState(state: TradeDetailsState): TradeDetailsUIState {
    const cancellable = isTradeCancellable(state.trade)
    const closeable = isTradeCloseable(state.trade)

    return {
        preview: tradeToUIState(state.trade),
        actions: {
            visible: cancellable || closeable,
            cancel: {
                visible: cancellable,
                error: state.tradeCancelError,
                success: state.tradeCanceled,
                confirmText: state.trade.smartTrade
                    ? "Tracking of your position will be disabled, SL and TP will not trigger upon price change, are you sure?"
                    : "Do you really want to cancel your conditional order?"
            },
            close: {
                visible: closeable,
                error: state.tradeCloseError,
                success: state.tradeClosed,
            },
        },
        pool: getPoolInfo(state.trade.poolInfo),
        position: getPositionInfo(state.trade),
        smartTrade: state.trade.smartTrade ? getSmartTradeInfo(state.trade) : undefined,
    }
}

function isTradeCancellable(trade: Trade): boolean {
    return trade.status === OrderStatus.PENDING
        && (trade.condition !== null || trade.smartTrade !== undefined)
}

function isTradeCloseable(trade: Trade): boolean {
    return trade.smartTrade !== undefined
        && trade.status === OrderStatus.PENDING
}

function getPoolInfo(pool: Pool): Record<string, string>[] {
    const info = []
    const price = toFormatted(pool.limits.price, 8)
    info.push(
        { key: "Dex", value: pool.dex.capitalize() },
        { key: "Network", value: pool.chainName.capitalize() },
        { key: "Current price", value: price}
    )

    return info
}

function getPositionInfo(trade: Trade): Record<string, string>[] {
    const info = []
    const entryPrice = trade.entryPrice
        ? toFormatted(trade.entryPrice, 8)
        : "-"

    const trigger = trade.condition
        ? toFormatted(trade.condition.price.toN(), 8)
        : "-"
    const amount = toFormatted(trade.size, 8)
    const total = toFormatted(trade.total, 8)
    info.push(
        { key: "Entry price", value: entryPrice },
        { key: "Trigger", value: trigger },
        { key: "Amount", value: amount },
        { key: "Total", value: total }
    )

    return info
}

function getSmartTradeInfo(trade: Trade): Record<string, string>[] {
    const info = []
    let tp = '-'
    let sl = '-'
    if (trade.smartTrade?.takeProfit) {
        const tpPrice = trade.smartTrade.takeProfit.price?.toN() ?? '-'
        const tpPercent = trade.smartTrade.takeProfit.pricePct?.toN() ?? '-'
        tp = `${toFormatted(tpPrice, 8)} / +${toFormatted(tpPercent, 2)}%`
    }
    if (trade.smartTrade?.stopLoss) {
        const slPrice = trade.smartTrade.stopLoss.price?.toN() ?? '-'
        const slPercent = trade.smartTrade.stopLoss.pricePct?.toN() ?? '-'
        sl = `${toFormatted(slPrice, 8)} / ${toFormatted(slPercent, 2)}%`
    }

    info.push(
        { key: "Take Profit", value: tp },
        { key: "Stop Loss", value: sl }
    )

    return info
}