import { fetchBackendApiAsArray, postBackendApi } from "@storage/net/utils";
import { QuestsCategoryNet } from "./models";

export async function getQuestsNet(): Promise<QuestsCategoryNet[]> {
    try {
        return fetchBackendApiAsArray<QuestsCategoryNet>("quest", {});
    } catch (e) {
        throw Error("Error loading quests");
    }
}

export async function checkQuestNet(slug: string): Promise<boolean> {
    try {
        return postBackendApi<boolean>("quest", { slug });
    } catch {
        throw Error(`Error checking quest ${slug}`);
    }
}

const LOCAL_STORAGE_STARTED_QUEST = "stier-started-quest";
export function loadStartedQuests(): Record<string, boolean> {
    const item = localStorage.getItem(LOCAL_STORAGE_STARTED_QUEST);
    return item ? JSON.parse(item) : {};
}

export function saveStartedQuests(items: Record<string, boolean>) {
    localStorage.setItem(LOCAL_STORAGE_STARTED_QUEST, JSON.stringify(items));
}
