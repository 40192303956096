import {Page} from "../../ui/components/containers/Page";
import fontSizes from "../../ui/theme/foundations/fontSizes";
import {Flex, Input, Text} from "@chakra-ui/react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {featureStateToUIState} from "./stateMapper";
import colors from "../../ui/theme/foundations/colors";
import spacing from "../../ui/theme/foundations/spacing";
import {BaseCard} from "../../ui/components/containers/BaseCard";
import SectionPlaceholder from "../../ui/components/section/SectionPlaceholder";
import {ADDRESS_CHANGED, WITHDRAWAL_INITIATED, CLEAN_UP} from "./feature";
import {MainButtonUIState, StatusUIState} from "./WithdrawUIState";
import { setMainButtonLoading, WebApp } from "../../ui/telegram/utils";
import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSmartAccount} from "../../domain/auth/alchemy/AlchemyProvider";

export default function WithdrawFormScreen() {
    const dispatch = useAppDispatch()
    const state = useAppSelector(state => featureStateToUIState(state.withdraw))

    useMainButton(state.button)
    useStatusPopUp(state.status)

    return (
        <Page>
            <Flex
                direction='column'
                gap={spacing.content_spacing_v}
            >
                <Text fontSize={fontSizes.page_title} as='b'>
                    {`Withdraw ${state.symbol}`}
                </Text>
                <Text
                    textColor={colors.tg.textColorSecondary}
                    fontSize={fontSizes.small}
                >
                    Please, put your destination address
                </Text>
            </Flex>

            <SectionPlaceholder
                content={
                    <BaseCard variant='section'>
                        <Input
                            paddingX={spacing.card_padding_h}
                            paddingY={spacing.card_padding_v}
                            variant='unstyled'
                            value={state.address.value}
                            placeholder='0x79A8AfAE...'
                            onChange={ (event) => dispatch(
                                ADDRESS_CHANGED(event.currentTarget.value)
                            ) }
                        />
                    </BaseCard>
                }
                footer={
                    state.address.error &&
                    <Text
                        fontSize={fontSizes.header_section}
                        color={colors.tg.red}
                    >
                        {state.address.error}
                    </Text>
                }
            />
        </Page>
    )
}

function useMainButton(state: MainButtonUIState) {
    const dispatch = useAppDispatch()
    const mainButton = WebApp.MainButton
    const { sendUserOperations, privyUser } = useSmartAccount()

    setMainButtonLoading(state.loading)
    mainButton.setParams({
        is_active: state.enabled,
        color: state.enabled ? colors.tg.accentColor : colors.tg.gray,
        text: state.text,
    })

    const callback = useCallback(() => {
        WebApp.showConfirm(
            "Confirm withdrawal?",
            (confirmed) => {
                if (confirmed) {
                    dispatch(WITHDRAWAL_INITIATED(sendUserOperations))
                }
            }
        )
    }, [ sendUserOperations ])

    useEffect(() => {
        if (privyUser) {
            mainButton.show()
            mainButton.onClick(callback)
            return () => {
                mainButton.hide()
                mainButton.offClick(callback)
            }
        }
    }, [ sendUserOperations, privyUser ])
}

function useStatusPopUp(state: StatusUIState) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (state.error) {
            WebApp.showAlert(state.error)
        }
    }, [state.error])
    useEffect(() => {
        if (state.hash) {
            WebApp.showAlert(
                "Withdrawal successfully initiated!",
                () => {
                    dispatch(CLEAN_UP())
                    navigate(-2)
                }
            )
        }
    }, [state.hash])
}