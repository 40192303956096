export enum Screen {
    ROOT = "/",
    AUTH = "/auth",
    NO_DESKTOP = "/nodesktop",
    MAIN = "/main",
    DASHBOARD = `${MAIN}/wallet`,
    FARMING = `${MAIN}/farming`,
    TINDER = `${FARMING}/tinder`,
    BOOST = `${MAIN}/boost`,
    QUEST = `${MAIN}/quest`,
    FRIENDS = `${MAIN}/friends`,
    TERMINAL = "/terminal",
    POOLS = `${TERMINAL}/pairs`,
    SETTINGS = "/settings",
    INVITES = "/invites",
    TOKENS = "/tokens",
    TRADES = "/trades",
    TRADE_DETAILS = "/trade_details",
    DEPOSIT = "/deposit",
    WITHDRAW_LIST = "/withdraw/list",
    WITHDRAW_FORM = "/withdraw/form",
}
