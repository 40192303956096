import spacing from "../../ui/theme/foundations/spacing";
import {Box, Button, Flex, Tab, TabList, Tabs, Text} from "@chakra-ui/react";
import fontSizes from "../../ui/theme/foundations/fontSizes";
import {BaseCard} from "../../ui/components/containers/BaseCard";
import {Page} from "../../ui/components/containers/Page";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {featureStateToTradesUIState} from "./stateMapper";
import {useEffect} from "react";
import {TAB_CHANGED, NEW_PAGE_REQUESTED} from "./feature";
import SkeletonListItems from "../../ui/components/feedback/SkeletonListItems";
import TradeListItems from "./components/TradeListItems";
import TradesEmptyState from "./components/TradesEmptyState";
import {Screen} from "../../router/Screen";
import {useNavigate} from "react-router-dom";
import {TRADE_SELECTED} from "./trade/feature";

export default function TradesScreen() {
    const state = useAppSelector(state => featureStateToTradesUIState(state.trades))

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(NEW_PAGE_REQUESTED(true))
    }, [])

    const index = state.active ? 0 : 1
    const handleTabChange = (index: number) => {
        const active = index === 0
        dispatch(TAB_CHANGED(active))
    }

    const noItems = state.items.length === 0
    const shouldShowSkeleton = noItems && state.loading
    const content = state.items.length > 0
        ? <TradeListItems
            items={state.items}
            onItemClick={(id) => {
                dispatch(TRADE_SELECTED(id))
                navigate(Screen.TRADE_DETAILS)
            }}
        />
        : <TradesEmptyState />
    const listItems = shouldShowSkeleton
        ? <SkeletonListItems count={5}/>
        : content

    return (
        <Page>
            <Flex
                direction='column'
                gap={spacing.content_spacing_v}
            >
                <Box w='full'>
                    <Text
                        fontSize={fontSizes.page_title}
                        as='b'
                    >
                        Trades
                    </Text>

                </Box>
                <BaseCard>
                    <Tabs
                        index={index}
                        variant='segmented'
                        onChange={(i) => handleTabChange(i)}
                    >
                        <TabList>
                            <Tab>Active</Tab>
                            <Tab>History</Tab>
                        </TabList>
                    </Tabs>

                    <Flex
                        direction='column'
                        gap={spacing.content_spacing_v}
                    >
                        <Flex direction='column'>
                            {listItems}
                        </Flex>
                        {state.loading && !noItems &&
                            <SkeletonListItems count={5} />
                        }
                        {state.loadMoreAvailable &&
                            <Button
                                variant='main'
                                onClick={ () => dispatch(NEW_PAGE_REQUESTED(false)) }
                            >
                                Load more
                            </Button>
                        }
                    </Flex>
                </BaseCard>
            </Flex>
        </Page>
    )
}