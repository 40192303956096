import { Box, Button, Flex, HStack, Image, Text } from "@chakra-ui/react";
import colors from "@ui/theme/foundations/colors";
import { HeartIcon, ShieldIcon } from "@ui/icons/Icon";
import { useNavigate } from "react-router-dom";
import { Screen } from "../../../router/Screen";
import { GameUIState } from "@presentation/farming/FarmingUIState";

export default function GameCard(props: {
    state: GameUIState
}) {
    const navigate = useNavigate()
    const { state } = props

    return (
        <Box
            position={"relative"}
        >
            <Image
                src={"/degenrush.gif"}
                borderRadius={"12px"}
            />
            <Box
                position={"absolute"}
                w={"full"}
                h={"full"}
                top={0}
                left={0}
                borderRadius={"12px"}
                bgGradient={"linear(to-b, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.75) 75%, rgba(0,0,0,1))"}
            />
            <Box
                position={"absolute"}
                w={"full"}
                bottom={0}
                left={0}
                p={"16px"}
            >
                <Flex
                    direction={"row"}
                    justifyContent={"space-between"}
                    align={"end"}
                >

                    <Flex
                        direction={"column"}
                        gap={"8px"}
                    >
                        <Text
                            fontSize={"18px"}
                            fontWeight={"extrabold"}
                            textColor={"#FFFFFF"}
                        >
                            Swipe Game
                        </Text>

                        <Flex
                            direction={"column"}
                            gap={"2px"}
                        >
                            <HStack gap={"4px"}>
                                <HeartIcon
                                    size={"20px"}
                                    color={colors.tg.red}
                                />
                                <Text
                                    textColor={"#FFFFFF"}
                                    fontWeight={"semibold"}
                                >
                                    {`${state.energy} / ${state.energyLimit}`}
                                </Text>
                            </HStack>
                        </Flex>

                    </Flex>

                    <Button
                        textColor={colors.tg.stierBrand}
                        borderRadius={"full"}
                        size={"lg"}
                        onClick={() => navigate(Screen.TINDER)}
                    >
                        Play
                    </Button>

                </Flex>
            </Box>
        </Box>
    )
}