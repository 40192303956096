import {BaseCard} from "../../../../ui/components/containers/BaseCard";
import DashboardActionButtons from "./DashboardActionButtons";
import NetworkAndBalance from "./NetworkAndBalance";

export default function SummaryCard() {

    return (
        <BaseCard pt='0px'>
            <NetworkAndBalance />
            <DashboardActionButtons pt='10px'/>
        </BaseCard>
    )
}