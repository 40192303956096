import {THexAddress} from "../../blockchain/erc20";

export type UserOperation = {
  target: THexAddress;
  data: THexAddress;
  value?: string;
};

export enum PluginStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  INSTALLED = "INSTALLED",
  NOT_INSTALLED = "NOT_INSTALLED",
  FAILED = "FAILED",
}

export type SmartAccount = {
  authenticated: boolean;
  loading: boolean;
  privyUser: {
    wallet:
        | {
      address: string;
      chainId: number;
    }
        | undefined;
    id?: string | undefined;
  };
  sendUserOperations: (operations: UserOperation[]) => Promise<THexAddress>;
  getAccessToken: () => Promise<string | null>;
  login: () => void;
  logout: () => Promise<void>;
  createSessionKey: (permission: THexAddress[]) => Promise<string>;
  switchChain: (chainId: number) => void;
  pluginStatus: PluginStatus;
  installSessionKeyPlugin: () => Promise<void>;
  uninstallSessionKeyPlugin: () => void;
};

