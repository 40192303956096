import {Flex, Text} from "@chakra-ui/react";
import colors from "../theme/foundations/colors";

export default function KeyValueItems(props: {
    items: Record<string, string>[],
}) {

    return props.items.map((item) => {
        return (
            <Flex
                direction='row'
                w='full'
                justifyContent='space-between'
            >
                <Text
                    textColor={colors.tg.textColorSecondary}
                >
                    {item.key}
                </Text>
                <Text align='end'>
                    {item.value}
                </Text>
            </Flex>
        )
    })
}