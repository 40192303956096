import {Flex, HStack, Image, Text} from "@chakra-ui/react";
import spacing from "../../../../ui/theme/foundations/spacing";
import sizes from "../../../../ui/theme/foundations/sizes";
import {ChevronRightIcon} from "../../../../ui/icons/Icon";
import colors from "../../../../ui/theme/foundations/colors";
import ClickableBox from "../../../../ui/components/containers/ClickableBox";
import {PairUIState} from "../../TerminalUIState";
import {useNavigate} from "react-router-dom";
import {Screen} from "../../../../router/Screen";

export default function PairSelector(
    props: {state: PairUIState}
) {
    const navigate = useNavigate()
    const state = props.state
    const pair = `${state.baseSymbol}/${state.quoteSymbol}`
    const iconUrls = [state.baseLogoUrl, state.quoteLogoUrl]

    return (
        <ClickableBox
            w='full'
            px={spacing.card_padding_h}
            py={spacing.card_padding_v}
            onClick={() => navigate(Screen.POOLS)}
        >
            <HStack
                justifyContent='space-between'
            >
                <HStack
                    gap={spacing.list_item_logo_padding_x}
                >
                    <Flex>
                        {iconUrls?.map(url => {
                            return <Image
                                src={url}
                                h={sizes.terminal_token_logo}
                                w={sizes.terminal_token_logo}
                                borderRadius='full'
                            />
                        })}
                    </Flex>
                    <Text
                    >
                        {pair}
                    </Text>
                </HStack>
                <ChevronRightIcon
                    color={colors.tg.textColorSecondary}
                    size={sizes.chevron_icon}
                />
            </HStack>

        </ClickableBox>
    )
}