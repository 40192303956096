import {InviteUIState} from "../InvitesUIState";
import {Box, HStack, Text} from "@chakra-ui/react";
import ClickableBox from "../../../ui/components/containers/ClickableBox";
import spacing from "../../../ui/theme/foundations/spacing";
import fontSizes from "../../../ui/theme/foundations/fontSizes";
import colors from "../../../ui/theme/foundations/colors";

export default function InviteListItem(props: {
    item: InviteUIState,
    onClick?: (code: string) => void,
}) {
    const { item, onClick } = props

    return item.available
        ? (
            <ClickableBox
                onClick={() => {
                    if (onClick) onClick(item.code)
                }}
            >
                <HStack
                    w='full'
                    paddingX={spacing.list_item_padding_x}
                    paddingY={spacing.list_item_padding_y}
                    justifyContent='space-between'
                >
                    <Text>{item.code}</Text>
                    <Box
                        px='4px'
                        py='1px'
                        borderRadius='6px'
                        backgroundColor={colors.tg.greenTransparent}
                    >
                        <Text
                            color={colors.tg.green}
                            fontSize={fontSizes.small}
                        >
                            Free
                        </Text>
                    </Box>
                </HStack>
            </ClickableBox>
        )
        : (
            <HStack
                w='full'
                paddingX={spacing.list_item_padding_x}
                paddingY={spacing.list_item_padding_y}
                justifyContent='space-between'
            >
                <Text
                    textColor={colors.tg.textColorSecondary}
                    decoration={'line-through'}
                >
                    {item.code}
                </Text>
                <Box
                    px='4px'
                    py='1px'
                    borderRadius='6px'
                    backgroundColor={colors.tg.redTransparent}
                >
                    <Text
                        color={colors.tg.red}
                        fontSize={fontSizes.small}
                    >
                        Used
                    </Text>
                </Box>
            </HStack>
        )
}