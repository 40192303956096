import {createMultiStyleConfigHelpers} from "@chakra-ui/react";
import colors from "../foundations/colors";

const helpers = createMultiStyleConfigHelpers(['dialog'])

const Modal = helpers.defineMultiStyleConfig({
    variants: {
        result: {
            dialog: {
                borderRadius: '12px',
                bg: colors.tg.sectionBgColor,
                width: '90vw'
            }
        }
    }
})

export default Modal