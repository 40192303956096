import {UserToken} from "./models";
import {getTokensNet} from "../../storage/net/decommas/tokens/tokensApi";
import {tokenNetToDomain} from "./mappers";

export async function getTokens(
    chainId: number,
    address: string,
    limit: number = 100,
    offset: number = 0,
): Promise<UserToken[]> {
   return getTokensNet(chainId, address, limit, offset)
       .then((tokens) =>
           tokens.map((token) =>
               tokenNetToDomain(token)
           )
       )
}