import { formatBalance, formatLargeNumber } from "@utils/format";

type TProps = {
    children: string | number;
    decimals?: number;
    variant?: "only-small" | "both";
};

type TPrecisePrice = {
    zeroCount: number;
    restValue: number;
};

const parseBalance = (value: string | number): TPrecisePrice => {
    if (value === 0 || value === "0" || value === "" || Number.isNaN(+value)) {
        return {
            zeroCount: 0,
            restValue: 0,
        };
    }
    const numberValue = Number(value).toFixed(20);
    if (+value >= 0.01) {
        return {
            zeroCount: 0,
            restValue: +value,
        };
    }

    const result = String(numberValue).replace(/0*$/gm, "");
    const [, decimals] = String(result).split(".");
    const match = decimals.match(/^0+/);
    const zeroCount = match ? match[0].length : 0;

    return {
        zeroCount,
        restValue: +String(Number.parseFloat(decimals)).slice(0, 4),
    };
};

const MIN_SUPPORTED_VALUE = 0.00000000000000000001;

export const NumberFormat = ({ children, decimals, variant = "both" }: TProps) => {
    if (Number(children) > 999 && variant === "both") {
        return <span>{formatLargeNumber(children)}</span>;
    }

    let resultingDecimals = decimals;
    if (resultingDecimals === undefined) {
        resultingDecimals = Number(children) > 1 ? 2 : 6;
    }

    const isNotSupported = +children > 0 && +children < MIN_SUPPORTED_VALUE;
    const { zeroCount, restValue } = parseBalance(isNotSupported ? MIN_SUPPORTED_VALUE : children);

    console.log({ name: "FORMAT", children, zeroCount, restValue, resultingDecimals });
    return (
        <span>
            {zeroCount === 0 ? (
                formatBalance(restValue, resultingDecimals)
            ) : (
                <>
                    <span>{`${isNotSupported ? `< ` : ``}0.0`}</span>
                    <sub>{zeroCount}</sub>
                    <span>{restValue}</span>
                </>
            )}
        </span>
    );
};
