import ModalContainer from "../../../ui/components/feedback/ModalContainer";
import {EmptyIcon} from "../../../ui/icons/Icon";

export default function InvitesEmptyState() {
    return (
        <ModalContainer
            icon={ <EmptyIcon size='85px' /> }
            title={ "No invites available" }
        />
    )
}