export const WebApp = window.Telegram.WebApp;

export function showSettingsButton(show: boolean) {
    if (show) {
        WebApp.SettingsButton.show();
    } else {
        WebApp.SettingsButton.hide();
    }
}

export function showBackButton(show: boolean) {
    if (show) {
        WebApp.BackButton.show();
    } else {
        WebApp.BackButton.hide();
    }
}

export function showMainButton(show: boolean) {
    if (show) {
        WebApp.MainButton.show();
    } else {
        WebApp.MainButton.hide();
    }
}

export function setMainButtonLoading(loading: boolean) {
    if (loading) {
        WebApp.MainButton.showProgress();
    } else {
        WebApp.MainButton.hideProgress();
    }
}

export function hapticImpact(type: "light" | "medium" | "heavy" = "medium") {
    WebApp.HapticFeedback.impactOccurred(type);
}

export function hapticNotification(type: "error" | "success" | "warning" = "success") {
    WebApp.HapticFeedback.notificationOccurred(type);
}

const DesktopPlatforms = ["macos", "tdesktop", "web", "weba"];
export function isDesktopPlatform() {
    console.log("Platform", WebApp.platform, process.env.REACT_APP_ENV);
    return DesktopPlatforms.includes(WebApp.platform) && process.env.REACT_APP_ENV === "prod";
}
