import {Page} from "../../ui/components/containers/Page";
import SummaryCard from "./components/summary/SummaryCard";
import DetailsCard from "./components/details/DetailsCard";
import {useEffect} from "react";
import {useAppDispatch} from "../../app/hooks";
import {REFRESH_REQUIRED} from "./feature";
import {useSmartAccount} from "../../domain/auth/alchemy/AlchemyProvider";

export function DashboardScreen() {
    useInit()

    return (
        <Page>
            <SummaryCard />
            <DetailsCard />
        </Page>
    )
}

function useInit() {
    const { privyUser } = useSmartAccount()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (privyUser.wallet?.chainId) {
            dispatch(REFRESH_REQUIRED())
        }
    }, [privyUser.wallet?.chainId])
}