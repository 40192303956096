import {AccountState} from "./feature";
import {AuthUiState} from "./AuthUIState";
import {Screen} from "../../router/Screen";

export function featureStateToUIState(state: AccountState): AuthUiState {
    const screenAfterPrivy = Screen.MAIN
    const shouldRedirect = state.tgAuthenticated

    return {
        nextScreen: screenAfterPrivy,
        shouldRedirect: shouldRedirect,
    }
}