import {UserToken} from "./models";
import {getCurrentWallet} from "../wallet/repo";
import {getTokensNet} from "../../storage/net/decommas/tokens/tokensApi";

export function sortTokens(tokens: UserToken[]): UserToken[] {
    return tokens.sort((a, b) => {
        const aValue =
            a.amount.toN()/Math.pow(10, a.decimals) * a.actualPrice.toN()
        const bValue =
            b.amount.toN()/Math.pow(10, b.decimals) * b.actualPrice.toN()
        return bValue - aValue
    })
}

export function filterSpam(tokens: UserToken[]): UserToken[] {
    return tokens.filter((token) => token.actualPrice.toN() > 0)
}

export async function getBalances(): Promise<Map<string, number>> {
    const wallet = getCurrentWallet()
    return getTokensNet(wallet.chainId, wallet.address)
        .then((tokens) => {
            const balances = new Map<string, number>()
            tokens.forEach((token) =>
                balances.set(token.address, token.amount.toN())
            )
            return balances
        })
}