export const sizes = {
    network_selector_logo: '20px',
    network_selector_menu_logo: '20px',
    action_button_icon: '18px',
    terminal_token_logo: '24px',
    asset_list_item_logo: '32px',
    trade_list_item_logo: '22px',
    refresh_icon: '20px',
    header_label_icon: '12px',
    chevron_icon: '18px',
    auth_logo: '160px',
    divider_height: '0.4px',
    default_icon: '24px',
}

export default sizes