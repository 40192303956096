import { createPublicClient, http, Chain, PublicClient } from "viem";
import { base } from "@alchemy/aa-core";
import { EvmChainId } from "@decommas/sdk";
import {AlchemyKeys} from "../env";

type TChainConfigItem = {
    id: EvmChainId;
    chain: Chain;
    alchemy: {
        Api: string;
        Gas: string;
    };
    client: PublicClient;
};

const Chains = [
    { id: EvmChainId.BASE, chain: base, alchemy: AlchemyKeys.Base },
];

const ChainConfig: Partial<Record<EvmChainId, TChainConfigItem>> = Chains.map(
    (item) => ({
        ...item,
        client: createPublicClient({ transport: http(), chain: item.chain }),
    })
).reduce((result, item) => ({ ...result, [item.id]: item }), {});

export const SupportedChains = [EvmChainId.BASE];

export const isSupportedChain = (chainId: EvmChainId) =>
    SupportedChains.includes(chainId);

export const getChainConfig = (id: number | EvmChainId): TChainConfigItem => {
    const config = ChainConfig[id as EvmChainId];

    if (!config) {
        throw new Error(`Can't get config for chain ${id}`);
    }

    return config;
};
