import {Tab, TabList, Tabs} from "@chakra-ui/react";
import colors from "../../../../ui/theme/foundations/colors";
import {useAppDispatch} from "../../../../app/hooks";
import {OrderDirection} from "../../../../domain/trades/models";
import {ORDER_DIRECTION_CHANGED} from "../../feature";

export default function OrderDirectionSelector(
    props: {direction: OrderDirection}
) {

    const dispatch = useAppDispatch()
    const index = props.direction === OrderDirection.BUY ? 0 : 1

    const handleTabChange = (index: number) => {
        const value = index === 0 ? OrderDirection.BUY : OrderDirection.SELL
        dispatch(ORDER_DIRECTION_CHANGED(value))
    }

    return (
        <Tabs
            index={index}
            variant='segmented'
            onChange={(i) => handleTabChange(i)}
        >
            <TabList>
                <Tab
                    _selected={{
                        textColor: colors.tg.green,
                        bgColor: colors.tg.greenTransparent,
                    }}
                >
                    Buy
                </Tab>
                <Tab
                    _selected={{
                        textColor: colors.tg.red,
                        bgColor: colors.tg.redTransparent,
                    }}
                >
                    Sell
                </Tab>
            </TabList>
        </Tabs>
    )
}