import {Input} from "@chakra-ui/react";
import React from "react";

export default function DecimalInput(
    props: {
        width?: string,
        precision?: number,
        value: string,
        onChange: (value: string) => void
    }
) {
    const inputWidth = props.width ?? 'full'
    const afterPoint = props.precision ?? 8

    const filterInput = (e: React.ChangeEvent<HTMLInputElement>): string => {
        const pattern = "^(0|[1-9]+(\\d+)?)?(\\.(\\d{0," + afterPoint + "}))?$"
        const regexp = RegExp(pattern)
        const cursorPos = e.currentTarget.selectionStart ?? 0
        let newVal = e.currentTarget.value.replace(/[^a-zA-Z0-9-]/g, '.')
        let before = newVal.substring(0, cursorPos)
        const after = newVal.substring(cursorPos)
        while (!regexp.test(newVal)) {
            before = before.slice(0, -1)
            newVal = before + after
        }
        return newVal
    }

    let formattedValue = props.value
    const pointIndex = props.value.indexOf('.')
    if (pointIndex !== -1) {
        const fraction = props.value.substring(pointIndex+1)
        if (fraction.length > afterPoint) {
            formattedValue = props.value.toN().toFixed(afterPoint)
        }
    }

    return (
        <Input
            w={inputWidth}
            variant='unstyled'
            inputMode='decimal'
            value={formattedValue}
            onChange={(event) => {
                const newVal = filterInput(event)
                props.onChange(newVal)
            }}
        />
    )
}

